




        /**
        * A TypeScript version of a server-side enum. 
        */
        export enum MoveReviewTypeEnum {
            
                /**
        * [0] 
        */
                Unknown=0,
                /**
        * [1] How was the Phone Support you received from {BrandName}?
        */
                PhoneSupport=1,

        }

        const moveReviewTypeEnumDescription = {
            
                Unknown: 'Unknown',
                PhoneSupport: 'How was the Phone Support you received from {BrandName}?',
        };

        export const MoveReviewTypeEnumDescription = {

            ...moveReviewTypeEnumDescription,

            get: (value: MoveReviewTypeEnum | undefined | null): string | undefined => {
                if (typeof (value) === "string") {
                    return moveReviewTypeEnumDescription[value];
                }
                if (value===null || value===undefined) {
                    return undefined;
                }
                return moveReviewTypeEnumDescription[MoveReviewTypeEnum[value]  as keyof typeof moveReviewTypeEnumDescription];
            },
            all: [
                    
                    { value: 0, label: moveReviewTypeEnumDescription.Unknown },
                    { value: 1, label: moveReviewTypeEnumDescription.PhoneSupport },
            ] as ReadonlyArray<{label: string, value: number}>

        };
    