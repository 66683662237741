import { useCallback, useState } from 'react';

export function useShowHide(initialValue = false): [boolean, () => void, () => void] {

    const [show, setShow] = useState(initialValue);

    const onShow = useCallback(() => {
        setShow(true);
    }, [setShow]);

    const onHide = useCallback(() => {

        setShow(false);
    }, [setShow]);

    return [show, onShow, onHide];

}
