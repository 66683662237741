import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CheckoutContext } from '../checkout/CheckoutContext';
import { BrandTypeDescription } from '../typewriter/enums';


export const UnexpectedError = ({ brandPhone }: { brandPhone: string }) => {
    document.title = 'Unexpected Error';
    const { model: { brandType } } = React.useContext(CheckoutContext);
    return (
        <div>
            <Container className="pb-3 body-container">
                <Row>
                    <Col>
                        <h1 className={'text-center fw-bold text-secondary'}>Thank you for your interest in {BrandTypeDescription.get(brandType)}! Unfortunately an error occurred and we are unable to process your request. If you need immediate assistance, please call <span className={'text-primary'}>{brandPhone}</span>.</h1>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
