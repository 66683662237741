export default function <T>(array: T[], comparer?: (item: T) => string | number) {
    const u = {};
    const a = [];
    for (let i = 0, l = array.length; i < l; ++i) {

        const current = array[i];
        const compare = comparer ? comparer(current) : current as any;
        if (Object.hasOwn(u, compare)) {
            continue;
        }
        a.push(array[i]);
        u[compare] = 1;
    }
    return a;
}
