




        /**
        * A TypeScript version of a server-side enum. 
        */
        export enum BookingSubdomainQuoteErrorReason {
            
                /**
        * [0] 
        */
                None=0,
                /**
        * [1] 
        */
                QuoteNotFound=1,
                /**
        * [2] 
        */
                QuoteAlreadyBooked=2,
                /**
        * [3] 
        */
                QuoteExpired=3,
                /**
        * [4] 
        */
                OrderTotalMismatch=4,
                /**
        * [5] 
        */
                BalanceDueMismatch=5,
                /**
        * [6] 
        */
                BalanceDueMismatchJobs=6,
                /**
        * [7] 
        */
                PaymentDeclined=7,
                /**
        * [8] 
        */
                BookQuoteJobsCommandFailed=8,
                /**
        * [9] 
        */
                SameAndNextDayNotAllowed=9,

        }

        const bookingSubdomainQuoteErrorReasonDescription = {
            
                None: 'None',
                QuoteNotFound: 'Quote Not Found',
                QuoteAlreadyBooked: 'Quote Already Booked',
                QuoteExpired: 'Quote Expired',
                OrderTotalMismatch: 'Order Total Mismatch',
                BalanceDueMismatch: 'Balance Due Mismatch',
                BalanceDueMismatchJobs: 'Balance Due Mismatch Jobs',
                PaymentDeclined: 'Payment Declined',
                BookQuoteJobsCommandFailed: 'Book Quote Jobs Command Failed',
                SameAndNextDayNotAllowed: 'Same And Next Day Not Allowed',
        };

        export const BookingSubdomainQuoteErrorReasonDescription = {

            ...bookingSubdomainQuoteErrorReasonDescription,

            get: (value: BookingSubdomainQuoteErrorReason | undefined | null): string | undefined => {
                if (typeof (value) === "string") {
                    return bookingSubdomainQuoteErrorReasonDescription[value];
                }
                if (value===null || value===undefined) {
                    return undefined;
                }
                return bookingSubdomainQuoteErrorReasonDescription[BookingSubdomainQuoteErrorReason[value]  as keyof typeof bookingSubdomainQuoteErrorReasonDescription];
            },
            all: [
                    
                    { value: 0, label: bookingSubdomainQuoteErrorReasonDescription.None },
                    { value: 1, label: bookingSubdomainQuoteErrorReasonDescription.QuoteNotFound },
                    { value: 2, label: bookingSubdomainQuoteErrorReasonDescription.QuoteAlreadyBooked },
                    { value: 3, label: bookingSubdomainQuoteErrorReasonDescription.QuoteExpired },
                    { value: 4, label: bookingSubdomainQuoteErrorReasonDescription.OrderTotalMismatch },
                    { value: 5, label: bookingSubdomainQuoteErrorReasonDescription.BalanceDueMismatch },
                    { value: 6, label: bookingSubdomainQuoteErrorReasonDescription.BalanceDueMismatchJobs },
                    { value: 7, label: bookingSubdomainQuoteErrorReasonDescription.PaymentDeclined },
                    { value: 8, label: bookingSubdomainQuoteErrorReasonDescription.BookQuoteJobsCommandFailed },
                    { value: 9, label: bookingSubdomainQuoteErrorReasonDescription.SameAndNextDayNotAllowed },
            ] as ReadonlyArray<{label: string, value: number}>

        };
    