




        /**
        * A TypeScript version of a server-side enum. 
        */
        export enum TokenResult {
            
                /**
        * [0] 
        */
                Valid=0,
                /**
        * [1] 
        */
                Expired=1,
                /**
        * [2] 
        */
                DoesNotExist=2,
                /**
        * [3] 
        */
                WrongType=3,

        }

        const tokenResultDescription = {
            
                Valid: 'Valid',
                Expired: 'Expired',
                DoesNotExist: 'Does Not Exist',
                WrongType: 'Wrong Type',
        };

        export const TokenResultDescription = {

            ...tokenResultDescription,

            get: (value: TokenResult | undefined | null): string | undefined => {
                if (typeof (value) === "string") {
                    return tokenResultDescription[value];
                }
                if (value===null || value===undefined) {
                    return undefined;
                }
                return tokenResultDescription[TokenResult[value]  as keyof typeof tokenResultDescription];
            },
            all: [
                    
                    { value: 0, label: tokenResultDescription.Valid },
                    { value: 1, label: tokenResultDescription.Expired },
                    { value: 2, label: tokenResultDescription.DoesNotExist },
                    { value: 3, label: tokenResultDescription.WrongType },
            ] as ReadonlyArray<{label: string, value: number}>

        };
    