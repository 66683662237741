




        /**
        * A TypeScript version of a server-side enum. 
        */
        export enum ProviderListResultStatus {
            
                /**
        * [0] 
        */
                Unknown=0,
                /**
        * [1] 
        */
                Success=1,
                /**
        * [2] 
        */
                NoProvidersFound=2,
                /**
        * [3] 
        */
                FullyBlocked=3,
                /**
        * [4] 
        */
                BlockedDuringArrivalWindow=4,
                /**
        * [5] 
        */
                Error=5,

        }

        const providerListResultStatusDescription = {
            
                Unknown: 'Unknown',
                Success: 'Success',
                NoProvidersFound: 'No Providers Found',
                FullyBlocked: 'Fully Blocked',
                BlockedDuringArrivalWindow: 'Blocked During Arrival Window',
                Error: 'Error',
        };

        export const ProviderListResultStatusDescription = {

            ...providerListResultStatusDescription,

            get: (value: ProviderListResultStatus | undefined | null): string | undefined => {
                if (typeof (value) === "string") {
                    return providerListResultStatusDescription[value];
                }
                if (value===null || value===undefined) {
                    return undefined;
                }
                return providerListResultStatusDescription[ProviderListResultStatus[value]  as keyof typeof providerListResultStatusDescription];
            },
            all: [
                    
                    { value: 0, label: providerListResultStatusDescription.Unknown },
                    { value: 1, label: providerListResultStatusDescription.Success },
                    { value: 2, label: providerListResultStatusDescription.NoProvidersFound },
                    { value: 3, label: providerListResultStatusDescription.FullyBlocked },
                    { value: 4, label: providerListResultStatusDescription.BlockedDuringArrivalWindow },
                    { value: 5, label: providerListResultStatusDescription.Error },
            ] as ReadonlyArray<{label: string, value: number}>

        };
    