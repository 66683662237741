




        /**
        * A TypeScript version of a server-side enum. 
        */
        export enum BookingSubdomainStep {
            
                /**
        * [0] 
        */
                Unknown=0,
                /**
        * [10] 
        */
                ServiceAndContact=10,
                /**
        * [20] 
        */
                Transportation=20,
                /**
        * [30] 
        */
                MoveDetails=30,
                /**
        * [40] 
        */
                ReservationDetails=40,

        }

        const bookingSubdomainStepDescription = {
            
                Unknown: 'Unknown',
                ServiceAndContact: 'Service And Contact',
                Transportation: 'Transportation',
                MoveDetails: 'Move Details',
                ReservationDetails: 'Reservation Details',
        };

        export const BookingSubdomainStepDescription = {

            ...bookingSubdomainStepDescription,

            get: (value: BookingSubdomainStep | undefined | null): string | undefined => {
                if (typeof (value) === "string") {
                    return bookingSubdomainStepDescription[value];
                }
                if (value===null || value===undefined) {
                    return undefined;
                }
                return bookingSubdomainStepDescription[BookingSubdomainStep[value]  as keyof typeof bookingSubdomainStepDescription];
            },
            all: [
                    
                    { value: 0, label: bookingSubdomainStepDescription.Unknown },
                    { value: 10, label: bookingSubdomainStepDescription.ServiceAndContact },
                    { value: 20, label: bookingSubdomainStepDescription.Transportation },
                    { value: 30, label: bookingSubdomainStepDescription.MoveDetails },
                    { value: 40, label: bookingSubdomainStepDescription.ReservationDetails },
            ] as ReadonlyArray<{label: string, value: number}>

        };
    