import React from 'react';
import { useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import CheckoutUrls from '../checkout/checkout-urls';
import { usePickSetState, useTransportOptions } from '../hooks';
import { useQueryStringParam } from '../hooks/useQueryStringParam';
import { quote } from '../typewriter/controllers/BookingSubdomainController';
import { BrandType } from '../typewriter/enums';
import { TransientAuthType } from '../typewriter/enums/TransientAuthType';
import { handleTokenErrorResponse } from './handleTokenErrorResponse';
import { mcGenericNonJsonFetchError } from '../typewriter/http-utils';

function getInitialModel(): models.PublicBookingSubdomainQuoteModel {
    return {
        contactInfo: {
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
        } as models.ContactInfo,
        brandType: BrandType.Unknown,
        moveDescription: '',
        loadingJobs: [],
        unloadingJobs: [],
        // If loadingLocation or unloadingLocation is set here and not null, useSteps will not work properly.
    };
}

export function useCheckoutContextProvider({
    brand,
    tokenType,
}: {
    brand: BrandType;
    tokenType: TransientAuthType;
}) {
    const initialModel = getInitialModel();

    const [model, onChange, modelRef] =
        usePickSetState<models.PublicBookingSubdomainQuoteModel>(() => ({
            ...initialModel,
            brandType: brand,
        }));

    const [loading, setLoading] = React.useState(true);

    const authToken = useQueryStringParam('token');

    const { replace } = useHistory();

    React.useEffect(() => {
        if (authToken) {
            (async () => {
                try {
                    const existingQuote = await quote(authToken, tokenType);
                    // this may be a redirect instead, handle that
                    // yes, this is a hack for PMG-3958
                    if ((existingQuote as any).redirectToUrl != null) {
                        window.location.replace((existingQuote as any).redirectToUrl);
                        return;
                    }

                    if (
                        existingQuote.bookingRootUrl &&
                        existingQuote.bookingRootUrl != window.location.origin
                    ) {
                        // just in case quote brand does not match url, redirect user to correct branded quote page
                        window.location.replace(
                            existingQuote.bookingRootUrl +
                                window.location.pathname +
                                window.location.search,
                        );
                        return;
                    }
                    onChange(existingQuote);
                    setLoading(false);
                } catch (error: any) {
                    setLoading(false);
                    replace(handleTokenErrorResponse(error, authToken, 'quote get'));
                }
            })();
        } else {
            // first page load and no authToken - if they are not on /checkout/ then redirect them since they must have refreshed in the middle before quote was saved
            if (
                window.location.pathname != CheckoutUrls.CheckoutBaseUrl &&
                window.location.pathname != CheckoutUrls.UnavailableUrl &&
                window.location.pathname != CheckoutUrls.QuoteExpiredUrl
            ) {
                replace(CheckoutUrls.CheckoutBaseUrl);
            }
            setLoading(false);
        }
    }, [authToken, modelRef, onChange, replace, tokenType]);

    const transportOptions = useTransportOptions();

    return {
        model,
        authToken,
        onChange,
        loading,
        setLoading,
        modelRef,
        transportOptions,
    };
}
