import React from 'react';
import { reviewQuote } from '../typewriter/controllers/BookingSubdomainController';
import { TransientAuthType } from '../typewriter/enums/TransientAuthType';
import { handleTokenErrorResponse } from './handleTokenErrorResponse';

export function useReviewModel(
    authToken: string,
    tokenType: TransientAuthType,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
) {
    const [reviewModel, setReviewModel] =
        React.useState<models.ReviewBookingSubdomainQuoteModel>();

    const fetchModel = React.useCallback(async () => {
        setLoading(true);

        try {
            const result = await reviewQuote(authToken, tokenType);
            // this may be a redirect instead, handle that
            // yes, this is a hack for PMG-3958
            if ((result as any).redirectToUrl != null) {
                window.location.replace((result as any).redirectToUrl);
                return;
            }
            setReviewModel(result);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            handleTokenErrorResponse(error, authToken, 'reviewQuote get');
        }
    }, [authToken, setLoading, tokenType]);

    React.useEffect(() => {
        if (authToken) {
            fetchModel();
        }
    }, [authToken, fetchModel]);

    return { reviewModel, fetchModel };
}
