import { DateTime } from 'luxon';
import * as React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { AddressOneLine } from '../shared/AddressOneLine';
import { AdditionalRatesRow } from '../AdditionalRates';

export const MoveDate = ({ job, label }: { job: models.BookingSubdomainJobInfo, label: string }) => {

    const date = DateTime.fromISO(job.jobDate!).toLocaleString(DateTime.DATE_HUGE);
    const month = DateTime.fromISO(job.jobDate!).toFormat('MMMM');
    const day = DateTime.fromISO(job.jobDate!).day;
    const addresses = job.jobAddresses;

    return <div className="review-box">
        <Row className="justify-content-center">
            <Col xs={'auto'}>
                <Card className="calendar-card drop-shadow">
                    <Card.Header className='drop-shadow'>
                        {month}
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {day}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="small">
                <h6 className="text-uppercase">{label}</h6>
                <div>{date}</div>
                <div>{job.desiredArrivalWindow}</div>
                <strong>
                    {addresses?.length == 1 
                        ? <AddressOneLine {...addresses![0]!} /> 
                        : addresses?.map((x, i) => <div key={i} className="d-flex flex-column"><AddressOneLine {...x} stopNumber={i + 1} /></div>)}
                </strong>
            </Col>
        </Row>
    </div>
};
