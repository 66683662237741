import { FaSymbol, FlipProp, IconDefinition, IconPack, library, PullProp, RotateProp, SizeProp, Transform } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import * as iconLibrary from '../modules/iconLibrary';
//export * as iconLibrary from './iconLibrary';

//import { CSSProperties } from 'react'

library.add(iconLibrary as IconPack);
export { iconLibrary };

export const Icon: React.SFC<Props> = FontAwesomeIcon;

export interface Props {
  icon: IconDefinition;
  mask?: IconDefinition;
  className?: string;
  color?: string;
  spin?: boolean;
  pulse?: boolean;
  border?: boolean;
  fixedWidth?: boolean;
  inverse?: boolean;
  listItem?: boolean;
  flip?: FlipProp;
  size?: SizeProp;
  pull?: PullProp;
  rotation?: RotateProp;
  transform?: string | Transform;
  symbol?: FaSymbol;
  style?: React.CSSProperties;
  tabIndex?: number;
  title?: string;
}
