import * as React from 'react';
import { Container } from 'react-bootstrap';
import { formatDate } from '../../components/DateTimeDisplays';

interface ReviewInformationProps {
    orderId: number;
    customerName: string;
    jobs: readonly models.ManagedReviewJobModel[];
}
export const TokenExpired = (props: ReviewInformationProps) => {
    const { orderId, customerName, jobs } = props;

    const lastDateJob = jobs.sortBy(x => x.jobDate)[0].jobDate;

    return <Container className="pb-3 body-container">
        <h2>Your Review Link for Order <b className='text-primary'>#{orderId}</b> Has Expired.</h2>
        <p className='text-muted'>
            <small>Hi, {customerName}. Unfortunately, your review link for Order #{orderId} expired.</small>
            { lastDateJob && <><br /> <small className='text-muted'>(Job completion date: {formatDate(lastDateJob)})</small></> }
        </p>
    </Container>;
};
