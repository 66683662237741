import React, { useCallback } from 'react';
import { OnChangePickSetState, OnlyUndefinableKeys, RequireAtLeastOne } from './usePickSetState';

export function useOnChangeCallback<T, TKey extends keyof T, TValue = T[TKey]>(onChange: (newValue: RequireAtLeastOne<T>) => void, key: TKey) {
    return useCallback<((newValue: TValue) => void)>(newValue => {

        if (typeof newValue === 'function') {
            throw new Error('useOnChangeCallback requires an object to be returned, not a function. You should probably be calling useOnChangeCallbackPickSetState instead');
        }
        onChange({ [key]: newValue } as unknown as RequireAtLeastOne<T>);
    }, [key, onChange]);
}
export type OnChangePickSetStateRequired<T extends {}, TFull extends (T | undefined) = T> = (change: ((prev: TFull) => RequireAtLeastOne<T>) | RequireAtLeastOne<T>) => void;

export function useOnChangeCallbackPickSetState<T extends {}, TFull extends T, TKey extends keyof OmitNulls<T>>(onChange: OnChangePickSetState<T, TFull>, key: TKey): OnChangePickSetState<Required<T>[TKey]>;
export function useOnChangeCallbackPickSetState<T extends {}, TFull extends T, TKey extends keyof OnlyUndefinableKeys<T>>(onChange: OnChangePickSetState<T, TFull>, key: TKey): OnChangePickSetState<Required<T>[TKey]>;
//export function useOnChangeCallbackPickSetState<T extends {}, TFull extends T, TKey extends keyof OnlyUndefinableKeys<T>>(onChange: OnChangePickSetState<T, TFull>, key: TKey, setUndefined: boolean): OnChangePickSetState<Required<T>[TKey], T[TKey]>;
export function useOnChangeCallbackPickSetState<T extends {}, TFull extends T, TKey extends keyof T>(onChange: OnChangePickSetState<T, TFull>, key: TKey): OnChangePickSetState<Required<T>[TKey]> {

    return React.useCallback<OnChangePickSetState<T[TKey]>>(newValue => {
        onChange((prev) => {


            const prevValue = prev[key];

            // if (typeof (newValue) === 'function') {

            //     const actualNewValue = newValue(prevValue);

            //     if (actualNewValue === undefined) {
            //         return;
            //     }
            // }
            const actualNewValue = typeof newValue === 'function' ? newValue(prevValue) : newValue;

            if (actualNewValue !== undefined && actualNewValue !== prevValue as any) {

                const combinedNewValue = typeof prevValue === 'object' && !Array.isArray(prevValue) ? { ...prevValue, ...actualNewValue } : actualNewValue;

                if (combinedNewValue !== prevValue as any) {
                    //const diff = shallowDiff(prevValue, combinedNewValue as any);
                    //debuglog('useOnChangeCallbackPickSetState', { key, prevValue, newValue: combinedNewValue, diff });

                    return { [key]: combinedNewValue } as any;
                }
            }
        })
    }, [key, onChange]);

}

export type OmitNulls<T> = Omit<T, OnlyUndefinableKeys<T>[keyof T]>;
