




        /**
        * A TypeScript version of a server-side enum. 
        */
        export enum JobStatus {
            
                /**
        * [0] 
        */
                Unknown=0,
                /**
        * [2] Waiting for provider
        */
                WaitingForHelper=2,
                /**
        * [3] Accepted
        */
                Accepted=3,
                /**
        * [4] Rejected
        */
                Rejected=4,
                /**
        * [6] Cancelled
        */
                Cancelled=6,
                /**
        * [10] Completed
        */
                Completed=10,
                /**
        * [15] No Show
        */
                NoShow=15,
                /**
        * [22] Rejected (no response)
        */
                NoResponse=22,
                /**
        * [25] Payment Release Requested
        */
                PaymentReleaseRequested=25,

        }

        const jobStatusDescription = {
            
                Unknown: 'Unknown',
                WaitingForHelper: 'Waiting for provider',
                Accepted: 'Accepted',
                Rejected: 'Rejected',
                Cancelled: 'Cancelled',
                Completed: 'Completed',
                NoShow: 'No Show',
                NoResponse: 'Rejected (no response)',
                PaymentReleaseRequested: 'Payment Release Requested',
        };

        export const JobStatusDescription = {

            ...jobStatusDescription,

            get: (value: JobStatus | undefined | null): string | undefined => {
                if (typeof (value) === "string") {
                    return jobStatusDescription[value];
                }
                if (value===null || value===undefined) {
                    return undefined;
                }
                return jobStatusDescription[JobStatus[value]  as keyof typeof jobStatusDescription];
            },
            all: [
                    
                    { value: 0, label: jobStatusDescription.Unknown },
                    { value: 2, label: jobStatusDescription.WaitingForHelper },
                    { value: 3, label: jobStatusDescription.Accepted },
                    { value: 4, label: jobStatusDescription.Rejected },
                    { value: 6, label: jobStatusDescription.Cancelled },
                    { value: 10, label: jobStatusDescription.Completed },
                    { value: 15, label: jobStatusDescription.NoShow },
                    { value: 22, label: jobStatusDescription.NoResponse },
                    { value: 25, label: jobStatusDescription.PaymentReleaseRequested },
            ] as ReadonlyArray<{label: string, value: number}>

        };
    