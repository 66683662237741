




        /**
        * A TypeScript version of a server-side enum. Types of Help at a Job's Address.
        */
        export enum TypeOfHelpEnum {
            
                /**
        * [0] 
        */
                Unknown=0,
                /**
        * [1] Loading
        */
                Loading=1,
                /**
        * [2] Unloading
        */
                Unloading=2,
                /**
        * [4] Move On-Site Only
        */
                MoveOnSiteOnly=4,
                /**
        * [5] Packing
        */
                Packing=5,
                /**
        * [6] 
        */
                PickupRentalTruck=6,
                /**
        * [7] 
        */
                PickupContainer=7,
                /**
        * [8] 
        */
                DropOffRentalTruck=8,
                /**
        * [9] 
        */
                DropOffContainer=9,
                /**
        * [10] 
        */
                LooseLoad=10,
                /**
        * [11] 
        */
                LooseUnload=11,
                /**
        * [12] 
        */
                WeightTicket=12,
                /**
        * [13] 
        */
                Unpacking=13,

        }

        const typeOfHelpEnumDescription = {
            
                Unknown: '',
                Loading: 'Loading',
                Unloading: 'Unloading',
                MoveOnSiteOnly: 'Move On-Site Only',
                Packing: 'Packing',
                PickupRentalTruck: 'Pickup Rental Truck',
                PickupContainer: 'Pickup Container',
                DropOffRentalTruck: 'Drop Off Rental Truck',
                DropOffContainer: 'Drop Off Container',
                LooseLoad: 'Loose Load',
                LooseUnload: 'Loose Unload',
                WeightTicket: 'Weight Ticket',
                Unpacking: 'Unpacking',
        };

        export const TypeOfHelpEnumDescription = {

            ...typeOfHelpEnumDescription,

            get: (value: TypeOfHelpEnum | undefined | null): string | undefined => {
                if (typeof (value) === "string") {
                    return typeOfHelpEnumDescription[value];
                }
                if (value===null || value===undefined) {
                    return undefined;
                }
                return typeOfHelpEnumDescription[TypeOfHelpEnum[value]  as keyof typeof typeOfHelpEnumDescription];
            },
            all: [
                    
                    { value: 0, label: typeOfHelpEnumDescription.Unknown },
                    { value: 1, label: typeOfHelpEnumDescription.Loading },
                    { value: 2, label: typeOfHelpEnumDescription.Unloading },
                    { value: 4, label: typeOfHelpEnumDescription.MoveOnSiteOnly },
                    { value: 5, label: typeOfHelpEnumDescription.Packing },
                    { value: 6, label: typeOfHelpEnumDescription.PickupRentalTruck },
                    { value: 7, label: typeOfHelpEnumDescription.PickupContainer },
                    { value: 8, label: typeOfHelpEnumDescription.DropOffRentalTruck },
                    { value: 9, label: typeOfHelpEnumDescription.DropOffContainer },
                    { value: 10, label: typeOfHelpEnumDescription.LooseLoad },
                    { value: 11, label: typeOfHelpEnumDescription.LooseUnload },
                    { value: 12, label: typeOfHelpEnumDescription.WeightTicket },
                    { value: 13, label: typeOfHelpEnumDescription.Unpacking },
            ] as ReadonlyArray<{label: string, value: number}>

        };
    