import React from 'react';
import { PaymentForm } from '../../components/PaymentForm';
import { TransientAuthType } from '../../typewriter/enums/TransientAuthType';
import { CheckoutNavigationContext } from '../CheckoutContext';


export const PaymentPage = () => {

    const { moveBack } = React.useContext(CheckoutNavigationContext);

    return <PaymentForm onGoBack={moveBack} authType={TransientAuthType.BookingSubdomain} />

}



