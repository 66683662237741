import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';
import * as React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { CheckoutContext } from '../checkout/CheckoutContext';
import { JobDetailsTypeOfHelpEnum, JobDetailsTypeOfHelpEnumDescription } from '../typewriter/enums';
import { ActionButton } from './ActionButton';
import { iconLibrary } from './Icon';

export const TypeOfHelpCard = ({iconProps, typeOfHelp, dataTestId}: { iconProps: FontAwesomeIconProps, typeOfHelp: JobDetailsTypeOfHelpEnum } & TestingElement) => {

    const [{ value }, { error, touched }, { setValue }] = useField('typeOfHelp');
    const isActive = value == typeOfHelp;

    const handleChange = React.useCallback(() => {
        setValue(typeOfHelp, true);

        const contactSection = document.querySelector( '#contactInfo' );
        contactSection!.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }, [setValue, typeOfHelp]);

    return <Button variant="link" aria-invalid={touched && !!error} data-testid={dataTestId} className={`border type-of-help-card w-100 mb-3 bg-white py-4 text-start ${isActive ? 'active' : ''}`} onClick={handleChange} style={{ borderRadius: '8px', textDecoration: 'none' }}>
        <Row className='align-items-center text-body gx-3'>
            <Col xs={'auto'}>
                <Form.Check.Input required name='typeOfHelp' isInvalid={touched && !!error} value={typeOfHelp} type='radio' checked={isActive} className="" id={`typeOfHelp-${typeOfHelp}`} onChange={handleChange}/>
            </Col>
            <Col xs={'auto'}>
                <span className="fa-layers" style={{width: '48px'}}>
                    <FontAwesomeIcon icon={iconLibrary.faCircle as IconProp} color="#FFF" size="3x"></FontAwesomeIcon>
                    <FontAwesomeIcon {...iconProps}></FontAwesomeIcon>
                </span>
            </Col>
            <Col xs xl={4} xxl={3}>
                <h3 className="fw-bold d-inline-block fs-5 mb-0">{JobDetailsTypeOfHelpEnumDescription.get(typeOfHelp)}</h3>
            </Col>
            <Col className='d-none d-md-block'>
                { typeOfHelp == JobDetailsTypeOfHelpEnum.LoadingAndUnloading ?
                    <p className='text-primary fw-bold mb-0'><FontAwesomeIcon icon={iconLibrary.faBolt as IconProp} className='pe-2' />{getTypeOfHelpSubtitle(typeOfHelp)}</p> :
                    <p className='mb-0'>{getTypeOfHelpSubtitle(typeOfHelp)}</p>
                }
            </Col>
        </Row>
    </Button>
}

export function getTypeOfHelpSubtitle(typesOfHelp: JobDetailsTypeOfHelpEnum){
    const loadingSubtitle = 'Labor at Origin Destination';
    const loadingUnloadingSubtitle = 'Save an average of 6 hours when you book loading and unloading';
    const unloading = 'Labor at Final Destination';

    switch(typesOfHelp){
        case JobDetailsTypeOfHelpEnum.Loading:
            return loadingSubtitle;
        case JobDetailsTypeOfHelpEnum.LoadingAndUnloading:
            return loadingUnloadingSubtitle;
        case JobDetailsTypeOfHelpEnum.Unloading:
            return unloading
        default:
            return '';

    }
}
