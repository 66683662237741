




        /**
        * A TypeScript version of a server-side enum. 
        */
        export enum TransportOptionType {
            
                /**
        * [0] None
        */
                None=0,
                /**
        * [1] Truck
        */
                Truck=1,
                /**
        * [2] Container
        */
                Container=2,
                /**
        * [3] Freight Trailer
        */
                FreightTrailer=3,
                /**
        * [4] Trailer (not freight)
        */
                Trailer=4,
                /**
        * [5] 
        */
                TruckSizeRange=5,
                /**
        * [6] Auto Shipping
        */
                AutoShipping=6,
                /**
        * [7] ReloCube
        */
                ReloCube=7,

        }

        const transportOptionTypeDescription = {
            
                None: 'None',
                Truck: 'Truck',
                Container: 'Container',
                FreightTrailer: 'Freight Trailer',
                Trailer: 'Trailer (not freight)',
                TruckSizeRange: 'Truck Size Range',
                AutoShipping: 'Auto Shipping',
                ReloCube: 'ReloCube',
        };

        export const TransportOptionTypeDescription = {

            ...transportOptionTypeDescription,

            get: (value: TransportOptionType | undefined | null): string | undefined => {
                if (typeof (value) === "string") {
                    return transportOptionTypeDescription[value];
                }
                if (value===null || value===undefined) {
                    return undefined;
                }
                return transportOptionTypeDescription[TransportOptionType[value]  as keyof typeof transportOptionTypeDescription];
            },
            all: [
                    
                    { value: 0, label: transportOptionTypeDescription.None },
                    { value: 1, label: transportOptionTypeDescription.Truck },
                    { value: 2, label: transportOptionTypeDescription.Container },
                    { value: 3, label: transportOptionTypeDescription.FreightTrailer },
                    { value: 4, label: transportOptionTypeDescription.Trailer },
                    { value: 5, label: transportOptionTypeDescription.TruckSizeRange },
                    { value: 6, label: transportOptionTypeDescription.AutoShipping },
                    { value: 7, label: transportOptionTypeDescription.ReloCube },
            ] as ReadonlyArray<{label: string, value: number}>

        };
    