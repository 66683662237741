import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { CheckoutContext, CheckoutNavigationContext } from '../checkout/CheckoutContext';
import { Step } from '../hooks';
import { BookingSubdomainStep } from '../typewriter/enums';
import { iconLibrary } from './Icon';

export const StepsSidebar = () => {

    const { goToStep, steps, currentStep } = React.useContext(CheckoutNavigationContext);
    const { authToken } = React.useContext(CheckoutContext);

    const handleClickStep = React.useCallback((step: Step) => {
        if (currentStep.step > step.step) {
            goToStep(step, authToken);
        }
    }, [authToken, currentStep.step, goToStep]);

    return <Container className='step-container'>
        <Row>
            <Col xs={'auto'} className='mx-auto mx-lg-0'>
                {
                    steps.filter(step => step.navTitle?.length > 0).map(s => <div key={s.step}>
                        <div onClick={() => handleClickStep(s)} style={{ cursor: currentStep.step > s.step ? 'pointer' : 'default' }} className={currentStep == s ? 'active text-primary fw-bolder step' : 'step'} title={currentStep.step > s.step ? `Go Back to ${s.title}` : ''}>
                            <FontAwesomeIcon className="pe-3" icon={currentStep == s ? s.navIconActive : s.navIcon} />
                            <span>{s.navTitle}</span>
                            { currentStep.step > s.step && <FontAwesomeIcon className="ps-3 text-primary" icon={iconLibrary.faCheck as IconProp} /> }
                        </div>
                        {s.step != BookingSubdomainStep.ReservationDetails && <div className='border-end step-divider'></div> }
                    </div>)
                }
            </Col>
        </Row>
    </Container>
}