import * as React from 'react';
import { ServiceType } from '../typewriter/enums';
import { formatCurrency } from '../utils/formatting';
import { MoveSummaryRow } from './MoveSummary';

interface Props {
    serviceType?: ServiceType,
    totals: models.BookingSubdomainJobTotals,
    typeOfHelp: string
}

export const AdditionalRatesSummaryRow = ({serviceType, totals, typeOfHelp}: Props) => {
    return totals.additionalRates
        ? serviceType === ServiceType.ManagedLaborPlus
            ? <MoveSummaryRow label={`Additional ${typeOfHelp} Rates`} description={
                <div className="d-flex flex-column">
                    {totals.additionalRates.map(r =>
                        <span key={r.id}>{`${r.name} - ${formatCurrency(r.customerPrice, { truncateZeroCents: true })}/${r.unitOfMeasurement}`}</span>)}
                </div>} />
            : <MoveSummaryRow label={'Additional Hourly Rate'} description={<>{formatCurrency(totals.additionalRates[0]?.customerPrice, { truncateZeroCents: true })}/hr</>} />
        : null;
}

export const AdditionalRatesRow = ({typeOfHelp, serviceType, totals}: Props) => {
    return totals.additionalRates && (serviceType === ServiceType.ManagedLaborPlus
        ? <AdditionalRates typeOfHelp={typeOfHelp} rates={totals.additionalRates} />
        : <AdditionalHourlyRate rate={totals.additionalRates[0].customerPrice} />);
}

const AdditionalRates = ({typeOfHelp, rates}: {typeOfHelp: string, rates: readonly models.AdditionalRate[]}) => {
    return(<div className="d-flex flex-column text-muted small mt-2">
        <div>Additional {typeOfHelp} Rates</div>
        {rates.map(r => <span className="ms-3" key={r.id}>{`${r.name} - ${formatCurrency(r.customerPrice, { truncateZeroCents: true })}/${r.unitOfMeasurement}`}</span>)}
    </div>);
}
const AdditionalHourlyRate = ({rate}: {rate: number}) => {
    return(<div className="d-flex text-muted small mt-2"><span>{`Additional Hourly Rate - ${formatCurrency(rate, { truncateZeroCents: true })}/hr`}</span></div>);
}