import React, { useEffect } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { CheckoutContext } from '../checkout/CheckoutContext';
import { BrandedNav } from '../components';
import { PaymentForm } from '../components/PaymentForm';
import { usePageTitle } from '../hooks';
import { useCheckoutContextProvider } from '../hooks/useCheckoutContextProvider';
import { TransientAuthType } from '../typewriter/enums/TransientAuthType';
import { BrandContext } from '../modules/BrandContext';



export const CompleteBooking = () => {
    
    const brandInfo = React.useContext(BrandContext);
    const { model, authToken, loading, setLoading, transportOptions, onChange } = useCheckoutContextProvider({ brand: brandInfo.brand, tokenType: TransientAuthType.CcAuth });

    useEffect(() => {
        if (brandInfo.brand != model.brandType) {
            brandInfo.setBrandType(model.brandType);
        }
    }, [brandInfo, brandInfo.brand, model.brandType]);

    usePageTitle('Moving Labor Reservation Details', brandInfo.companyName);
    return <>
        <BrandedNav brandInfo={brandInfo} affiliateInfo={model.affiliateInfo} shouldShowOffCanvas={false} />
        <CheckoutContext.Provider value={{ model, authToken, brandInfo, transportOptions, loading, setLoading, onChange }}>

            {loading && <Container className="text-center mt-5">
                <Spinner animation='border' />
                <div>Please wait... Loading</div>
            </Container>}

            <PaymentForm authType={TransientAuthType.CcAuth} />

        </CheckoutContext.Provider>
    </>;
}