import React from 'react';
import { Link } from 'react-router-dom';
import { typedHahFormikCheckbox, typedHahFormikField } from '.';

const HahFormikCheckbox = typedHahFormikCheckbox<models.ManagedSignTermsModel>();
const HahFormikField = typedHahFormikField<models.ManagedSignTermsModel>();

interface Props {
    termsHtml: string;
    authToken: string | undefined;
}
export const TermsOfService = ({ termsHtml, authToken }: Props) => {
    return <div className="mb-5">
        <div className="terms-wrapper">
            <div className="terms-and-conditions border rounded">
                <pre className="mb-0 p-3" tabIndex={0} style={{ whiteSpace: 'pre-wrap', maxHeight: '320px', overflowY: 'scroll' }} dangerouslySetInnerHTML={{ __html: termsHtml }} />
            </div>
        </div>
        <div className="d-flex pt-3 mb-3">
            <HahFormikCheckbox name="agreedToTerms" parentName="terms" label="Yes, I accept Terms & Conditions" />
            <Link className="ms-auto" to={`/tos/?token=${authToken}`} target="_blank" rel="noreferrer">Expand Terms & Conditions</Link>
        </div>
        <div className="mb-3">
            <h6>Please type your full name as your signature</h6>
            <HahFormikField name="signatureName" parentName="terms" className="customerSignature" label="Please type your full name to sign" autoComplete='name' />
        </div>
    </div>
};
