import { DateTime } from 'luxon';

export const dateTBD = DateTime.local(1, 1, 1);

export const formatDate = (value: string | undefined): string | undefined => {
    if (value) {
        const d = DateTime.fromISO(value);
        return d.equals(dateTBD) ? 'TBD' : d.toLocaleString(DateTime.DATE_SHORT);
    }
    return undefined;
}