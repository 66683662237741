




        /**
        * A TypeScript version of a server-side enum. 
        */
        export enum PaymentMethod {
            
                /**
        * [0] 
        */
                Unknown=0,
                /**
        * [1] Visa
        */
                Visa=1,
                /**
        * [2] MasterCard
        */
                Mastercard=2,
                /**
        * [3] American Express
        */
                AmericanExpress=3,
                /**
        * [4] Discover
        */
                Discover=4,
                /**
        * [5] 
        */
                CreditCard=5,
                /**
        * [6] 
        */
                Ach=6,
                /**
        * [7] 
        */
                Check=7,
                /**
        * [8] 
        */
                Transfer=8,
                /**
        * [9] PayPal/PayPal Credit
        */
                PayPal=9,
                /**
        * [10] 
        */
                Veem=10,
                /**
        * [11] 
        */
                Comdata=11,
                /**
        * [12] 
        */
                ApplePay=12,
                /**
        * [13] 
        */
                GooglePay=13,

        }

        const paymentMethodDescription = {
            
                Unknown: 'Unknown',
                Visa: 'Visa',
                Mastercard: 'MasterCard',
                AmericanExpress: 'American Express',
                Discover: 'Discover',
                CreditCard: 'Credit Card',
                Ach: 'Ach',
                Check: 'Check',
                Transfer: 'Transfer',
                PayPal: 'PayPal/PayPal Credit',
                Veem: 'Veem',
                Comdata: 'Comdata',
                ApplePay: 'Apple Pay',
                GooglePay: 'Google Pay',
        };

        export const PaymentMethodDescription = {

            ...paymentMethodDescription,

            get: (value: PaymentMethod | undefined | null): string | undefined => {
                if (typeof (value) === "string") {
                    return paymentMethodDescription[value];
                }
                if (value===null || value===undefined) {
                    return undefined;
                }
                return paymentMethodDescription[PaymentMethod[value]  as keyof typeof paymentMethodDescription];
            },
            all: [
                    
                    { value: 0, label: paymentMethodDescription.Unknown },
                    { value: 1, label: paymentMethodDescription.Visa },
                    { value: 2, label: paymentMethodDescription.Mastercard },
                    { value: 3, label: paymentMethodDescription.AmericanExpress },
                    { value: 4, label: paymentMethodDescription.Discover },
                    { value: 5, label: paymentMethodDescription.CreditCard },
                    { value: 6, label: paymentMethodDescription.Ach },
                    { value: 7, label: paymentMethodDescription.Check },
                    { value: 8, label: paymentMethodDescription.Transfer },
                    { value: 9, label: paymentMethodDescription.PayPal },
                    { value: 10, label: paymentMethodDescription.Veem },
                    { value: 11, label: paymentMethodDescription.Comdata },
                    { value: 12, label: paymentMethodDescription.ApplePay },
                    { value: 13, label: paymentMethodDescription.GooglePay },
            ] as ReadonlyArray<{label: string, value: number}>

        };
    