import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';
import {
    mcBadRequestFetchError,
    mcGoneFetchError,
    mcNotFoundFetchError,
} from '../typewriter/http-utils';
import CheckoutUrls from '../checkout/checkout-urls';
import { catchAndLogToSentry } from '../modules/catchAndLogToSentry';

/**
 * Generic error handling for token based requests. Logs to Sentry, and also will return a URL to redirect to.
 */
export function handleTokenErrorResponse(error: any, token: string, what: string): string {
    catchAndLogToSentry(error, `handleTokenErrorResponse: ${what}`, token);

    if (error.name == mcNotFoundFetchError.Name) {
        // token not found
        toast.error(
            'The link you\'ve followed appears invalid; your token cannot be found. Please start over.'
        );
        return CheckoutUrls.UnavailableUrl;
    } else if (error.name == mcBadRequestFetchError.Name) {
        // invalid token type or some fall through error
        toast.error(
            'There was an error trying to load your order, if this problem persists please contact support.'
        );
        return CheckoutUrls.UnavailableUrl;
    } else if (error.name == mcGoneFetchError.Name) {
        // expired token
        toast.error(
            'The link you\'ve followed appears to be expired. Please start over.'
        );
        return CheckoutUrls.QuoteExpiredUrl;
    } else {
        // something else unhandled or 500
        toast.error(
            'There was an unexpected error loading the your link. Please try again later, or contact support.'
        );
        return CheckoutUrls.UnavailableUrl;
    }
}
