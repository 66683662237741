import { FormikHelpers } from 'formik';
import React from 'react';
import { OnChangePickSetState, PickSetState, Step } from '../hooks/';

export interface ICheckoutContext {
    model: models.PublicBookingSubdomainQuoteModel;
    authToken: string | undefined;
    brandInfo: BrandInfo;
    transportOptions: readonly models.TransportOptionBasic[] | undefined;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    onChange: OnChangePickSetState<models.PublicBookingSubdomainQuoteModel, models.PublicBookingSubdomainQuoteModel>;
}
export const CheckoutContext = React.createContext({ model: { contactInfo: {} } } as unknown as ICheckoutContext);
export interface ICheckoutNavigationContext {
    moveNextWithChange: (change: PickSetState<models.PublicBookingSubdomainQuoteModel, models.PublicBookingSubdomainQuoteModel>, formikHelpers?: FormikHelpers<any>) => void;
    moveNext: () => void;
    moveBack: () => void;
    goToStep: (step: Step, authToken?: string) => void;
    currentStepIndex: number;
    stepsLength: number;
    steps: Step[];
    currentStep: Step;
}
export const CheckoutNavigationContext = React.createContext({} as unknown as ICheckoutNavigationContext);
