




        /**
        * A TypeScript version of a server-side enum. 
        */
        export enum BrandType {
            
                /**
        * [0] Unknown
        */
                Unknown=0,
                /**
        * [1] HireAHelper
        */
                HireAHelper=1,
                /**
        * [2] MovingPlace
        */
                MovingPlace=2,
                /**
        * [3] MovingPlace LD
        */
                MovingPlaceLD=3,
                /**
        * [4] Simple Moving Labor
        */
                SimpleMovingLabor=4,
                /**
        * [5] Moving Staffers
        */
                MovingStaffers=5,
                /**
        * [6] Simple Moving Labor (M2M)
        */
                MarketplaceToManaged=6,
                /**
        * [7] HireAHelper Corporate
        */
                HireAHelperCorporate=7,
                /**
        * [8] MovingPlace OG
        */
                MovingPlaceOG=8,
                /**
        * [9] Porch Moving Group
        */
                PorchMovingGroup=9,
                /**
        * [10] Simple Moving Labor Marketplace
        */
                SmlMarketplace=10,
                /**
        * [11] Moving Staffers Marketplace
        */
                MsMarketplace=11,

        }

        const brandTypeDescription = {
            
                Unknown: 'Unknown',
                HireAHelper: 'HireAHelper',
                MovingPlace: 'MovingPlace',
                MovingPlaceLD: 'MovingPlace LD',
                SimpleMovingLabor: 'Simple Moving Labor',
                MovingStaffers: 'Moving Staffers',
                MarketplaceToManaged: 'Simple Moving Labor (M2M)',
                HireAHelperCorporate: 'HireAHelper Corporate',
                MovingPlaceOG: 'MovingPlace OG',
                PorchMovingGroup: 'Porch Moving Group',
                SmlMarketplace: 'Simple Moving Labor Marketplace',
                MsMarketplace: 'Moving Staffers Marketplace',
        };

        export const BrandTypeDescription = {

            ...brandTypeDescription,

            get: (value: BrandType | undefined | null): string | undefined => {
                if (typeof (value) === "string") {
                    return brandTypeDescription[value];
                }
                if (value===null || value===undefined) {
                    return undefined;
                }
                return brandTypeDescription[BrandType[value]  as keyof typeof brandTypeDescription];
            },
            all: [
                    
                    { value: 0, label: brandTypeDescription.Unknown },
                    { value: 1, label: brandTypeDescription.HireAHelper },
                    { value: 2, label: brandTypeDescription.MovingPlace },
                    { value: 3, label: brandTypeDescription.MovingPlaceLD },
                    { value: 4, label: brandTypeDescription.SimpleMovingLabor },
                    { value: 5, label: brandTypeDescription.MovingStaffers },
                    { value: 6, label: brandTypeDescription.MarketplaceToManaged },
                    { value: 7, label: brandTypeDescription.HireAHelperCorporate },
                    { value: 8, label: brandTypeDescription.MovingPlaceOG },
                    { value: 9, label: brandTypeDescription.PorchMovingGroup },
                    { value: 10, label: brandTypeDescription.SmlMarketplace },
                    { value: 11, label: brandTypeDescription.MsMarketplace },
            ] as ReadonlyArray<{label: string, value: number}>

        };
    