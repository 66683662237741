import * as React from 'react';
import { formatCityStateZip } from '../modules/formatting';
import { formatWithOrdinalSuffix } from '../utils/formatting';

export const Address: React.FunctionComponent<models.JobAddressModel & { allowDev?: boolean } & { stopNumber?: number }> = ({ street, streetLineTwo, city, state, zip, stopNumber }) => {

    return <div className="address">
        {stopNumber && <b>{formatWithOrdinalSuffix(stopNumber) + ' Stop:'}</b>}
        <div className="align-middle">{street} {streetLineTwo && streetLineTwo.length <= 8 && streetLineTwo}</div>
        {streetLineTwo && streetLineTwo.length > 8 && <div>{streetLineTwo}</div>}
        <div>{formatCityStateZip(city, state, zip)}</div>
    </div>;
};
