




        /**
        * A TypeScript version of a server-side enum. 
        */
        export enum TransientAuthType {
            
                /**
        * [0] 
        */
                Unknown=0,
                /**
        * [1] 
        */
                OrderChangeRequest=1,
                /**
        * [2] 
        */
                CcAuth=2,
                /**
        * [3] 
        */
                BookingSubdomain=3,
                /**
        * [4] 
        */
                BookingSubdomainOrderConfirmation=4,
                /**
        * [5] 
        */
                UpdatePaymentInfo=5,
                /**
        * [6] 
        */
                ManagedReview=6,
                /**
        * [7] 
        */
                AutomaticRetailCheckoutLogin=7,
                /**
        * [9] 
        */
                AutomaticReplacement=9,

        }

        const transientAuthTypeDescription = {
            
                Unknown: 'Unknown',
                OrderChangeRequest: 'Order Change Request',
                CcAuth: 'Cc Auth',
                BookingSubdomain: 'Booking Subdomain',
                BookingSubdomainOrderConfirmation: 'Booking Subdomain Order Confirmation',
                UpdatePaymentInfo: 'Update Payment Info',
                ManagedReview: 'Managed Review',
                AutomaticRetailCheckoutLogin: 'Automatic Retail Checkout Login',
                AutomaticReplacement: 'Automatic Replacement',
        };

        export const TransientAuthTypeDescription = {

            ...transientAuthTypeDescription,

            get: (value: TransientAuthType | undefined | null): string | undefined => {
                if (typeof (value) === "string") {
                    return transientAuthTypeDescription[value];
                }
                if (value===null || value===undefined) {
                    return undefined;
                }
                return transientAuthTypeDescription[TransientAuthType[value]  as keyof typeof transientAuthTypeDescription];
            },
            all: [
                    
                    { value: 0, label: transientAuthTypeDescription.Unknown },
                    { value: 1, label: transientAuthTypeDescription.OrderChangeRequest },
                    { value: 2, label: transientAuthTypeDescription.CcAuth },
                    { value: 3, label: transientAuthTypeDescription.BookingSubdomain },
                    { value: 4, label: transientAuthTypeDescription.BookingSubdomainOrderConfirmation },
                    { value: 5, label: transientAuthTypeDescription.UpdatePaymentInfo },
                    { value: 6, label: transientAuthTypeDescription.ManagedReview },
                    { value: 7, label: transientAuthTypeDescription.AutomaticRetailCheckoutLogin },
                    { value: 9, label: transientAuthTypeDescription.AutomaticReplacement },
            ] as ReadonlyArray<{label: string, value: number}>

        };
    