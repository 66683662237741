import { parse } from 'qs';
import React from 'react';
import { useLocation } from 'react-router-dom';




export function useQueryStringParam(name: string) {


    const location = useLocation();

    const result = React.useMemo(() => {

        if (location.search) {
            const qs = parse(location.search, { ignoreQueryPrefix: true });

            if (qs[name]) {

                return qs[name] as string;


            }
        }

    }, [location.search, name]);

    return result;
}
