import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {  TypeOfHelpAndContactPage } from '../checkout/pages/TypeOfHelpAndContactPage';
import { PaymentPage } from '../checkout/pages/PaymentPage';
import { TransportationPage } from '../checkout/pages/TransportationPage';
import { BookingSubdomainStep } from '../typewriter/enums';
import CheckoutUrls from '../checkout/checkout-urls';
import { iconLibrary } from '../components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { MoveDetailsPage } from '../checkout/pages/MoveDetailsPage';

export const useSteps = () => {

    const steps = React.useMemo(() => {
        return [
            { url: CheckoutUrls.CheckoutBaseUrl, title: 'Enter Contact Info', component: TypeOfHelpAndContactPage, step: BookingSubdomainStep.ServiceAndContact, navTitle: 'Your Move', navIcon: iconLibrary.faHome, navIconActive: iconLibrary.faHomeSolid },
            { url: CheckoutUrls.TransportationUrl, title: 'Select Transportation', component: TransportationPage, step: BookingSubdomainStep.Transportation, navTitle: 'Transportation', navIcon: iconLibrary.faTruck, navIconActive: iconLibrary.faTruckSolid },
            { url: CheckoutUrls.MoveDetailsUrl, title: 'Enter Move Details', component: MoveDetailsPage, step: BookingSubdomainStep.MoveDetails, navTitle: 'Schedule', navIcon: iconLibrary.faBoxAlt, navIconActive: iconLibrary.faBoxAltSolid },
            { url: CheckoutUrls.ReservationDetailsUrl, title: 'Summary and Payment Information', component: PaymentPage, step: BookingSubdomainStep.ReservationDetails, navTitle: 'Checkout', navIcon: iconLibrary.faArrowCircleRight, navIconActive: iconLibrary.faArrowCircleRight },
        ] as Step[];
    }, []);

    const moveNext = useMoveStep(steps, 1);
    const moveBack = useMoveStep(steps, -1);
    const goToStep = useGoToStep();

    const location = useLocation();

    const currentStepIndex = steps.findIndex(m => m.url === location.pathname);
    const currentStep = steps[currentStepIndex];

    React.useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [location.pathname]);

    return { steps, currentStep, currentStepIndex, moveNext, moveBack, goToStep };

}

const useMoveStep = (steps: Step[], change: number) => {
    const { push } = useHistory();
    const location = useLocation();

    return React.useCallback((authtoken?: string, goToIndex?: number) => {

        const i = steps.findIndex(m => m.url === location.pathname);

        let nextStep = steps[i + change];
        if (goToIndex) {
            nextStep = steps[goToIndex];
        }
        if (nextStep) {

            const url = nextStep.url + (authtoken ? '?token=' + authtoken : '');

            push(url);
        }
    }, [change, steps, location.pathname, push]);

}
const useGoToStep = () => {
    const { push } = useHistory();

    return React.useCallback((goToStep: Step, authtoken?: string) => {

        if (goToStep) {

            const url = goToStep.url + (authtoken ? '?token=' + authtoken : '');

            push(url);
        }
    }, [push]);

}
export interface StepComponentProps {
    currentStepIndex: number;
    stepsLength: number;
    currentStep: Step;
}

export interface Step {
    url: string;
    //number: string;
    title: string;
    component: React.ComponentType<StepComponentProps>;
    step: BookingSubdomainStep;
    navTitle: string;
    navIcon: IconProp;
    navIconActive: IconProp;
    //progressBarPercentage: number;
    //pageName: string,
    //shouldRender: boolean;
}