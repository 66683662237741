import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CheckoutFooterActionBar, TransportationCard } from '../../components/';
import { PickSetState, RequireAtLeastOne, useOnChangeCallbackPickSetState } from '../../hooks';
import { TransportOptionType } from '../../typewriter/enums';
import { randomNumber } from '../../utils/randomNumber';
import { CheckoutContext, CheckoutNavigationContext } from '../CheckoutContext';


const noop = () => ({} as RequireAtLeastOne<models.PublicBookingSubdomainQuoteModel>);

export const TransportationPage = () => {

    const { model: { transportOption = { transportOptionID: 0, quantity: 1 } }, transportOptions, brandInfo, onChange } = React.useContext(CheckoutContext);
    const { moveNext } = React.useContext(CheckoutNavigationContext);

    const handleChangeTransport = useOnChangeCallbackPickSetState(onChange, 'transportOption');

    const [selectedCard, setSelectedCard] = React.useState<TransportOptionType>();

    React.useEffect(() => {

        if (transportOption.transportOptionID) {
            const selected = transportOptions?.find(m => m.transportOptionId === transportOption.transportOptionID);

            if (selected) {
                setSelectedCard(selected.type);
            }
        }

    }, [transportOption.transportOptionID, transportOptions]);


    const handleChange = React.useCallback((change: PickSetState<models.TransportOptionRef>) => {

        //onChange(prev => ({ transportOption: { ...prev.transportOption!, ...change } }));
        handleChangeTransport(change);
        toast.dismiss('transportation-validation');
    }, [handleChangeTransport]);

    const handleSelectTransportationCard = React.useCallback((val: TransportOptionType) => {
        setSelectedCard(val);
        handleChange({ transportOptionID: 0, quantity: 1 });
    }, [handleChange]);

    //const toastId = React.useRef<string | number>();

    const handleContinue = React.useCallback(() => {
        if ((transportOption.transportOptionID && transportOption.quantity)) {
            moveNext();
        }
        else {
            toast.error('You must select a transportation type and quantity.', { toastId: 'transportation-validation' })

        }
    }, [transportOption.transportOptionID, transportOption.quantity, moveNext]);



    const reducedTransportOptions = React.useMemo(() => transportOptions?.groupBy(z => z.type), [transportOptions]);

    if (!reducedTransportOptions) {
        return null; // todo: probably should handle this before they get to this page shouldn't we? also this isn't loaded if they just hit that page immediately
    }


    const testData = !isProdBuild ? () => {

        const grp = reducedTransportOptions[randomNumber(0, reducedTransportOptions.length)];

        return {
            transportOption: {
                transportOptionID: (grp && grp.items[randomNumber(0, grp.items.length)]?.transportOptionId) ?? 27,
                quantity: randomNumber(1, 3)
            }
        } as RequireAtLeastOne<models.PublicBookingSubdomainQuoteModel>;
    } : noop;


    return (
        <div>
            <Container className="pb-3 body-container gx-1">
                <Row className='heading-row'>
                    <Col>
                        <h1 className='main-title mb-4 pb-2'>Tell us about <span className='text-primary fst-italic'>your transportation</span></h1>
                        <h2 className='main-subtitle mb-md-3'>What transportation are you using for your move?</h2>
                    </Col>
                    <Col xs={'auto'}>
                        <picture>
                            <source srcSet={`/img/${brandInfo.abbrev}/transportation.png 1x, /img/${brandInfo.abbrev}/transportation2x.png 2x`} media="(min-width: 768px)" width="223" height="164"/>
                            <img src={`/img/${brandInfo.abbrev}/transportation-mobile2x.png`} alt='Moving Truck' width="64" height="64" />
                        </picture>
                    </Col>
                </Row>
                <Row>
                    <Col className="mx-auto">
                        <Row>
                            {<Col className="d-flex flex-wrap">{reducedTransportOptions.map(x =>
                                <TransportationCard key={x.key} onSelect={handleSelectTransportationCard}
                                    selected={selectedCard === x.key} transportationType={x.key} options={x.items} value={transportOption} onChange={handleChange} />)}
                            </Col>}
                        </Row>
                    </Col>
                </Row>
            </Container>
            <CheckoutFooterActionBar continueAction={handleContinue} testData={testData} />
        </div>
    );
}


