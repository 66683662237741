import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { HahFormikField, HahFormikSelectField } from '.';
import { stateAbbreviations } from '../modules/stateAbbreviations';

interface Props {
    parentName?: string;
}

export const stateOptions = [{ label: 'Select...', value: 0 }, ...stateAbbreviations];

export const AddressForm = ({ parentName }: Props) => {

    return <Row className="gx-3">
        <Col md={12} className="mb-3">
            <HahFormikField name="street" parentName={parentName} label="Street Address" autoComplete='address-line1' />
        </Col>
        <Col xs={6} className="mb-3">
            <HahFormikField name="city" parentName={parentName} label="City" autoComplete='address-level2' />
        </Col>
        <Col xs={6} className="mb-3">
            <HahFormikSelectField name="state" parentName={parentName} label="State" autoComplete='address-level1' options={stateOptions} />
        </Col>
        <Col xs={6} className="mb-3">
            <HahFormikField name="zip" parentName={parentName} label="ZIP Code" autoComplete='postal-code' />
        </Col>
        <Col xs={6} className="mb-3">
            <HahFormikField name="streetLineTwo" parentName={parentName} label="Suite/Apt. #" autoComplete='address-line2' />
        </Col>
    </Row>;
};
