import { Duration } from 'luxon';
import numeral from 'numeral';
export function expandCaps(str: null | undefined): null;
export function expandCaps(str: string): string;
export function expandCaps(str: string | null | undefined): string | null {
    if (str == null) { return null; }
    return str.replace(/([A-Za-z])([A-Z])/g, '$1 $2');
}

export function pascalCase(str: string) {
    return str && typeof (str) === 'string' ? str.substring(0, 1).toUpperCase() + str.substring(1) : str;
}

export function formatDuration(seconds: number) {
    if (seconds !== null && seconds !== undefined) {

        const d = Duration.fromMillis(seconds * 1000);

        //if (seconds > 60) {
        //const minutes = Math.round(seconds / 60);

        if (seconds > 3600) {

            //                const hours = Math.round(minutes / 60);

            //return numberWithCommas(hours) + ' hrs';

            return d.toFormat('h:mm:ss');

        }
        //return minutes + ' min';
        //return d.toFormat('m:ss');

        //}
        return d.toFormat('m:ss');
    }
    return '';
}

export function formatPhoneNumber(strippedNumber: string) {

    let clean = strippedNumber ? strippedNumber.replace(/\D/g, '') : '';
    if (clean.length == 11 && clean.substring(0, 1) == '1') {
        clean = clean.substring(1);
    }
    if (clean.length == 10) {
        let formattedNumber = clean.substring(0, 3) + '-';

        formattedNumber += clean.substring(3, 6) + '-';
        formattedNumber += clean.substring(6, 10);

        return formattedNumber;

    }

    return strippedNumber;
}

/** returns the number passed in and appends st, nd, rd or th */
export function formatWithOrdinalSuffix(i: number) {
    const j = i % 10;
    const k = i % 100;
    if (j == 1 && k != 11) {
        return i + 'st';
    }
    if (j == 2 && k != 12) {
        return i + 'nd';
    }
    if (j == 3 && k != 13) {
        return i + 'rd';
    }
    return i + 'th';
}

//declare const numeral: any;
export function formatCurrency(value: number | undefined): string;
// eslint-disable-next-line:unified-signatures
export function formatCurrency(value: number | undefined, options: { truncateZeroCents: boolean }): string;
export function formatCurrency(value: number | undefined, options?: { truncateZeroCents: boolean }) {

    if (typeof value === 'undefined') {
        return undefined;
    }
    //const v = evenRound(value, 2);
    return numeral(value).format(options?.truncateZeroCents && value == Math.round(value) ? '($0,0)' : '($0,0.00)');
}
export function formatNumber(value: number): string;
// eslint-disable-next-line:unified-signatures
export function formatNumber(value: number, options: { truncateDecimals?: boolean, decimalPlaces?: number }): string;
export function formatNumber(value: number, options?: { truncateDecimals?: boolean, decimalPlaces?: number }) {
    let format = options?.truncateDecimals && value == Math.round(value) ? '0,0' : '0,0.00';
    if (!options?.truncateDecimals && options?.decimalPlaces && options.decimalPlaces > 0) {
        format = '0,0.' + '0'.repeat(options.decimalPlaces);
    }
    return typeof value === 'undefined' ? undefined : numeral(value).format(format);
}
export function pluralize(num: number, singular: string, plural = singular + 's') {

    return num == 1 ? singular : plural;
}

export function toQuantity(num: number, singular: string, plural = singular + 's') {

    return num + ' ' + pluralize(num, singular, plural);
}

export function formatPercent(value: number): string;
// eslint-disable-next-line:unified-signatures
export function formatPercent(value: number, options: { truncateDecimals: boolean }): string;
export function formatPercent(value: number, options?: { truncateDecimals: boolean }) {
    return typeof value === 'undefined' ? undefined : numeral(Math.abs(value)).format(options?.truncateDecimals && value == Math.round(value) ? '0,0%' : '0,0.00%');
}

/**
 * Take a optional bool and display it as a string as Yes or No. Shows (unknown) if undefined/null.
 */
export function formatBool(inVal?: boolean): string {
    if (inVal == null) {
        return '(unknown)';
    }
    if (inVal == true) {
        return 'Yes';
    } else {
        return 'No';
    }
}