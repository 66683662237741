import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { usePageTitle } from '../../hooks/usePageTitle';
import { BrandTypeDescription } from '../../typewriter/enums';
import { CheckoutContext } from '../CheckoutContext';


export const UnavailablePage = () => {
    const { brandInfo } = React.useContext(CheckoutContext);

    usePageTitle('Unable to Find Available Movers', brandInfo.companyName);

    return (
        <div>
            <Container className="pb-3 body-container">
                <Row>
                    <Col>
                        <h1 className={'text-center fw-bold text-secondary'}>
                            Thank you for your interest in {BrandTypeDescription.get(brandInfo.brand)}!
                            In order to service your move we need a few more details.
                        </h1>
                        <h1 className={'text-center fw-bold text-secondary'}>
                            Please call us at <span className={'text-primary'}>{brandInfo.brandPhone}</span> and we will assist you right away.
                        </h1>
                        <p className="text-center text-headings-color">Each move is unique, and it appears we will need to ask you a few more questions to build an accurate quote.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
