import memoize from 'memoize-one';
import { AddressType } from '../typewriter/enums/AddressType';

export function formatCityState(city: string, state: string | undefined) {

    if (city) {

        if (state) {
            return city + ', ' + state;
        }
        return city;
    }

    return state || '';
}

export function formatCityStateZip(city: string, state: string, zip: string): string;
export function formatCityStateZip(address: CityStateZip | undefined): string;
export function formatCityStateZip(cityOrAddress: string | CityStateZip | undefined, state?: string, zip?: string) {

    //let city: string, state: string, zip: string;

    if (typeof (cityOrAddress) === 'string') {
        return formatCityState(cityOrAddress, state) + (zip ? ' ' + zip : '');
    }

    return cityOrAddress ? formatCityState(cityOrAddress.city, cityOrAddress.state) + (cityOrAddress.zip ? ' ' + cityOrAddress.zip : '') : '';

}

export function formatAddressOneLine(address: models.AddressModel) {

    let result = address.street;

    const {streetLineTwo} = address as models.AddressModel;

    if (streetLineTwo) {
        if (result) {
            result += ' ' + streetLineTwo;
        }
    }
    const cityStZip = formatCityStateZip(address);
    if (result) {
        if (cityStZip) {
            return result + ', ' + cityStZip;
        }
        return result;
    }

    return cityStZip;

}

interface CityStateZip {
    city: string;
    state: string;
    zip: string;
}

interface NameModel {
    name?: string;
    firstName?: string;
    lastName?: string;
}

export function getFullName(name: NameModel) {
    return name && (name.name ? name.name : ((name.firstName || '') + ' ' + (name.lastName || ''))) || '';
}

// /**
//  * KEEP IN SYNC with JobAddressFormatter.cs
//  */
export const getHomeSize = memoize((addressType?: AddressType | null, bedrooms?: number | null, prefixWithAOrAn = true) => {

    const aPrefix = prefixWithAOrAn ? 'a ' : '';
    const anPrefix = prefixWithAOrAn ? 'an ' : '';

    switch (addressType) {
        case AddressType.CondoApt:

            if (bedrooms === 0) {
                return aPrefix + 'Studio apartment';
            }
            else if (bedrooms! > 0 && bedrooms! <= 10) {
                return aPrefix + bedrooms + ' bedroom apartment';
            }
            return aPrefix + 'Condo/Apt';

        case AddressType.Studio:
            return aPrefix + 'Studio apartment';
        case AddressType.House:
            if (bedrooms! > 0 && bedrooms! <= 10) {
                return aPrefix + bedrooms + ' bedroom house';
            }
            return aPrefix + 'House';

        case AddressType.StorageUnit:
            return aPrefix + 'Storage unit';

        case AddressType.Office:
            return anPrefix + 'Office';

        case AddressType.Castle:
            return aPrefix + 'Castle';

        case AddressType.Other:
            return '(Other)';
    }

    return '';
});
