




        /**
        * A TypeScript version of a server-side enum. Really should be called ProductType or something.
        */
        export enum ServiceType {
            
                /**
        * [1] Labor-Only: Standard
        */
                Standard=1,
                /**
        * [2] Labor-Only: Elite
        */
                Elite=2,
                /**
        * [3] Movers + Truck
        */
                MoversPlusTruck=3,
                /**
        * [11] Obsolete: Managed Labor: Hourly
* @deprecated We are getting rid of managed labor hourly in early 2024
        */
                ManagedLaborHourly=11,
                /**
        * [12] Managed Labor: Plus
        */
                ManagedLaborPlus=12,
                /**
        * [4] Obsolete: Transport
* @deprecated 
        */
                Transport=4,
                /**
        * [5] Obsolete: Hybrid: ZS
* @deprecated 
        */
                HybridZS=5,
                /**
        * [7] Obsolete: Container
* @deprecated 
        */
                Container=7,
                /**
        * [8] Obsolete: Freight Trailer
* @deprecated 
        */
                FreightTrailer=8,
                /**
        * [9] Obsolete: Car Shipping
* @deprecated 
        */
                CarShipping=9,
                /**
        * [10] Obsolete: Truck Rental
* @deprecated 
        */
                TruckRental=10,
                /**
        * [13] Obsolete: Crating
* @deprecated 
        */
                Crating=13,

        }

        const serviceTypeDescription = {
            
                Standard: 'Labor-Only: Standard',
                Elite: 'Labor-Only: Elite',
                MoversPlusTruck: 'Movers + Truck',
                ManagedLaborHourly: 'Managed: Hourly',
                ManagedLaborPlus: 'Managed: Plus',
                Transport: 'Obsolete: Transport',
                HybridZS: 'Obsolete: Hybrid: ZS',
                Container: 'Obsolete: Container',
                FreightTrailer: 'Obsolete: Freight Trailer',
                CarShipping: 'Obsolete: Car Shipping',
                TruckRental: 'Obsolete: Truck Rental',
                Crating: 'Obsolete: Crating',
        };

        export const ServiceTypeDescription = {

            ...serviceTypeDescription,

            get: (value: ServiceType | undefined | null): string | undefined => {
                if (typeof (value) === "string") {
                    return serviceTypeDescription[value];
                }
                if (value===null || value===undefined) {
                    return undefined;
                }
                return serviceTypeDescription[ServiceType[value]  as keyof typeof serviceTypeDescription];
            },
            all: [
                    
                    { value: 1, label: serviceTypeDescription.Standard },
                    { value: 2, label: serviceTypeDescription.Elite },
                    { value: 3, label: serviceTypeDescription.MoversPlusTruck },
                    { value: 11, label: serviceTypeDescription.ManagedLaborHourly },
                    { value: 12, label: serviceTypeDescription.ManagedLaborPlus },
                    { value: 4, label: serviceTypeDescription.Transport },
                    { value: 5, label: serviceTypeDescription.HybridZS },
                    { value: 7, label: serviceTypeDescription.Container },
                    { value: 8, label: serviceTypeDescription.FreightTrailer },
                    { value: 9, label: serviceTypeDescription.CarShipping },
                    { value: 10, label: serviceTypeDescription.TruckRental },
                    { value: 13, label: serviceTypeDescription.Crating },
            ] as ReadonlyArray<{label: string, value: number}>

        };
    