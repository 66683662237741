import faker from '@faker-js/faker/locale/en_US';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, useFormikContext } from 'formik';
import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { CheckoutFooterActionBarFormik, ErrorFocus, iconLibrary, TypeOfHelpCard, typedHahFormikField } from '../../components';
import { PickSetState, RequireAtLeastOne } from '../../hooks';
import { typeOfHelpAndContactValidationSchema } from '../../modules';
import { JobDetailsTypeOfHelpEnum } from '../../typewriter/enums';
import { CheckoutContext, CheckoutNavigationContext } from '../CheckoutContext';

const HahFormikField = typedHahFormikField<models.ContactInfo>();

const noop = ()=> ({} as RequireAtLeastOne<models.PublicBookingSubdomainQuoteModel>);

const testData: PickSetState<models.PublicBookingSubdomainQuoteModel> = isProdBuild ? noop : () => ({ contactInfo: { firstName: faker.name.firstName(), lastName: faker.name.lastName(), email: faker.internet.email(), phone: faker.phone.phoneNumber('5##-###-####') }, typeOfHelp: JobDetailsTypeOfHelpEnum.LoadingAndUnloading });

export const TypeOfHelpAndContactPage = () => {
  const { model: { contactInfo, typeOfHelp }, brandInfo } = React.useContext(CheckoutContext);

  const { moveNextWithChange } = React.useContext(CheckoutNavigationContext);

  return <Formik
    initialValues={{ contactInfo, typeOfHelp }}
    validationSchema={typeOfHelpAndContactValidationSchema}
    enableReinitialize
    validateOnBlur
    validateOnChange={false}
    onSubmit={moveNextWithChange}>
    <Form noValidate>
      <ErrorFocus />
      <Container className="pb-3 body-container gx-1">
        <Row className='heading-row'>
            <Col>
                <h1 className='main-title mb-4 pb-2'>We'll make this a <span className='text-primary fst-italic'>hassle-free</span> move</h1>
                <h2 className='main-subtitle mb-4 mb-lg-5'>What help do you need with your move?</h2>
            </Col>
            <Col xs={'auto'}>
                <picture>
                    <source srcSet={`/img/${brandInfo.abbrev}/service.png 1x, /img/${brandInfo.abbrev}/service2x.png 2x`} media="(min-width: 768px)" width="202" height="164"/>
                    <img src={`/img/${brandInfo.abbrev}/service-mobile2x.png`} alt='Mover holding box' width="64" height="65" />
                </picture>
            </Col>
        </Row>
        <Row>
            <Col>
                <TypeOfHelpCard dataTestId="loading-btn" iconProps={{ icon: iconLibrary.faTruckLoading as IconProp, color: '#9F9E9A', size: 'lg' }} typeOfHelp={JobDetailsTypeOfHelpEnum.Loading} />
                <TypeOfHelpCard iconProps={{ icon: iconLibrary.faTruckLoading as IconProp, color: '#9F9E9A', size: 'lg', flip: 'horizontal' }} typeOfHelp={JobDetailsTypeOfHelpEnum.Unloading} />
                <TypeOfHelpCard iconProps={{ icon: iconLibrary.faPeopleCarry as IconProp, color: '#9F9E9A', size: 'lg' }} typeOfHelp={JobDetailsTypeOfHelpEnum.LoadingAndUnloading} />
                <Form.Control.Feedback type="invalid">Please select loading and/or unloading.</Form.Control.Feedback>
                <p className='text-primary fw-bold d-flex align-items-center d-md-none'><FontAwesomeIcon icon={iconLibrary.faBolt as IconProp} className='pe-2' />Save an average of 6 hours when you book loading and unloading</p>
            </Col>
        </Row>
        <Row id='contactInfo' className='pt-4'>
            <Col md={6}>
                <div>
                    <h3 className='mb-4 fs-5'>Tell us about yourself</h3>
                    <p>This information helps us lock in your quote for the best price. We take your privacy seriously and we <em>never</em> share your contact details.</p>
                    <h4 className='text-success fw-bold fs-5 pb-4 pb-md-0'><FontAwesomeIcon icon={iconLibrary.faShieldAlt as IconProp} className='pe-2' />Trusted Moving Partners</h4>
                </div>
            </Col>
            <Col md={6}>
                <Row className="pb-4 gx-3 align-items-center">
                    <Col xs="auto">
                        <StatusIcon icon={iconLibrary.faUser} name={['firstName', 'lastName']} />
                    </Col>
                    <Col>
                        <Row className="gx-3">
                            <Col>
                                <div className='pb-4'><HahFormikField parentName='contactInfo' name="firstName" label="First Name" autoComplete='given-name' /></div>
                                <HahFormikField parentName='contactInfo' name="lastName" label="Last Name" autoComplete='family-name' />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="pb-4 gx-3">
                    <Col xs="auto">
                        <StatusIcon icon={iconLibrary.faPhone} name={'phone'} />
                    </Col>
                    <Col>
                        <HahFormikField parentName='contactInfo' name="phone" label="Phone Number" autoComplete='tel' />
                    </Col>
                </Row>
                <Row className="gx-3">
                    <Col xs="auto">
                        <StatusIcon icon={iconLibrary.faEnvelope} name={'email'} />
                    </Col>
                    <Col>
                        <HahFormikField parentName='contactInfo' name="email" label="Email Address" autoComplete='email' type='email' />
                    </Col>
                </Row>
            </Col>
        </Row>
      </Container>
      <CheckoutFooterActionBarFormik testData={testData} />
    </Form>
  </Formik>;
};


const StatusIcon = ({ icon, name }: { icon: IconDefinition, name: keyof models.ContactInfo | (keyof models.ContactInfo)[] }) => {

  const { errors, touched } = useFormikContext<Pick<models.BookingSubdomainQuoteModelBase, 'contactInfo'>>();

  const errorsContact = errors.contactInfo;
  const touchedContact = touched.contactInfo;

  let isValid = touchedContact && !errorsContact;

  if (touchedContact && errorsContact) {
    isValid = (Array.isArray(name) ? name.every(m => touchedContact[m] && !errorsContact[m]) : touchedContact[name] && !errorsContact[name]) ?? false;
  }

  return <div className="mt-3" style={{ width: '42px' }}>
    <span className="fa-layers w-100">
      <FontAwesomeIcon icon={iconLibrary.faCircle as IconProp} className={`text-${isValid ? 'primary' : 'light-gray'}`} style={{ fontSize: 42 }}></FontAwesomeIcon>
      <FontAwesomeIcon icon={icon as IconProp} className="text-white" style={{ fontSize: 21 }}></FontAwesomeIcon>
    </span>
  </div>
}