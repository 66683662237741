import { HostedFieldsTokenizePayload } from 'braintree-web';
import { Field } from 'formik';
import { tokenizeFunc } from 'react-braintree-fields';


type TokenizePaymentResult =
    { success: true, nonce: string }
    |
    {
        success: false, error: string, code?: string, invalidFieldKeys?: string[]
    };


const invalidFieldMap: { [key: string]: { fieldName: string, message: string } } = {
    'number': {
        fieldName: 'paymentInfo.cardNumber',
        message: 'Invalid Card Number'
    },
    'cardholderName': {
        fieldName: 'paymentInfo.cardholderName',
        message: 'Invalid Name on Card'
    },
    'expirationDate': {
        fieldName: 'paymentInfo.expirationDate',
        message: 'Invalid Expiration Date'
    },
    'cvv': {
        fieldName: 'paymentInfo.cvv',
        message: 'Invalid CVV'
    },
};

export async function tokenizePayment(tokenizeFunc: tokenizeFunc | undefined, setFieldError: (field: string, message: string | undefined) => void): Promise<TokenizePaymentResult> {

    if (!tokenizeFunc) {
        //alert('An unexpected error occurred');

        return { success: false, error: 'An unexpected error occurred' };
    }

    let tokenResult: HostedFieldsTokenizePayload;
    try {

        tokenResult = await tokenizeFunc();
        return { success: true, nonce: tokenResult.nonce };


    } catch (ex: any) {
        switch (ex.code) {
            case 'HOSTED_FIELDS_FIELDS_EMPTY':
                return { success: false, error: 'Some fields are empty. Please check your entries and try again.', code: ex.code }

            //break;
            case 'HOSTED_FIELDS_FIELDS_INVALID':
                {
                    (ex.details.invalidFieldKeys as string[]).forEach(f => {
                        const field = invalidFieldMap[f];
                        if (field) {
                            setFieldError(field.fieldName, field.message);
                        }
                    });
                    return { success: false, error: 'Some fields are invalid. Please check your entries and try again.', code: ex.code, invalidFieldKeys: ex.details.invalidFieldKeys }

                    //break;
                }
            // case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
            //     console.error('Tokenization failed server side. Is the card valid?');
            //     break;
            // case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
            //     console.error('Network error occurred when tokenizing.');
            //     break;
            default:
                return { success: false, error: 'An unexpected error occurred. Please try again.', code: ex.code };

        }

    }
}
