import React from 'react';
import { useHistory } from 'react-router-dom';
import { getUpdatePaymentInfo } from '../typewriter/controllers/OrderController';
import { useQueryStringParam } from './useQueryStringParam';
import { usePickSetState } from './usePickSetState';
import { handleTokenErrorResponse } from './handleTokenErrorResponse';

export function useUpdatePaymentInfoModel() {
    const [model, setModel] = usePickSetState<models.UpdatePaymentInfoModel>();

    const [loading, setLoading] = React.useState(true);

    const { replace } = useHistory();

    const token = useQueryStringParam('token');

    React.useEffect(() => {
        if (token) {
            setLoading(true);

            (async () => {
                try {
                    const result = await getUpdatePaymentInfo(token);
                    setModel(result);
                    setLoading(false);
                } catch (error: any) {
                    setLoading(false);
                    replace(handleTokenErrorResponse(error, token, 'getUpdatePaymentInfo'));
                }
            })();
        }
    }, [replace, setLoading, setModel, token]);

    return { authToken: token, model, loading, setLoading, setModel };
}
