




        /**
        * A TypeScript version of a server-side enum. 
        */
        export enum ClaimType {
            
                /**
        * [0] Service Complaint
        */
                ServiceComplaint=0,
                /**
        * [1] Damage Claim
        */
                DamageClaim=1,

        }

        const claimTypeDescription = {
            
                ServiceComplaint: 'Service Complaint',
                DamageClaim: 'Damage Claim',
        };

        export const ClaimTypeDescription = {

            ...claimTypeDescription,

            get: (value: ClaimType | undefined | null): string | undefined => {
                if (typeof (value) === "string") {
                    return claimTypeDescription[value];
                }
                if (value===null || value===undefined) {
                    return undefined;
                }
                return claimTypeDescription[ClaimType[value]  as keyof typeof claimTypeDescription];
            },
            all: [
                    
                    { value: 0, label: claimTypeDescription.ServiceComplaint },
                    { value: 1, label: claimTypeDescription.DamageClaim },
            ] as ReadonlyArray<{label: string, value: number}>

        };
    