import * as Sentry from '@sentry/browser';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { get, review } from '../typewriter/controllers/ManagedReviewController';
import { TokenResult } from '../typewriter/enums';
import { useQueryStringParam } from './useQueryStringParam';
import { handleTokenErrorResponse } from './handleTokenErrorResponse';

export function useManagedReviewModel() {
    const [model, setModel] = React.useState<models.CustomerManagedReviewModel>();

    const [loading, setLoading] = React.useState(true);
    const [editMode, setEditMode] = React.useState<boolean>(true);
    const [tokenExpired, setTokenExpired] = React.useState<boolean>(false);

    const token = useQueryStringParam('token');

    const history = useHistory();

    React.useEffect(() => {
        if (token) {
            setLoading(true);

            (async () => {
                try {
                    const result = await get(token);
                    setModel(result);
                    setTokenExpired(result.tokenResult == TokenResult.Expired);
                    setLoading(false);

                    setEditMode(result.jobs.some(x => x.customerRating == 0 && x.customerReview == ''));

                } catch (error: any) {
                    setLoading(false);
                    history.replace(handleTokenErrorResponse(error, token, 'managedReview get'));
                }
            })();
        }
    }, [history, setLoading, token]);



    const onUpdate = React.useCallback(async (jobs: readonly models.ManagedReviewJobModel[], moveReviews: readonly models.ManagedMoveReviewModel[]) => {
        try {
            const jobsWithNoStarRating = jobs.some(x => !x.customerRating);
            if(jobsWithNoStarRating){
                toast.warn('Please select a star rating.');
                return;
            }
            const redirectToClaims = jobs.some(x => x.customerRating < 2);
            setLoading(true);
            const reviewModel: models.CustomerManagedReviewModel = {
                ...model!,
                jobs: jobs,
                moveReviews: moveReviews
            };
            const result = await review(reviewModel);

            if (result.success) {
                setLoading(false);
                setEditMode(false);

                if (redirectToClaims)
                {
                    history.push({
                        pathname: '/claim/',
                        state: {
                            orderId: model?.orderId,
                            token: token,
                            firstName: model?.customerFirstName,
                            lastName: model?.customerLastName,
                            phoneNumber: model?.customerNumber,
                            email: model?.customerEmail,
                            typesOfHelp: jobs.map(j => j.typeOfHelp)
                        }
                    });
                }
            }

        } catch (error) {
            Sentry?.captureException(error, {
                extra: {
                    model
                }
            });

            setLoading(false);
            toast.error('There was an error trying to update your review, if this problem persists please contact support.');
        }
    }, [token, model, history]);

    return { authToken: token, model, loading, setLoading, editMode, tokenExpired, setEditMode, onUpdate };
}