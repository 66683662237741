// Should be kept in sync with AvailableArrivalWindows.cs
export const arrivalWindows = [
    { label: 'Select...', value: '' },
    { label: 'Arrival Between 8 AM - 9 AM', value: 'Arrival Between 8 AM - 9 AM' },
    { label: 'Arrival Between 9 AM - 10 AM', value: 'Arrival Between 9 AM - 10 AM' },
    { label: 'Arrival Between 10 AM - 11 AM', value: 'Arrival Between 10 AM - 11 AM' },
    { label: 'Arrival Between 11 AM - 12 PM', value: 'Arrival Between 11 AM - 12 PM' },
    { label: 'Arrival Between 12 PM - 1 PM', value: 'Arrival Between 12 PM - 1 PM' },
    { label: 'Arrival Between 1 PM - 2 PM', value: 'Arrival Between 1 PM - 2 PM' },
    { label: 'Arrival Between 2 PM - 3 PM', value: 'Arrival Between 2 PM - 3 PM' },
    { label: 'Arrival Between 3 PM - 4 PM', value: 'Arrival Between 3 PM - 4 PM' },
    { label: 'Arrival Between 4 PM - 5 PM', value: 'Arrival Between 4 PM - 5 PM' },
    { label: 'Arrival Between 5 PM - 6 PM', value: 'Arrival Between 5 PM - 6 PM' },
    { label: 'Arrival Between 6 PM - 7 PM', value: 'Arrival Between 6 PM - 7 PM' }
]