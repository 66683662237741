import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { usePageTitle } from '../../hooks/usePageTitle';
import { CheckoutContext } from '../CheckoutContext';
import { ActionButton, iconLibrary } from '../../components';
import CheckoutUrls from '../checkout-urls';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';


export const QuoteExpiredPage = () => {
    const { brandInfo } = React.useContext(CheckoutContext);

    usePageTitle('Unable to Find Available Movers', brandInfo.companyName);

    const history = useHistory();

    const handleClick = () => {
        history.replace(CheckoutUrls.CheckoutBaseUrl);
    }
    return (
        <div>
            <Container className="pb-3 body-container">
                <Row className='justify-content-center'>
                    <Col xs={'10'} md={'6'} lg={'5'} xl={'4'}>
                        <div className='bg-white border rounded px-3 py-4'>
                            <div className='text-center'>
                                <picture>
                                    <source srcSet={`/img/${brandInfo.abbrev}/quote-expired.png 1x, /img/${brandInfo.abbrev}/quote-expired2x.png 2x`} media="(min-width: 768px)" width="156" height="184"/>
                                    <img src={`/img/${brandInfo.abbrev}/quote-expired2x.png`} alt='Moving Truck' width="156" height="184" />
                                </picture>
                            </div>
                            <h4 className={'fw-normal'}>
                                We can't find your quote
                            </h4>
                            <p>
                                Don't worry! Create a new quote to save a ton of money on your upcoming move.
                            </p>
                            <div className='text-center'>
                                <ActionButton variant="primary" className='text-white rounded fw-normal my-4' onClickAction={handleClick}>Start a new quote <FontAwesomeIcon className=' ms-2' icon={iconLibrary.faExternalLinkAlt as IconProp} /></ActionButton>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
