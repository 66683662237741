import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useState } from 'react';
import { Carousel, Col, Row } from 'react-bootstrap';
import getIcons from '../../utils/ratingIcons';
import { iconLibrary } from '../Icon';

interface ProviderReview {
    text: string;
    name: string;
    location: string;
    typeOfHelp: string;
    dateOfHelp: string;
}

export const ProviderReviews = ({brandInfo}:{brandInfo: BrandInfo}) => {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex: React.SetStateAction<number>, e: any) => {
        setIndex(selectedIndex);
    };

    const review1 = { text: 'Outstanding team effort & service, both personally & professionally. Countless kindnesses & exceptional caring for me & by extension, my family. Highest possible rating I can offer. Thank you to each team member for everything.', name: 'Donald', location: 'Seattle, WA', typeOfHelp: 'Unloading', dateOfHelp: '2/6/2023' } as ProviderReview;
    const review2 = { text: 'The guys were very helpful and great at what they did. They were professional and extremely careful with our stuff.', name: 'Kimberly', location: 'Norcross, GA', typeOfHelp: 'Unloading', dateOfHelp: '2/21/2023' } as ProviderReview;
    const review3 = { text: 'The gentlemen that helped were amazingly helpful and went over and beyond to make everything an easy transition. Thank you!', name: 'Suzanne', location: 'Kingston, MA', typeOfHelp: 'Loading', dateOfHelp: '12/20/2022' } as ProviderReview;

    const reviews = [review1, review2, review3] as ProviderReview[]

    return <div className="d-none d-lg-block">
        <h5 className='mb-3'>{brandInfo.companyName} Reviews:</h5>

        <Carousel activeIndex={index} onSelect={handleSelect} interval={5000} controls={false} variant="dark">

                {reviews.map((review) => (
                    <Carousel.Item key={review.name}>
                    <p className="text-light-gray">
                        {review.text}
                    </p>
                    <Row className='gx-2'>
                        <Col xs='auto'>
                            <span className="fa-stack">
                                <FontAwesomeIcon className="fa-stack-2x text-primary" icon={iconLibrary.faCircle as IconProp}/>
                                <FontAwesomeIcon className="fa-stack-1x fa-inverse" style={{fontSize: '1.4em'}} icon={iconLibrary.faPersonCarry as IconProp}/>
                            </span>
                        </Col>
                        <Col>
                            <h5>{review.name}</h5>
                            <div className='mb-2'>
                                {getIcons(5).map((v, i) => {
                                    return <FontAwesomeIcon key={i} className="text-primary" icon={v as IconProp} />
                                })} ({5} out of 5 rating)
                            </div>

                            <div className='mb-2'><FontAwesomeIcon icon={iconLibrary.faMapMarkerAlt as IconProp}/> From {review.location}</div>
                            <div>About <span className='text-primary'>{review.typeOfHelp}</span> on {review.dateOfHelp}</div>
                        </Col>
                    </Row>
                </Carousel.Item>
                ))}
            </Carousel>
        </div>
}