import * as React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import CheckoutUrls from '../checkout/checkout-urls';
import { iconLibrary } from './Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const BrandedNav = ({
    brandInfo,
    affiliateInfo,
    toggleOffCanvas,
    shouldShowOffCanvas,
}: {
    brandInfo: BrandInfo;
    affiliateInfo?: models.BookingSubdomainQuoteAffiliateInfoModel | undefined;
    toggleOffCanvas?: () => void;
    shouldShowOffCanvas: boolean;
}) => {
    return (
        <Navbar expand='lg' className='py-3 mb-3'>
            <Container fluid='xxl'>
                {toggleOffCanvas && shouldShowOffCanvas && (
                    <Navbar.Toggle
                        className='d-lg-none'
                        onClick={toggleOffCanvas}
                    />
                )}
                <Navbar.Brand
                    href={CheckoutUrls.CheckoutBaseUrl}
                    className='me-0'
                >
                    <img
                        src={`${brandInfo.logoUrl}`}
                        width='240'
                        height='40'
                        className='d-inline-block align-top brand-logo'
                        alt={brandInfo.companyName}
                    />
                </Navbar.Brand>
                <a
                    className='btn btn-sm btn-light p-2 d-flex align-items-center help-icon'
                    href={`tel:${brandInfo.cleanPhone}`}
                >
                    <FontAwesomeIcon
                        flip={'horizontal'}
                        className='ps-md-2'
                        icon={iconLibrary.faPhone as IconProp}
                    />
                    <span className='d-none d-md-inline'>
                        Need help? Call {brandInfo.brandPhone}
                    </span>
                </a>
                {affiliateInfo?.logoUrl && (
                    <span className='d-none d-lg-inline-block'>
                        <img
                            alt={affiliateInfo.publicName}
                            src={affiliateInfo.logoUrl}
                            style={{ maxHeight: '40px', maxWidth: '30vw' }}
                        />
                    </span>
                )}
            </Container>
        </Navbar>
    );
};
