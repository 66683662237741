import * as Sentry from '@sentry/react';
import React from 'react';
import { toast } from 'react-toastify';
import { getTos } from '../typewriter/controllers/BookingSubdomainController';

export const useTos = () => {
    const [tos, setTos] = React.useState<models.BookingSubdomainTos>();

    React.useEffect(() => {
        if (!tos) {
            (async () => {
                try {
                    const result = await getTos();
                    setTos(result);
                } catch (error) {
                    Sentry?.captureException(error);
                    toast.error('Sorry, an unexpected error occurred. Please try refreshing your browser. If the problem continues, please call us for assistance');
                }
            })();
        }
    }, [tos]);

    return tos;
}