// THIS IS COPIED FROM OUR MAIN REACT PACKAGES static\Hireahelper.Web.React\packages\hah-shared\src\hooks\useOnMountUnsafe.ts - PLEASE KEEP IN SYNC

import type { EffectCallback } from 'react';
import { useEffect, useRef } from 'react';

/**
 * We sometimes need to call a useEffect function only once  - regardless of dev mode strict mode checks or whatever (which causes it to run twice).
 * So this hook lets us ensure the useEffect function is only called once.
 * P.S. I hate React. It treats us like little kids sometimes.
 * @param effect The effect you want to run only once.
 */
export function useOnMountUnsafe(effect: EffectCallback) {
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            effect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}