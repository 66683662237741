import classNames from 'classnames';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import { HostedField } from 'react-braintree-fields';

interface HostedFieldWithLabelProps {

    type: BraintreeFieldType;
    label: string;
    placeholder?: string;
    setRef: (field: typeof HostedField) => void;
    value: BraintreeFieldValue | string | undefined;
    onChange: (value: BraintreeFieldValue | string) => void;
    onBlur?: () => void;
    isValid: boolean | undefined;
    isInvalid?: boolean | undefined;
    name: string | undefined;

    error?: string
    
}


export const HostedFieldWithLabel = ({ type, label, placeholder, setRef, value, onChange, name, isValid, isInvalid, error, onBlur }: HostedFieldWithLabelProps) => {

    const [hasFocus, setHasFocus] = React.useState<boolean>();

    //const invalidFeedback = transformErrorMessage(validationState?.errorMessage, label);
    const className = classNames('form-control', hasFocus && 'focused', value === BraintreeFieldValueEmpty ? 'empty' : 'non-empty', isInvalid && 'is-invalid');
    const handleOnBlur = React.useCallback(() => {
        setHasFocus(false);
        onBlur?.();

    }, [onBlur]);

    return <div className="braintree-hosted">
        <div className="form-floating">
            <HostedField
                type={type}
                name={name}
                className={className}
                onBlur={handleOnBlur}
                onFocus={() => setHasFocus(true)}
                onNotEmpty={() => onChange(BraintreeFieldValueInvalid)}
                onEmpty={() => onChange(BraintreeFieldValueEmpty)}
                placeholder={placeholder}
                ref={setRef}
                onValidityChange={v => onChange(v.isValid ? BraintreeFieldValueValid : v.isEmpty ? BraintreeFieldValueEmpty : BraintreeFieldValueInvalid)} />

            <label htmlFor={type} onClick={() => () => setHasFocus(true)}>{label}</label>
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>


        </div>
    </div>;
};




export type BraintreeFieldType = 'number' | 'expirationDate' | 'expirationMonth' | 'expirationYear' | 'cvv' | 'postalCode' | 'cardholderName';

// ** possible values: 
// undefined or '' means field is empty 
// '0' means field is set but is invalid
// '1' means field is valid */

export const BraintreeFieldValueEmpty = '';
export type BraintreeFieldValueEmpty = '';

export const BraintreeFieldValueInvalid = '0';
export type BraintreeFieldValueInvalid = '0';

export const BraintreeFieldValueValid = '1';
export type BraintreeFieldValueValid = '1';

export type BraintreeFieldValue = BraintreeFieldValueEmpty | BraintreeFieldValueInvalid | BraintreeFieldValueValid;

