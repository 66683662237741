import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HostedFields } from 'braintree-web';
import { useFormikContext } from 'formik';
import React from 'react';
import { Spinner, InputGroup, Col, Row } from 'react-bootstrap';
import { Braintree, CardType, HostedField } from 'react-braintree-fields';
import { ActionButton, HahFormikField, HostedFieldWithLabel, iconLibrary } from '..';
import { BookOrderValidationModel } from '../../modules';

export type tokenizeFunc = HostedFields['tokenize'];

export interface PaymentInfoProps {
    braintreeClientToken: string;
    tokenizeRef: React.MutableRefObject<tokenizeFunc | undefined>;
    onError: (error: Error) => void;
    setTestNonce: (value: string | undefined) => void;
}

export const PaymentInfo = ({ braintreeClientToken, tokenizeRef, onError }: PaymentInfoProps) => {

    const btFieldRefs = React.useRef<{
        cardholderName?: typeof HostedField;
        number?: typeof HostedField;
        expirationDate?: typeof HostedField;
        cvv?: typeof HostedField;
    }>({});

    const [cardType, setCardType] = React.useState('');

    const onCardTypeChange = ({ cards }: { cards: CardType[]; }) => {
        if (1 === cards.length) {

            const [card] = cards;

            setCardType(card.type);

        } else {
            setCardType('');
        }
    };

    const { values: { paymentInfo: values }, setFieldValue: setFieldValueParent, setFieldTouched, errors: { paymentInfo: errors = {} }, touched: { paymentInfo: touched = {} } } = useFormikContext<BookOrderValidationModel>();

    const setFieldValue = React.useCallback((field: string, value: string, shouldValidate?: boolean) => {
        setFieldValueParent('paymentInfo.' + field, value, shouldValidate)
    }, [setFieldValueParent]);


    return <Braintree
        className="payment-info"
        authorization={braintreeClientToken}
        onError={onError}
        onCardTypeChange={onCardTypeChange}
        getTokenRef={ref => tokenizeRef.current = ref}
        styles={{
            'input': {
                'font-size': '16px',
                'font-family': 'Roboto, "Helvetica Neue", Arial, sans-serif',
                'color': '#3a3a3a'
            },
            ':focus': {
                'color': 'black'
            }
        }}
    >
        <Row className="pb-3">
            <Col>
                <HostedFieldWithLabel setRef={r => btFieldRefs.current.cardholderName = r} type="cardholderName" name="cardholderName" label="Name on Card" value={values.cardholderName} onChange={value => setFieldValue('cardholderName', value, true)} onBlur={() => setFieldTouched('cardholderName', true)} isValid={touched.cardholderName && !errors.cardholderName} isInvalid={touched.cardholderName && !!errors.cardholderName} error={errors.cardholderName} />
            </Col>
        </Row>
        <Row className="align-items-center pb-3">
            <Col>
                <InputGroup className='flex-nowrap'>
                    <HostedFieldWithLabel setRef={r => btFieldRefs.current.number = r} type="number" name="cardNumber" label="Card Number" value={values.cardNumber} onChange={value => setFieldValue('cardNumber', value, true)} onBlur={() => setFieldTouched('cardNumber', true)} isValid={touched.cardNumber && !errors.cardNumber} isInvalid={touched.cardNumber && !!errors.cardNumber} error={errors.cardNumber} />
                    <InputGroup.Text id="basic-addon1" style={{maxHeight: '58px'}}><FontAwesomeIcon icon={getCardTypeIcon(cardType) as IconProp} size="lg" /></InputGroup.Text>
                </InputGroup>
            </Col>
        </Row>
        <Row>
            <Col xs={8} md={4} className="pb-3">
                <HostedFieldWithLabel setRef={r => btFieldRefs.current.expirationDate = r} type="expirationDate" name="expirationDate" label="Exp Date" value={values.expirationDate} onChange={value => setFieldValue('expirationDate', value, true)} onBlur={() => setFieldTouched('expirationDate', true)} isValid={touched.expirationDate && !errors.expirationDate} isInvalid={touched.expirationDate && !!errors.expirationDate} error={errors.expirationDate} />
            </Col>
            <Col xs={4} md={3} lg={3} className="pb-3">
                <HostedFieldWithLabel setRef={r => btFieldRefs.current.cvv = r} type="cvv" name="cvv" label="CVV" value={values.cvv} onChange={value => setFieldValue('cvv', value, true)} onBlur={() => setFieldTouched('cardNumber', true)} isValid={touched.cvv && !errors.cvv} isInvalid={touched.cvv && !!errors.cvv} error={errors.cvv} />
            </Col>
            <Col xs={8} md={5} className="pb-3">
                <HahFormikField name="zip" parentName="paymentInfo.address" label="Billing ZIP Code" autoComplete='postal-code' />
            </Col>
        </Row>
    </Braintree>;
};

export function getCardTypeIcon(cardType: string) {
    switch (cardType) {
        case 'visa':
            return iconLibrary.faCcVisa;
        case 'master-card':
            return iconLibrary.faCcMastercard;

        case 'american-express':
            return iconLibrary.faCcAmex;

        case 'discover':
            return iconLibrary.faCcDiscover;

    }
    return iconLibrary.faCreditCard;
}

export const BookOrderButton = ({ isFormSubmitting, onClick }: { isFormSubmitting: boolean, onClick: () => void }) => {

    const inner = isFormSubmitting ? <><Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
    />
        <span className="d-inline-block ms-1">BOOKING...</span>
    </>
        : 'Confirm Payment'

    return <ActionButton variant="btn btn-primary text-white w-100" onClickAction={onClick} disabled={isFormSubmitting}>{inner}</ActionButton>
}