import { Formik, FormikHelpers, useField } from 'formik';
import { DateTime } from 'luxon';
import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CheckoutFooterActionBarFormik, ErrorFocus, HahFormikField, LocationCard, typedHahFormikSelectField } from '../../components/';
import { HeavyItems } from '../../components/HeavyItems';
import { PickSetState, RequireAtLeastOne } from '../../hooks/';
import { moveDetailsValidationSchema, MoveDetailsModel, LocationValidationModel } from '../../modules/';
import { emptyLocation, testLocation } from '../../modules/emptyAddress';
import { JobDetailsTypeOfHelpEnum, TypeOfHelpEnum } from '../../typewriter/enums';
import { CheckoutContext, CheckoutNavigationContext } from '../CheckoutContext';

const noop = () => ({} as RequireAtLeastOne<models.PublicBookingSubdomainQuoteModel>);

const testData: PickSetState<models.PublicBookingSubdomainQuoteModel> = isProdBuild ? noop : () => ({
    loadingLocation: { ...testLocation(TypeOfHelpEnum.Loading) },
    unloadingLocation: { ...testLocation(TypeOfHelpEnum.Unloading) },
    heavyItems: { anyHeavyItems: true, heavyItemQuantities: { heavyItemsUprightPianosQty: 0, heavyItemsBabyGrandPianosQty: 0, heavyItemsBetweenFourFiftyAndSixQty: 0, heavyItemsBetweenThreeAndFourFiftyQty: 1, heavyItemsSixPlusQty: 0, indicatedHasHeavyItems: true }  },
    moveDescription: 'This is just a test move with fake data.',
    typeOfHelp: JobDetailsTypeOfHelpEnum.LoadingAndUnloading
});

export const MoveDetailsPage = () => {

    const { model: { heavyItems, typeOfHelp, loadingLocation, unloadingLocation, moveDescription  }, brandInfo } = React.useContext(CheckoutContext);

    const { moveNextWithChange } = React.useContext(CheckoutNavigationContext);

    const getLocationModel = (model: models.BookingSubdomainJobInfo) => {
        const location = { // this is insane but if we use the spread operator we get a bunch of excess..
            desiredArrivalWindow: model.desiredArrivalWindow,
            jobDate: model.jobDate,
            city: model.jobAddresses?.[0].city,
            zip: model.jobAddresses?.[0].zip,
            state: model.jobAddresses?.[0].state,
            street: model.jobAddresses?.[0].street,
            streetLineTwo: model.jobAddresses?.[0].streetLineTwo,
            type: model.jobAddresses?.[0].type,
            sqFootage: model.jobAddresses?.[0].sqFootage,
            stairs: model.jobAddresses?.[0].stairs,
            typeOfHelp: model.jobAddresses?.[0].typeOfHelp,
        } as LocationValidationModel;
        return location;
    };

    const handleSubmit = React.useCallback((values: MoveDetailsModel, formikHelpers: FormikHelpers<MoveDetailsModel>) => {
        const hasLoading = (values.typeOfHelp == JobDetailsTypeOfHelpEnum.Loading || values.typeOfHelp == JobDetailsTypeOfHelpEnum.LoadingAndUnloading);
        const hasUnloading = (values.typeOfHelp == JobDetailsTypeOfHelpEnum.Unloading || values.typeOfHelp == JobDetailsTypeOfHelpEnum.LoadingAndUnloading);

        if (hasLoading && hasUnloading && values.loadingLocation?.jobDate && values.unloadingLocation?.jobDate) {
            if (DateTime.fromISO(values.loadingLocation.jobDate) > DateTime.fromISO(values.unloadingLocation.jobDate)) {
                toast.error('Your unloading date must be after your loading date.');
                formikHelpers.setFieldError('unloadingLocation.jobDate', 'Your unloading date must be after your loading date.');
                const invalidInput = document.querySelector('.is-invalid ~ .invalid-feedback');
                invalidInput!.scrollIntoView( { behavior: 'smooth', block: 'center' } );
                return;
            }
        }
        const loadingLocation = {
            desiredArrivalWindow: values.loadingLocation?.desiredArrivalWindow,
            jobDate: values.loadingLocation?.jobDate,
            jobAddresses: [{...values.loadingLocation} as models.JobAddressModel]
        } as models.BookingSubdomainJobInfo;
        const unloadingLocation =  {
            desiredArrivalWindow: values.unloadingLocation?.desiredArrivalWindow,
            jobDate: values.unloadingLocation?.jobDate,
            jobAddresses: [{...values.unloadingLocation} as models.JobAddressModel]
        } as models.BookingSubdomainJobInfo;
        moveNextWithChange({ heavyItems: values.heavyItems, moveDescription: values.moveDescription, typeOfHelp: values.typeOfHelp,  loadingLocation: hasLoading ? loadingLocation : undefined, unloadingLocation: hasUnloading ? unloadingLocation : undefined }, formikHelpers);
    }, [moveNextWithChange]);

    const initialValues: MoveDetailsModel = {
        loadingLocation: (loadingLocation?.jobAddresses?.[0].street != undefined ? getLocationModel(loadingLocation) : { ...emptyLocation, typeOfHelp: TypeOfHelpEnum.Loading }),
        unloadingLocation: (unloadingLocation?.jobAddresses?.[0].street != undefined ? getLocationModel(unloadingLocation) : { ...emptyLocation, typeOfHelp: TypeOfHelpEnum.Unloading }),
        heavyItems: heavyItems ?? { anyHeavyItems: undefined, heavyItemQuantities: { heavyItemsUprightPianosQty: 0, heavyItemsBabyGrandPianosQty: 0, heavyItemsBetweenFourFiftyAndSixQty: 0, heavyItemsBetweenThreeAndFourFiftyQty: 0, heavyItemsSixPlusQty: 0, indicatedHasHeavyItems: false } },
        moveDescription: moveDescription ?? '',
        typeOfHelp: typeOfHelp ?? JobDetailsTypeOfHelpEnum.Unknown
    };

    return (<Formik
        initialValues={initialValues}
        validationSchema={moveDetailsValidationSchema}
        validateOnBlur
        validateOnChange={false}
        enableReinitialize
        onSubmit={handleSubmit}>
        {({ values }) =>
            <Form noValidate>
                <ErrorFocus />
                <Container className="pb-3 body-container gx-1">
                    <Row className='heading-row pb-3'>
                        <Col>
                            <h1 className='main-title mb-4 pb-2'>Schedule the <span className='text-primary fst-italic'>best time</span> for your move</h1>
                            <h2 className='main-subtitle mb-md-3'>You can always modify your dates after booking!</h2>
                        </Col>
                        <Col xs={'auto'}>
                            <picture>
                                <source srcSet={`/img/${brandInfo.abbrev}/reservation.png 1x, /img/${brandInfo.abbrev}/reservation2x.png 2x`} media="(min-width: 768px)" width="202" height="164"/>
                                <img src={`/img/${brandInfo.abbrev}/reservation-mobile2x.png`} alt='Clock' width="54" height="62" />
                            </picture>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mx-auto">
                            <LocationCard typeOfHelpProp={JobDetailsTypeOfHelpEnum.Loading} />

                            <LocationCard typeOfHelpProp={JobDetailsTypeOfHelpEnum.Unloading} />
                        </Col>
                    </Row>
                    <Row className='pt-3'>
                        <Col>
                            <HeavyItems />
                            <div>
                                <h3 className="fw-bold h6">Tell us a little more about your move</h3>
                                <Form.Group className="mb-3">
                                    <HahFormikField name="moveDescription" label="Enter a brief description" as={'textarea' as any} rows={5} />
                                </Form.Group>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <CheckoutFooterActionBarFormik testData={testData} />
            </Form>
        }
    </Formik>
    );
}
