
import { useFormikContext } from 'formik';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CheckoutContext, CheckoutNavigationContext } from '../checkout/CheckoutContext';
import { PickSetState } from '../hooks';
import { BookOrderValidationModel } from '../modules/validationSchema';
import { BrandTypeDescription, BookingSubdomainStep } from '../typewriter/enums';
import { ActionButton } from './ActionButton';
import { BookOrderButton } from './payment/PaymentInfo';

interface Props {
    continueAction?: () => void,
    continueButton: React.ReactFragment,
    onGoBack?: () => void;
    onClickTestData?: () => void;
}
export const FooterActionBar = ({ continueButton, onGoBack, continueAction, onClickTestData }: Props) => {

    const { model: { brandType } } = React.useContext(CheckoutContext);
    const { currentStep } = React.useContext(CheckoutNavigationContext);

    if (!continueButton) {
        continueButton = <ActionButton variant="primary text-white rounded d-block w-100" onClickAction={continueAction}>Continue</ActionButton>;
    }

    return <div className="py-3">
        <Row className="justify-content-end">
            {(!isProdBuild && onClickTestData) && <Col md="auto">
                <ActionButton variant="info rounded mb-3 mb-md-0" onClickAction={onClickTestData}>Fill Test Data</ActionButton>
            </Col>}
            {onGoBack && currentStep.step != BookingSubdomainStep.ServiceAndContact && <Col xs={4} md={2} className="text-end">
                <ActionButton variant='' className="text-white fw-bold rounded d-block w-100" style={{ background: '#9F9E9A' }} onClickAction={onGoBack}>Go Back</ActionButton>
            </Col>}
            <Col xs md={currentStep.step == BookingSubdomainStep.ReservationDetails ? 12 : 6}>
                {continueButton}
            </Col>
        </Row>
        {currentStep.step == BookingSubdomainStep.ServiceAndContact &&
            <Row className="justify-content-end">
                <Col xs md={6}>
                    <div className="my-3" style={{fontSize: '12px'}}>
                        By clicking Continue, you authorize {BrandTypeDescription.get(brandType)}, Porch Moving Group and/or its affiliates to deliver marketing emails, calls or texts to the email and/or number you provide above regarding your upcoming move.
                    </div>
                </Col>
            </Row>
        }
    </div>;
}

export const CheckoutFooterActionBarFormik = (props: Omit<CheckoutFooterActionBarProps, 'continueAction'>) => {

    const { handleSubmit } = useFormikContext();

    return <CheckoutFooterActionBar {...props} continueAction={handleSubmit} />

}

interface CheckoutFooterActionBarProps {
    continueAction?: () => void,
    continueButton?: React.ReactFragment,
    testData?: PickSetState<models.PublicBookingSubdomainQuoteModel>;
}

export const CheckoutFooterActionBar = ({ continueButton, testData, continueAction }: CheckoutFooterActionBarProps) => {

    const { moveBack } = React.useContext(CheckoutNavigationContext);
    const { onChange } = React.useContext(CheckoutContext);

    if (!continueButton) {
        continueButton = <ActionButton variant="primary text-white rounded d-block w-100" onClickAction={continueAction}>Continue</ActionButton>;
    }

    return <FooterActionBar onGoBack={moveBack} continueAction={continueAction} continueButton={continueButton} onClickTestData={testData ? () => onChange(testData) : undefined} />
}

export const BookingFooterActionBar = ({ isFormSubmitting }: { isFormSubmitting: boolean }) => {

    const { handleSubmit } = useFormikContext<BookOrderValidationModel>();

    const continueButton = <BookOrderButton isFormSubmitting={isFormSubmitting} onClick={handleSubmit} />;
    return <div className="py-3">
        <Row className="justify-content-end">
            <Col>
                {continueButton}
            </Col>
        </Row>
    </div>
}

export const UpdatePaymentFooterActionBar = ({ continueButton }: { continueButton: React.ReactFragment }) => {

    return <div className="py-3">
        <Row className="justify-content-end">
            <Col>
                {continueButton}
            </Col>
        </Row>
    </div>;
}