// @ts-ignore
import * as enums from '~/typewriter/enums';


import { executeGet, executePost } from '../http-utils';




    export const endpointUrl = '/api/bookingSubdomain/';


    export function getTransportOptions() { return executeGet<readonly models.TransportOptionBasic[]>(endpointUrl + 'getTransportOptions/'); }
    
    export function getBrandPhone() { return executeGet<string>(endpointUrl + 'getBrandPhone/'); }
    
    export function quote(authToken: string, tokenType: enums.TransientAuthType) { return executeGet<models.PublicBookingSubdomainQuoteModel>(endpointUrl + 'quote/?authToken=' + (authToken ? encodeURIComponent(authToken) : '') + '&tokenType=' + tokenType); }
    
    export function reviewQuote(authToken: string, tokenType: enums.TransientAuthType) { return executeGet<models.ReviewBookingSubdomainQuoteModel>(endpointUrl + 'reviewQuote/?authToken=' + (authToken ? encodeURIComponent(authToken) : '') + '&tokenType=' + tokenType); }
    
    export function getTos() { return executeGet<models.BookingSubdomainTos>(endpointUrl + 'getTos/'); }
    

    
    export function saveQuote(model: models.PublicBookingSubdomainQuoteModel, step?: enums.BookingSubdomainStep, authToken?: string) { return executePost<models.SaveQuoteResult>(endpointUrl + 'saveQuote/?step=' + step + '&authToken=' + (authToken ? encodeURIComponent(authToken) : ''), model); }
    
    export function bookOrder(model: models.BookingSubdomainBookOrderModel) { return executePost<models.BookingSubdomainBookOrderResult>(endpointUrl + 'bookOrder/', model); }
    
    export function updateContact(contactInfo: models.ContactInfo, authToken: string, authType: enums.TransientAuthType) { return executePost<models.ContactInfo>(endpointUrl + 'updateContact/?authToken=' + (authToken ? encodeURIComponent(authToken) : '') + '&authType=' + authType, contactInfo); }
    


