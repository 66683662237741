import * as Sentry from '@sentry/react';
import React, { useContext } from 'react';
import { Button, Col, Container, Modal, Offcanvas, Row, Spinner } from 'react-bootstrap';
import { Route, useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ActionLink, BrandedNav, StepsSidebar } from '../components';
import { PickSetState, usePageTitle, useShowHide, useSteps } from '../hooks';
import { useCheckoutContextProvider } from '../hooks/useCheckoutContextProvider';
import { saveQuote } from '../typewriter/controllers/BookingSubdomainController';
import { TransientAuthType } from '../typewriter/enums/TransientAuthType';
import { CheckoutContext, CheckoutNavigationContext } from './CheckoutContext';
import { UnavailablePage } from './pages/UnavailablePage';
import CheckoutUrls from '../checkout/checkout-urls';
import './CheckoutLayout.scss';
import { toast } from 'react-toastify';
import { FormikHelpers } from 'formik';
import { setApiFieldErrors } from '../modules';
import { catchAndLogToSentry } from '../modules/catchAndLogToSentry';
import { mcProblemJsonFetchError } from '../typewriter/http-utils';
import { QuoteExpiredPage } from './pages/QuoteExpired';
import { ProviderListResultStatus } from '../typewriter/enums';
import { BrandContext } from '../modules/BrandContext';


export const CheckoutLayout = () => {
    const brandInfo = useContext(BrandContext);

    const { model, authToken, onChange, loading, setLoading, modelRef, transportOptions } = useCheckoutContextProvider({ brand: brandInfo.brand, tokenType: TransientAuthType.BookingSubdomain });

    const { steps, currentStepIndex, moveBack, moveNext, currentStep, goToStep } = useSteps();

    const [errorResults, setErrorResults] = React.useState<readonly models.SaveQuoteProviderListResult[]>([]);

    const [showUnavailable, setShowUnavailable, hideUnavailable] = useShowHide(false);

    usePageTitle(currentStep?.title, brandInfo.companyName);

    const shouldSave = !!authToken || currentStepIndex >= 0;

    const handleMoveBack = React.useCallback(() => {
        moveBack(authToken);
    }, [authToken, moveBack]);

    const { push } = useHistory();
    const handleMoveNext = React.useCallback(async (change?: PickSetState<models.PublicBookingSubdomainQuoteModel, models.PublicBookingSubdomainQuoteModel>, formikHelpers?: FormikHelpers<any>) => {


        const latest = change ? { ...modelRef.current, ...(typeof change === 'function' ? change(modelRef.current) : change) } : modelRef.current;
        onChange(latest);

        if (shouldSave) {

            try {
                setLoading(true);
                const result = await saveQuote(latest, currentStep?.step, authToken);
                if (result.success) {
                    moveNext(result.authToken);
                }
                else {
                    Sentry?.captureEvent({
                        message: 'saveQuote returned non-success: ' + result.message,
                        level: 'warning',
                        tags: {
                            pmgAuthToken: authToken,
                        },
                        extra: {
                            result,
                            model: latest as any,
                            authToken
                        }
                    } as Sentry.Event);
                    if (result.jobResults.some(x => x.status == ProviderListResultStatus.NoProvidersFound)) {
                        push(CheckoutUrls.UnavailableUrl);
                    }
                    else if (result.jobResults.some(x => x.status == ProviderListResultStatus.FullyBlocked || x.status == ProviderListResultStatus.BlockedDuringArrivalWindow)) {
                        setErrorResults([]);
                        setShowUnavailable();
                    }
                    else if (result.jobResults.some(x => x.status == ProviderListResultStatus.Error)) {
                        setErrorResults(result.jobResults.filter(x => x.status == ProviderListResultStatus.Error));
                        setShowUnavailable();
                    }
                    else {
                        // FUTURE SG: We have a lot of different places where we are returning the same page for different errors, I created a 'QuoteExpired' page but unless we return specific reasons from the result we won't be able to use it
                        // New page: "Can't Find a Match" - This page would be displayed to a user if we cannot find them a suitable provider with our automated means - and we will most likely tell them to call in, etc
                        push(CheckoutUrls.UnavailableUrl);
                    }


                }
            } catch (error: any) {
                let apiError = false;
                if (error.name == mcProblemJsonFetchError.Name) {
                    const castedErr = error as mcProblemJsonFetchError;
                    apiError = true;
                    if (formikHelpers) {
                        setApiFieldErrors(castedErr.webApiProblem.errors, formikHelpers);
                    }
                    const toastMessage = 'There was one or more validation errors with your form - please take a look and try submitting again.';
                    toast.error(toastMessage);
                }
                else {
                    toast.error('An unexpected error has occurred. We have notified our team and do apologize for any inconvenience. Please call us at ' + brandInfo.brandPhone + ' and we would be happy to assist you.');
                }

                Sentry?.withScope((scope) => {
                    scope.setLevel(apiError ? 'warning' : 'error');
                    catchAndLogToSentry(error,
                        'saveQuote returned non-success: ' + error.message,
                        authToken,
                        {
                            error,
                            model: JSON.stringify(model)
                        }
                    );
                });
            }
            finally {
                setLoading(false);
            }
        }
        else {
            moveNext();
        }


    }, [modelRef, onChange, shouldSave, setLoading, currentStep?.step, authToken, moveNext, push, setShowUnavailable, brandInfo.brandPhone, model]);

    const handleMoveNextWithChange = React.useCallback((change: PickSetState<models.PublicBookingSubdomainQuoteModel, models.PublicBookingSubdomainQuoteModel>, formikHelpers?: FormikHelpers<any>) => {

        handleMoveNext(change, formikHelpers);

    }, [handleMoveNext]);

    const [showOffCanvas, setShowOffCanvas] = React.useState(false);

    const handleCloseOffCanvas = () => setShowOffCanvas(false);
    const handleShowOffCanvas = () => setShowOffCanvas(true);

    return <>
        <BrandedNav brandInfo={brandInfo} affiliateInfo={model.affiliateInfo} toggleOffCanvas={handleShowOffCanvas} shouldShowOffCanvas={currentStepIndex < 3 && currentStep != undefined} />
        {showUnavailable && <UnavailableModal onHide={hideUnavailable} brandInfo={brandInfo} errorResults={errorResults} />}
        <CheckoutContext.Provider value={{ model, authToken, brandInfo, transportOptions, loading, setLoading, onChange }}>

            <CheckoutNavigationContext.Provider value={{ moveNext: handleMoveNext, moveNextWithChange: handleMoveNextWithChange, moveBack: handleMoveBack, currentStepIndex, stepsLength: steps.length, goToStep, steps, currentStep }}>
                {loading && <div className="text-center position-fixed w-100 h-100 top-0 d-flex align-items-center checkoutLayoutContainer">
                    <div className="p-4 border rounded border-primary border-3 bg-white mx-auto  checkoutLayoutInnerContainer">
                        <Spinner className="text-primary" animation='border' />
                        <div>Please wait... Loading</div>
                    </div>
                </div>}
                <Container fluid='xxl'>
                    <Row>
                        {currentStepIndex < 3 && currentStep && <Col xs={'auto'} xl={3} className='mx-auto mx-lg-0 d-none d-lg-block'>
                            <Offcanvas className={brandInfo.abbrev} show={showOffCanvas} onHide={handleCloseOffCanvas} responsive="lg">

                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title className='flex-grow-1'>
                                        <img
                                            src={`${brandInfo.logoUrl}`}
                                            width="240"
                                            height="40"
                                            className="d-block align-top mx-auto brand-logo"
                                            alt={brandInfo.companyName}
                                        />
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <StepsSidebar />
                                </Offcanvas.Body>
                            </Offcanvas>
                        </Col>}
                        <Col className='ms-auto'>
                            {steps.map(step => <Route key={step.url} exact path={step.url} component={step.component} />)}
                        </Col>
                    </Row>
                </Container>
                <Route key={CheckoutUrls.UnavailableUrl} exact path={CheckoutUrls.UnavailableUrl} component={UnavailablePage} />
                <Route key={CheckoutUrls.QuoteExpiredUrl} exact path={CheckoutUrls.QuoteExpiredUrl} component={QuoteExpiredPage} />
            </CheckoutNavigationContext.Provider>
        </CheckoutContext.Provider>
    </>;
}


const UnavailableModal = ({ onHide, brandInfo, errorResults }: { onHide: () => void; brandInfo: BrandInfo; errorResults: readonly models.SaveQuoteProviderListResult[] }) => {

    const title = React.useMemo(() => errorResults.length > 0 ? 'Error finding Movers' : 'No Movers for this date', [errorResults]);

    return <Modal
        show={true}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        centered
        className={brandInfo.abbrev}
    >
        <Modal.Header closeButton className='border-0'>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {errorResults.length > 0 && <div>
                There was an error finding Movers. Please fix the following issues and try again. If you need additional assistance, please call {brandInfo.cleanPhone}.
                <ul>
                    {errorResults.map((x, i) => <li key={i}>{x.message}</li>)}
                </ul>
            </div>}
            {errorResults.length === 0 && <div>
                There are no Movers available for this date or time. Trying searching a new date or time. You can always call in {brandInfo.cleanPhone} to speak with a moving specialist.
            </div>}
            <div>
                <a href={`tel:${brandInfo.cleanPhone}`} className="btn btn-lg btn-primary text-white rounded-pill mt-4 w-100">
                    Call {brandInfo.cleanPhone}
                </a>
            </div>
            <div className='text-center mt-3 text-primary'>
                <ActionLink onClick={onHide}>Change Search</ActionLink>
            </div>
        </Modal.Body>
    </Modal>;
}