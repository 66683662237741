import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { AddressType, AddressTypeDescription } from '../typewriter/enums';
import { iconLibrary } from './Icon';


const addressTypes = [
  { type: AddressType.House, icon: iconLibrary.faHome, },
  { type: AddressType.Studio, icon: iconLibrary.faHotel, },
  { type: AddressType.Office, icon: iconLibrary.faCity, },
  { type: AddressType.StorageUnit, icon: iconLibrary.faWarehouse, },
];


export const AddressTypeSelection = ({ parentName }: { parentName: string }) => {

  const fullName = parentName + '.type';
  const [{ value }, { error, touched }, { setValue }] = useField(fullName);

  return <Row className="gx-3">
    {addressTypes.map(x => <Col key={x.type} xs={6} lg={3}><AddressTypeCard onClick={() => setValue(x.type, true)} selected={value === x.type} addressType={x.type} icon={x.icon} /></Col>)}
    <Form.Control type="hidden" required name={fullName} value={value || AddressType.Unknown} isInvalid={touched && !!error} />
    <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
  </Row>;
}

const AddressTypeCard = ({ addressType, selected, onClick, icon }: { addressType: AddressType, icon: IconDefinition, selected: boolean, onClick: () => void }) => {


  return <div className={`address-type-card mb-3 border rounded py-3 ${selected ? 'selected border-primary shadow' : ''}`} onClick={onClick}>
    <div className={'align-items-center'}>
      <div className="text-center mb-2">
        <FontAwesomeIcon icon={icon as IconProp} className={`${selected ? 'text-primary' : 'text-light-gray'}`} style={{ fontSize: 36 }}></FontAwesomeIcon>
      </div>
      <div className='text-center'>
        <h2 className='h6 mb-0'>{AddressTypeDescription.get(addressType)}</h2>
      </div>
    </div>
  </div>;
}