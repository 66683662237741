
/**
 * Class to help manage managed labor checkout URLs.
 */
export default class CheckoutUrls {
    /** "Select Service Type" and Contact Info step of checkout */
    static CheckoutBaseUrl = '/checkout/';

    /** "Select Transportation" step of checkout */
    static TransportationUrl = `${CheckoutUrls.CheckoutBaseUrl}transportation/`;

    /** "Enter Move Details" step of checkout */
    static MoveDetailsUrl = `${CheckoutUrls.CheckoutBaseUrl}move-details/`;

    /** "Reservation Details" step of checkout */
    static ReservationDetailsUrl = `${CheckoutUrls.CheckoutBaseUrl}reservation-details/`;

    /** The "Unavailable" URL when they need to call us to get service */
    static UnavailableUrl = `${CheckoutUrls.CheckoutBaseUrl}unavailable/`;

    /** The "Expired Quote" URL when they need to call us to get service or start over */
    static QuoteExpiredUrl = `${CheckoutUrls.CheckoutBaseUrl}quote-expired/`;
}