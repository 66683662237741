import * as Sentry from '@sentry/react';
import React from 'react';
import { getBrandPhone } from '../typewriter/controllers/BookingSubdomainController';
import { getBrandFromHost } from '.';
import { formatPhoneNumber } from '../utils/formatting';
import { BrandType } from '../typewriter/enums';

type BrandContextProps = BrandInfo & { setBrandType: (brandType: BrandType) => void; }

const emptyBrand: BrandInfo = {
    brand: BrandType.Unknown,
    companyName: '',
    abbrev: 'unknown',
    icon: '',
    logoUrl: '',
    brandPhone: '',
    cleanPhone: '',
    gtmId: '',
    addressLine1: '',
    addressLine2: '',
    phoneNumbers: [],
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const BrandContext = React.createContext<BrandContextProps>({...emptyBrand, setBrandType: () => {}});

export const BrandContextProvider = ({children}: {children?: React.ReactNode}) => {
    const [brandInfo, setBrandInfo] = React.useState<BrandInfo>(emptyBrand);


    const setBrandType = React.useCallback((brandType: BrandType) => {
        const brand = getBrandFromHost(window.location.host, brandType);
        setBrandInfo(prev => ({ ...brand, brandPhone: prev?.brandPhone ?? brand.brandPhone, cleanPhone: prev?.cleanPhone ?? brand.cleanPhone}));
    }, []);

    React.useEffect(() => {
        if (brandInfo.brand == BrandType.Unknown) {
            (async () => {
                const brand = getBrandFromHost(window.location.host);
                try {
                    const brandPhone = await getBrandPhone();
                    setBrandInfo({ ...brand, brandPhone, cleanPhone: formatPhoneNumber(brandPhone) });
                } catch (error) {
                    Sentry?.captureException(error);
                    setBrandInfo(brand);
                }
            })();
        }
    }, [brandInfo]);

    return <BrandContext.Provider value={{...brandInfo, setBrandType}}>{children}</BrandContext.Provider>;
}