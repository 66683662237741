import { FlipProp, IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { AddressTypeDescription, BrandTypeDescription, JobStatus, ServiceType, TransportOptionType } from '../typewriter/enums';
import { formatCurrency, formatNumber } from '../utils/formatting';
import { AddressOneLine } from './shared/AddressOneLine';
import { iconLibrary } from './Icon';
import { formatDate } from './DateTimeDisplays';
import { AdditionalRatesSummaryRow } from './AdditionalRates';
import { StarDisplay } from './shared/StarDisplay';

interface LocationDetailsProps {
    key: number | undefined;
    label: string;
    icon: IconProp;
    flip?: FlipProp;
    job: models.BookingSubdomainJobInfo;
    transportOptions: readonly models.JobTransportOptionModel[];
}

export const MoveSummary = ({ model }: { model: models.BookingSubdomainOrderDetails }) => {
    // short-circuit if nothing to render yet
    if (model == null) {
        return <div>Loading...</div>;
    }

    const { loadingJobs, unloadingJobs, moveDescription, heavyItemsList, brandType, jobStatus } = model.quoteModel;
    const { transportOptions } = model;

    const jobIsNotCancelled = jobStatus !== JobStatus.Cancelled;

    const orderId = loadingJobs.length > 0 ? loadingJobs[0].jobID : unloadingJobs[0].jobID;

    const typeLabel = makeCommaSeparatedString([loadingJobs.length > 0 ? 'LOADING' : '', unloadingJobs.length > 0 ? 'UNLOADING' : ''].filter(x => x), true);

    const orderEstimate = (jobStatus === JobStatus.Cancelled && model.balanceDueNowSum) ? formatCurrency(model.balanceDueNowSum, { truncateZeroCents: true}) : formatCurrency(model.grandOrderTotal, { truncateZeroCents: true })

    const job1 = loadingJobs.length > 0 ? loadingJobs[0] : unloadingJobs[0];
    const job2 = loadingJobs.length > 0 && unloadingJobs.length > 0 ? unloadingJobs[0] : undefined;
    return <div className='mb-4'>
        <Accordion defaultActiveKey="0" className="drop-shadow">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <Row className='flex-grow-1 align-items-center'>
                        <Col>
                            <h5 className='mb-0'>Quote</h5>
                        </Col>
                        <Col xs={'auto'}>
                            <h5 className='mb-0'>{orderEstimate}</h5>
                        </Col>
                    </Row>
                </Accordion.Header>
                <Accordion.Body className='small px-0 px-md-3'>
                    <QuoteSummary orderId={orderId} orderEstimate={orderEstimate} />
                    <ProviderSummary job={job1} />
                    {jobIsNotCancelled && loadingJobs.map((job) => <LocationDetails key={job.jobID} label="Loading" icon={iconLibrary.faPersonCarry as IconProp} job={job} transportOptions={transportOptions} />)}
                    
                    {job2 && <ProviderSummary job={job2} />}
                    {jobIsNotCancelled && unloadingJobs.map((job) => <LocationDetails key={job.jobID} label="Unloading" icon={iconLibrary.faPersonCarry as IconProp} flip={'horizontal' as FlipProp} job={job} transportOptions={transportOptions} />)}

                    {jobIsNotCancelled && heavyItemsList && heavyItemsList.length > 0 && <HeavyItemSummary model={model} /> }

                    {jobIsNotCancelled && transportOptions && <TransportSummary model={model}/>}

                    {model.quoteModel.moveDescription && <div className='mt-3'>
                            <Row className='gx-2'>
                                <Col xs='auto' className='d-none d-md-block'>
                                    <FontAwesomeIcon className="text-primary" fixedWidth icon={iconLibrary.faComment as IconProp}/>
                                </Col>
                                <Col>
                                    <Container fluid>
                                        <Row className='pb-2'>
                                            <Col md={3}>
                                                <strong>Additional Comments</strong>
                                            </Col>
                                            <Col>
                                                {model.quoteModel.moveDescription}
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        </div>
                    }
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
};

export const MoveSummaryRow = ({label, description, amount}:{label?: string, description?: React.ReactElement, amount?: React.ReactElement}) => {
    return <Row className='pb-2'>
            <Col md={3}>
                <strong>{label}</strong>
            </Col>
            <Col>
                {description}
            </Col>
            <Col xs='auto' className="text-end">
                {amount}
            </Col>
    </Row>
}

const ProviderSummary = ({ job }:{ job: models.BookingSubdomainJobInfo}) => {
    return <div className='mb-2'>
        <Row className='pb-2 gx-2'>
            <Col xs='auto' className='d-none d-md-block'>
                <FontAwesomeIcon className="text-primary" fixedWidth icon={iconLibrary.faUserCircle as IconProp}/>
            </Col>
            <Col>
                <Container fluid>
                    <Row className='pb-2'>
                        <Col md={3}>
                            <strong>Service Provider</strong>
                        </Col>
                        {job.helperImageUrl && <Col xs="auto">
                            <img src={job.helperImageUrl} alt={job.helperCompanyName} width="40" />
                        </Col>}
                        <Col>
                            <h6 className='mb-1'>{job.helperCompanyName}</h6>
                            {(job.helperRating ?? 0) > 0 && <Row>
                                <Col xs="auto" className='small pe-0 text-success'>
                                    <StarDisplay rating={job.helperRating!} id={0} />
                                </Col>
                                <Col xs="auto" className='pe-0 ps-2 fw-semibold'>
                                    {formatNumber(job.helperRating!, { decimalPlaces: 1 })}
                                </Col>
                                <Col xs={12} md="auto">
                                    {job.helperReviewCount} verified reviews
                                </Col>
                            </Row>}
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    </div>
}

const QuoteSummary = ({orderId, orderEstimate }:{orderId: number | undefined, orderEstimate: string}) => {
    return <div className='mb-2'>
        <Row className='pb-2 gx-2'>
            <Col xs='auto' className='d-none d-md-block'>
                <FontAwesomeIcon className="text-primary" fixedWidth icon={iconLibrary.faListAlt as IconProp}/>
            </Col>
            <Col>
                <Container fluid>
                    <MoveSummaryRow label={'Quote'} description={<>#{orderId}</>} amount={<strong>{orderEstimate}</strong>} />
                </Container>
            </Col>
        </Row>
    </div>
}

const HeavyItemSummary = ({model}:{model: models.BookingSubdomainOrderDetails}) => {
    const { heavyItemsList } = model.quoteModel;
    return <>
        <div className='mb-2'>
            <Row className='pb-2 gx-2'>
                <Col xs='auto' className='d-none d-md-block'>
                    <FontAwesomeIcon className="text-primary" fixedWidth icon={iconLibrary.faBoxAlt as IconProp}/>
                </Col>
                <Col>
                    <Container fluid>
                        {heavyItemsList?.map((x, i) => <MoveSummaryRow key={x} label={i == 0 ? 'Extra Heavy Items' : ''} description={<>{x}</>} />)}
                    </Container>
                </Col>
            </Row>
        </div>
    </>
}

const TransportSummary = ({model}:{model: models.BookingSubdomainOrderDetails}) => {
    const { brandType } = model.quoteModel;
    const { transportOptions } = model;
    return <div>
            <Row className='gx-2'>
                <Col xs='auto' className='d-none d-md-block'>
                    <FontAwesomeIcon className="text-primary" fixedWidth icon={iconLibrary.faTruck as IconProp}/>
                </Col>
                <Col>
                    <Container fluid>
                        <Row className='pb-2'>
                            <Col md={3}>
                                <strong>Transportation*</strong>
                            </Col>
                            <Col>
                                <TransportOptions transportOptions={transportOptions} />
                            </Col>
                            {transportOptions?.length > 0 &&
                                <Col xs='auto'>
                                    <span className="text-end text-light-gray ps-4">
                                        *Not provided by <strong>{BrandTypeDescription.get(brandType)}</strong>
                                    </span>
                                </Col>}
                        </Row>
                    </Container>
                </Col>
            </Row>
        </div>;
}

const LocationDetails = ({ label, icon, flip, job, transportOptions }: LocationDetailsProps) => {

    if (!job.totals) {
        return null;
    }

    return <>
        <LocationDetailsContainer key={job.jobID} label={label} icon={icon} {...(flip && { flip: flip })} job={job} transportOptions={transportOptions} />
    </>;
}

const LocationDetailsContainer = ({ label, icon, flip, job, transportOptions }: LocationDetailsProps) => {
    const { hoursAndHelpers, jobDate, jobAddresses, desiredArrivalWindow, totals, serviceType } = job;
    const dateStr = formatDate(jobDate!);
    const arrivalWindow = desiredArrivalWindow?.replace('Arrival Between', '');

    const MovingDetailsDescription = React.useMemo(() => {
        return serviceType === ServiceType.ManagedLaborPlus 
            ? <TransportOptions transportOptions={transportOptions} />
            : <>({hoursAndHelpers?.numHelpers}) Crew Members for ({hoursAndHelpers?.laborHours}) hours</>;
    }, [hoursAndHelpers?.laborHours, hoursAndHelpers?.numHelpers, serviceType, transportOptions]);

    return <>
        <div className='mb-2'>
            <Row className='pb-2 gx-2'>
                <Col xs='auto' className='d-none d-md-block'>
                    <FontAwesomeIcon className="text-primary" fixedWidth icon={icon} {...(flip && { flip: flip })}/>
                </Col>
                <Col>
                    <Container fluid>
                        <MoveSummaryRow
                            label={label + ' Moving Details'}
                            description={MovingDetailsDescription}
                            amount={<>{formatCurrency(totals?.orderTotal, { truncateZeroCents: true })}</>} />
                        <MoveSummaryRow label={'Move Size'} description={<>{jobAddresses && <>{AddressTypeDescription.get(jobAddresses![0].type)} {jobAddresses![0].sqFootage} sq. ft.</>}</>}/>
                        <MoveSummaryRow label={'Arrival Window'} description={<><strong className="text-primary">{dateStr}</strong> between <strong className="text-primary">{arrivalWindow}</strong></>} />
                        <MoveSummaryRow label={label + ' Address'} description={<>{jobAddresses?.length == 1 ? <AddressOneLine {...jobAddresses![0]!} /> : jobAddresses?.map((x, i) => <AddressOneLine key={i} {...x} stopNumber={i + 1} />)}</>} />
                        {job.totals && <AdditionalRatesSummaryRow typeOfHelp={label} serviceType={job.serviceType} totals={job.totals} />}
                    </Container>
                </Col>
            </Row>
        </div>
    </>
}

const TransportOptions = ({transportOptions}: {transportOptions: readonly models.JobTransportOptionModel[]}) => {
    return (
        transportOptions?.length > 0
            ? <div className="d-flex flex-column">
                {transportOptions.map(t => t.type == TransportOptionType.None
                    ? <span key={`no-transport-${t.transportOptionID}`}>No Transportation</span>
                    : <div className='d-inline' key={t.transportOptionID}>{t.quantity} x {t.displayName}</div>)}
            </div>
            : <>No transportation details provided</>
    );
}

// FUTURE: move to shared place
function makeCommaSeparatedString(arr: any[], useOxfordComma: boolean) {
    const listStart = arr.slice(0, -1).join(', ')
    const listEnd = arr.slice(-1)
    const conjunction = arr.length <= 1
      ? ''
      : useOxfordComma && arr.length > 2
        ? ', and '
        : ' and '

    return [listStart, listEnd].join(conjunction)
  }