// @ts-ignore
import * as enums from '~/typewriter/enums';


import { executeGet, executePost } from '../http-utils';




    export const endpointUrl = '/api/order/';


    export function get(authToken: string) { return executeGet<models.BookingSubdomainOrderCollectionModel>(endpointUrl + 'get/?authToken=' + (authToken ? encodeURIComponent(authToken) : '')); }
    
    export function getUpdatePaymentInfo(authToken: string) { return executeGet<models.UpdatePaymentInfoModel>(endpointUrl + 'getUpdatePaymentInfo/?authToken=' + (authToken ? encodeURIComponent(authToken) : '')); }
    

    
    export function postUpdatePaymentInfo(model: models.SubmitUpdatePaymentInfoModel) { return executePost<models.PostUpdatePaymentInfoResult>(endpointUrl + 'postUpdatePaymentInfo/', model); }
    


