
export const usePageTitle = (pageTitle: string, brandName: string) => {

    
    if (typeof document !== 'undefined' && typeof pageTitle !== 'undefined' && pageTitle !== document.title) {

        document.title = pageTitle + (brandName ? ' - ' + brandName : '');
    }

};
