import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { CheckoutContext, CheckoutNavigationContext } from '../../checkout/CheckoutContext';
import { contactValidationSchema } from '../../modules/validationSchema';
import { updateContact } from '../../typewriter/controllers/BookingSubdomainController';
import { BookingSubdomainStep, TransientAuthType } from '../../typewriter/enums';
import { formatPhoneNumber } from '../../utils/formatting';
import { HahFormikFieldSmall } from '../HahFormikField';
import { iconLibrary } from '../Icon';
import { catchAndLogToSentry } from '../../modules/catchAndLogToSentry';


interface Props {
    authType: TransientAuthType;
}
export const ContactInformation = ({authType}: Props) => {
    const { authToken, model: { contactInfo }, onChange } = React.useContext(CheckoutContext);

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const [editName, setEditName] = useState(false);
    const [editEmail, setEditEmail] = useState(false);
    const [editPhone, setEditPhone] = useState(false);

    const handleEdit = (field: string, formik: FormikProps<models.ContactInfo>) => {
        switch (field) {
            case 'Name':
                setEditName(true);
                break;
            case 'Email':
                setEditEmail(true);
                break;
            case 'Phone':
                setEditPhone(true);
                break;
        }
    };

    const handleCancel = (formik: FormikProps<models.ContactInfo>) => {
        formik.resetForm();

        setEditName(false);
        setEditEmail(false);
        setEditPhone(false);
    };

    const useHandleSubmit = async (values: models.ContactInfo) => {
        setIsSubmitting(true);
        try {
            if (!authToken) {throw new Error('Invalid Quote');}


            const result = await updateContact(values, authToken, authType)
            if (result) {
                onChange({ contactInfo: values });
            }
        } catch (error: any) {
            catchAndLogToSentry(error,
                'Contact Information: Tried updating from PaymentForm',
                authToken,
                {
                    error,
                    contactInfo: values
                }
            );

            return;
        }
        finally {
            setEditName(false);
            setEditEmail(false);
            setEditPhone(false);
            setIsSubmitting(false);
        }


    };

  return <>
        <h5 className='mb-3 mt-lg-5'>Contact Information:</h5>
        <Formik
            initialValues={contactInfo}
            validationSchema={contactValidationSchema}
            enableReinitialize
            onSubmit={useHandleSubmit}>
            {formik => (
                <Form className='position-relative'>
                    {isSubmitting && <div className="text-center position-absolute w-100 h-100 top-0 d-flex align-items-center checkoutLayoutContainer">
                        <div className="p-3 border rounded border-primary border-3 bg-white mx-auto">
                            <Spinner className="text-primary" animation='border' />
                        </div>
                    </div>}
                    <Row className="g-0 mb-2">
                        <Col>
                            {editName ? (<Row className="g-1">
                                <Col>
                                    <HahFormikFieldSmall name="firstName" label="First Name" />
                                </Col>
                                <Col>
                                    <HahFormikFieldSmall name="lastName" label="Last Name" />
                                </Col>
                                </Row>
                            ) : (
                                <><FontAwesomeIcon className="text-primary me-2" icon={iconLibrary.faUser as IconProp} /> {formik.values.firstName + ' ' + formik.values.lastName} </>
                            )}

                            </Col>
                            <Col className="text-end" xs={'auto'}>
                            {editName ? (<>
                                    <button type="submit" disabled={isSubmitting} className='btn btn-sm btn-primary text-white ms-1 me-1'>Save</button>
                                    <button type="button" className='btn btn-sm btn-dark-gray text-white' onClick={() => handleCancel(formik)}>Cancel</button>
                                </>
                            ) : (
                                <a className="link-primary" style={{cursor: 'pointer'}} onClick={() => handleEdit('Name', formik)}>Edit</a>
                            )}
                        </Col>
                    </Row>
                    <Row className="g-0 mb-2">
                        <Col>
                            {editEmail ? (<>
                                <HahFormikFieldSmall name="email" label="Email" />
                                </>
                            ) : (
                                <><FontAwesomeIcon className="text-primary me-2" icon={iconLibrary.faEnvelope as IconProp} /> {formik.values.email} </>
                            )}
                            </Col>
                            <Col className="text-end" xs={'auto'}>
                            {editEmail ? (<>
                                    <button type="submit" disabled={isSubmitting} className='btn btn-sm btn-primary text-white ms-1 me-1'>Save</button>
                                    <button type="button" className='btn btn-sm btn-dark-gray text-white' onClick={() => handleCancel(formik)}>Cancel</button>
                                </>
                            ) : (
                                <a className="link-primary" style={{cursor: 'pointer'}} onClick={() => handleEdit('Email', formik)}>Edit</a>
                            )}
                        </Col>
                    </Row>
                    <Row className="g-0 mb-2">
                        <Col>
                            {editPhone ? (<>
                                <HahFormikFieldSmall name="phone" label="Phone" />
                                </>
                            ) : (
                                <><FontAwesomeIcon className="text-primary me-2" icon={iconLibrary.faPhone as IconProp} /> {formatPhoneNumber(formik.values.phone)} </>
                            )}
                            </Col>
                            <Col className="text-end" xs={'auto'}>
                            {editPhone ? (<>
                                    <button type="submit"disabled={isSubmitting} className='btn btn-sm btn-primary text-white ms-1 me-1'>Save</button>
                                    <button type="button" className='btn btn-sm btn-dark-gray text-white' onClick={() => handleCancel(formik)}>Cancel</button>
                                </>
                            ) : (
                                <a className="link-primary" style={{cursor: 'pointer'}} onClick={() => handleEdit('Phone', formik)}>Edit</a>
                            )}
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    </>
}