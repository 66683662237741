//import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useContext } from 'react';
import { Layout } from './Layout';
import './scss/main.scss';
import './utils/js-prototypes';
import './App.scss';
import { Container, Spinner } from 'react-bootstrap';
import { BrandContext, BrandContextProvider } from './modules/BrandContext';
import { BrandType } from './typewriter/enums';
import * as Sentry from '@sentry/react';
import { useOnMountUnsafe } from './utils/useOnMountUnsafe';

interface RosaaResponse {
    val: boolean;
}

async function fetchRosaaValue(): Promise<boolean> {
    try {
        const response = await fetch('/api/utils/rosaa/');
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: RosaaResponse = await response.json();
        return data.val;
    } catch (error) {
        console.error('Error fetching Rosaa value:', error);
        throw error;
    }
}


const App = () => {
    return (
        <BrandContextProvider>
            <AppInternal />
        </BrandContextProvider>
    );
};

const AppInternal = () => {
    // FUTURE: We need to get this from the token if one exists, otherwise get it from the host?
    const brandInfo = useContext(BrandContext);

    const { icon } = brandInfo ?? {};

    useOnMountUnsafe(() => {
        (async () => { // Start an async IIFE because react is dumb and doesn't have async methods for useEffect natively
            let shouldLoadOptimizely = isProdBuild;
            //let shouldLoadOptimizely = true;

            if (shouldLoadOptimizely) {
                // check kill switch
                try {
                    const shouldRenderOptimizelyScriptsAtAll = await fetchRosaaValue();
                    if (!shouldRenderOptimizelyScriptsAtAll) {
                        console.log('server rosaa said not to render optimizely, so setting to false');
                        shouldLoadOptimizely = false;
                    }
                } catch (error) {
                    // failed for some reason, log and move on with optimizely loaded i guess
                    Sentry?.captureException(error);
                }
            }

            if (shouldLoadOptimizely) {
                if (window.optimizelyLoaded) {
                    console.warn('optimizely was already loaded; not loading again');
                } else {
                    console.debug('loading optimizely');
                    window.optimizelyLoaded = true;
                    // load optimizely
                    // let the record show this is super hacky and lame. wish we could put it in the index.html, however
                    // then we don't have control over prod vs. non-prod
                    const e1 = document.createElement('link');
                    e1.rel = 'preload';
                    e1.href = '//cdn.optimizely.com/js/25775630111.js';
                    e1.as = 'script';
                    const e2 = document.createElement('link');
                    e2.rel = 'preconnect';
                    e2.href = '//logx.optimizely.com';
                    const e3 = document.createElement('script');
                    e3.src = 'https://cdn.optimizely.com/js/25775630111.js';

                    const head = document.getElementsByTagName('head')[0];
                    head.insertBefore(e3, head.firstChild);
                    head.insertBefore(e2, head.firstChild);
                    head.insertBefore(e1, head.firstChild);
                }
            } else {
                console.debug('not loading optimizely because we\'re not on production');
            }
        })(); // Execute the IIFE immediately

        // set favicon element
        let link = document.querySelector(
            'link[rel~=\'icon\']'
        ) as HTMLLinkElement;
        if (!link) {
            link = document.createElement('link') as any;
            document.getElementsByTagName('head')[0].appendChild(link);
        }

        link.href = `/img/${icon}`;
        link.rel = 'icon';
        link.type = 'image/x-icon'; // .ico file
    });

    if (!brandInfo || brandInfo.brand == BrandType.Unknown)
        return (
            <Container className='text-center mt-4'>
                <Spinner />
                <span className='ms-2'>Loading...</span>
            </Container>
        );
    return <Layout />;
};

export default App;