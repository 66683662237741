import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/browser';
import { Formik, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Container, Form, OverlayTrigger, Popover, Row, Spinner } from 'react-bootstrap';
import { BookingFooterActionBar, ErrorFocus, iconLibrary, MoveSummary } from '.';
import { CheckoutContext } from '../checkout/CheckoutContext';
import { useBookOrderHandler } from '../hooks/useBookOrderHandler';
import { BookOrderValidationModel, bookOrderValidationSchema, completeOrderValidationSchema } from '../modules';
import { emptyAddress } from '../modules/emptyAddress';
import { TransientAuthType } from '../typewriter/enums/TransientAuthType';
import { BillingAddress } from './payment/BillingAddress';
import { ContactInformation } from './payment/ContactInformation';
import { PaymentInfo } from './payment/PaymentInfo';
import { PaymentInfoDev } from './payment/PaymentInfoDev';
import { TermsOfService } from './TermsOfService';
import { ProviderReviews } from './payment/ProviderReviews';

const initialValues = { terms: { agreedToTerms: false, signatureName: '' }, paymentInfo: { address: emptyAddress, cardholderName: '', cardNumber: '', expirationDate: '', cvv: '' } } as BookOrderValidationModel;

const PaymentInfoActual = isProdBuild ? PaymentInfo : PaymentInfoDev;

function getLoadingAction(authType: TransientAuthType): string {
    switch (authType) {
        case TransientAuthType.BookingSubdomain:
        case TransientAuthType.CcAuth:
            return 'Processing Payment';
        case TransientAuthType.UpdatePaymentInfo:
            return 'Updating Your Order';
        default:
            return '';
    }
}

export const PaymentForm = ({ onGoBack, authType }: { onGoBack?: () => void; authType: TransientAuthType; }) => {

    const { authToken, setLoading, brandInfo } = React.useContext(CheckoutContext);
    const { reviewModel, handleSubmit, tokenizeRef, isFormSubmitting, setTestNonce } = useBookOrderHandler(authToken!, authType, setLoading, brandInfo);

    const handleError = React.useCallback((error: Error) => {
        const ex = error as any;
        if (ex && ex.code) {
            switch (ex.code) {
                case 'HOSTED_FIELDS_FIELDS_EMPTY':
                case 'HOSTED_FIELDS_FIELDS_INVALID':
                    return;
            }
        }
        Sentry.captureException(error, { tags: { pmgAuthToken: authToken, } });
    }, [authToken]);

    if (!reviewModel) {
        return null;
    }

    const isManagedPayment = authType == TransientAuthType.CcAuth || authType == TransientAuthType.UpdatePaymentInfo;

    const validationSchema = isManagedPayment ? completeOrderValidationSchema : bookOrderValidationSchema;

    const generatedOptionPopover = <Popover className='p-2'>
        <Button variant='close' className='float-end' onClick={() => document.body.click()} ></Button>
        <h4>Generated Option</h4>
        <div>Our system only displays one option which is generated algorithmically based on availability, rating, pricing, and other Mover Service Provider qualifications. This generated option has the best combination of price, rating, and availability from the criteria you provided. Other options may have lower pricing, different ratings, and other availability options. Call in to hear more.</div>
    </Popover>;

    return (
        <>
            {isFormSubmitting === true &&
                <div className="text-center position-fixed w-100 h-100 top-0 d-flex align-items-center start-0" style={{ zIndex: '9999', backgroundColor: 'rgba(0,0,0, 25%)' }}>
                    <div className="p-4 mx-auto" >
                        <h3>{getLoadingAction(authType)}</h3>
                        <br />
                        <Spinner className="text-primary" animation='border' />
                    </div>
                </div>}

            <Container fluid={'md'} className="mb-5 body-container">
                <Row className='pb-4 pb-lg-0'>
                    <Col lg={8}>
                        {onGoBack && <h5 className="text-primary" style={{ cursor: 'pointer' }}>
                            <a onClick={onGoBack}><FontAwesomeIcon icon={iconLibrary.faArrowCircleLeft as IconProp} /> Go Back</a>
                        </h5>}
                        <h1 className='h2 my-3 d-md-flex align-items-center position-relative'>Generated Option
                            <OverlayTrigger trigger="click" rootClose placement='auto' overlay={generatedOptionPopover}>
                                <span className="small text-muted pe-auto text-decoration-underline" role="button"><FontAwesomeIcon size='xs' icon={iconLibrary.faInfoCircle as IconProp} className='mx-3' /></span>
                            </OverlayTrigger>
                            <span className='d-block fs-5 fw-normal'>Scroll below to pay</span>
                        </h1>
                        <MoveSummary model={reviewModel.quote} />

                        <div className='d-lg-none mb-4'><ContactInformation authType={authType} /></div>

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}>
                            <Form noValidate>
                                <ErrorFocus />

                                {isManagedPayment && <>
                                    <h5 className="mb-3">Please read and accept Terms & Conditions</h5>
                                    <TermsOfService termsHtml={reviewModel.termsHtml} authToken={authToken} />
                                </>}
                                {!isManagedPayment && <a href={`tel:${brandInfo.cleanPhone}`} className="btn btn-lg btn-secondary rounded-pill mb-4 w-100">
                                    Call {brandInfo.cleanPhone} to learn of additional mover options
                                </a>}

                                <div>
                                    <h5 className="mb-3" style={{ display: 'inline-block' }}>Payment Information</h5>
                                    <strong className="text-primary float-end">
                                        Secure Payment <FontAwesomeIcon icon={iconLibrary.faShieldCheck as IconProp} />
                                    </strong>
                                </div>

                                <div className="payment-info-form">
                                    <PaymentInfoActual braintreeClientToken={reviewModel.braintreeClientToken} tokenizeRef={tokenizeRef} onError={handleError} setTestNonce={setTestNonce} />
                                    <BookingFooterActionBar isFormSubmitting={isFormSubmitting} />
                                    <div className="mb-3 p-2 payment-callout rounded text-center">
                                        <p className="mb-0 small">Your card <strong>WILL NOT</strong> be charged at this time. We will charge your card three (3) days prior to your booked move date.</p>
                                    </div>
                                    {!isManagedPayment && <p className='small text-muted'>Your reservation will not be made until this step is complete. By clicking the button above, you agree to our <a target='_blank' href="https://www.porchmovinggroup.com/legal/" rel="noreferrer">Terms of Service</a>. Once you authorize and confirm your payment with Credit Card you will be taken to your confirmation page.</p>}
                                </div>
                            </Form>
                        </Formik>
                    </Col>
                    <Col lg={4} className='d-none d-lg-block'>
                        <ContactInformation authType={authType} />

                        {isManagedPayment && <>
                            <h4 className='text-success fw-bold fs-5 my-4 py-3 d-none d-lg-block'><FontAwesomeIcon icon={iconLibrary.faShieldAlt as IconProp} className='pe-2' />Trusted Moving Partners</h4>
                            <ProviderReviews brandInfo={brandInfo} />
                        </>}
                    </Col>
                </Row>

            </Container>
        </>
    );
};
