import * as React from 'react';
import { Spinner } from 'react-bootstrap';
import { JobReview } from './JobReview';
import { MoveReview} from './MoveReview';

interface AppContainerProps {
    model: models.CustomerManagedReviewModel;
    editMode: boolean;
    onUpdate: (jobs: readonly models.ManagedReviewJobModel[], moveReviews: readonly models.ManagedMoveReviewModel[]) => void;
    loading: boolean;
}
export const AppContainer = (props: AppContainerProps) => {

    const { model, editMode, onUpdate, loading } = props;

    const [jobReviews, setJobReviews] = React.useState<readonly models.ManagedReviewJobModel[]>(model.jobs);

    const [moveReviews, setMoveReviews] = React.useState<readonly models.ManagedMoveReviewModel[]>(model.moveReviews);

    return <>
        <div className='container app-container'>
            <div className='row'>
                <div className='col-12 mt-5'>
                    {!editMode && <h3 className='text-secondary'>Review for Order #{model.orderId}:</h3>}
                </div>
            </div>
        </div>

        <div className='container bg-white mb-5'>
            {jobReviews && jobReviews.map(j => {
                return <div key={j.jobID}>
                    <JobReview editMode={editMode} model={j} onChange={newValue => setJobReviews(prev => prev.map(m => m.jobID == j.jobID ? { ...m, ...newValue } : m))} />
                    <hr />
                </div>;
            })}

            {moveReviews && moveReviews.map(r => {
                return <div key={ r.type }>
                    <MoveReview editMode={editMode} model={r} onChange={newValue => setMoveReviews(prev => prev.map(m => m.type == r.type ? { ...m, ...newValue } : m))} />
                    <hr />
                </div>;
            })}

            {editMode &&
                <div className='row justify-content-center mt-3 mb-5'>
                    <div className='col-md-3 d-grid'>
                        <button onClick={() => onUpdate(jobReviews, moveReviews)} disabled={loading} className='btn btn-success mb-3'>
                            {loading
                                ? <Spinner animation='border' />
                                : 'Save and Send My Review!'
                            }
                        </button>
                    </div>
                </div>
            }
        </div>
    </>;
};
