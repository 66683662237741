




        /**
        * A TypeScript version of a server-side enum. 
        */
        export enum AddressType {
            
                /**
        * [0] 
        */
                Unknown=0,
                /**
        * [10] House/Property
        */
                House=10,
                /**
        * [20] Condo/Apt
        */
                CondoApt=20,
                /**
        * [21] Studio/Apt
        */
                Studio=21,
                /**
        * [30] Office
        */
                Office=30,
                /**
        * [40] Storage Unit
        */
                StorageUnit=40,
                /**
        * [50] 
        */
                Other=50,
                /**
        * [60] Castle
        */
                Castle=60,

        }

        const addressTypeDescription = {
            
                Unknown: 'Unknown',
                House: 'House/Property',
                CondoApt: 'Condo/Apt',
                Studio: 'Studio/Apt',
                Office: 'Office',
                StorageUnit: 'Storage Unit',
                Other: 'Other',
                Castle: 'Castle',
        };

        export const AddressTypeDescription = {

            ...addressTypeDescription,

            get: (value: AddressType | undefined | null): string | undefined => {
                if (typeof (value) === "string") {
                    return addressTypeDescription[value];
                }
                if (value===null || value===undefined) {
                    return undefined;
                }
                return addressTypeDescription[AddressType[value]  as keyof typeof addressTypeDescription];
            },
            all: [
                    
                    { value: 0, label: addressTypeDescription.Unknown },
                    { value: 10, label: addressTypeDescription.House },
                    { value: 20, label: addressTypeDescription.CondoApt },
                    { value: 21, label: addressTypeDescription.Studio },
                    { value: 30, label: addressTypeDescription.Office },
                    { value: 40, label: addressTypeDescription.StorageUnit },
                    { value: 50, label: addressTypeDescription.Other },
                    { value: 60, label: addressTypeDescription.Castle },
            ] as ReadonlyArray<{label: string, value: number}>

        };
    