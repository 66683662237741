export default function <T, TCollection, TResult>(array: T[], collectionSelector: (col: T, index?: number) => TCollection[], resultSelector?: (parent: T, child: TCollection) => TResult): TResult[] {
    let result: TResult[] = [];
    let parent: T;
    let col: TResult[];
    let i: number;

    if (resultSelector) {
        for (i = 0; i < array.length; i++) {
            parent = array[i];

            col = collectionSelector(parent, i).map(m => resultSelector(parent, m));

            result = result.concat(col);
        }
    } else {
        for (i = 0; i < array.length; i++) {
            parent = array[i];

            col = collectionSelector(parent, i) as any as TResult[];

            result = result.concat(col);
        }
    }
    return result;
}
