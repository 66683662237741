import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import { BrandedNav, Icon, iconLibrary } from '../components';
import { MoveDate } from '../components/confirmation/MoveDate';
import { usePageTitle } from '../hooks';
import { useOrderModel } from '../hooks/useOrderModel';
import { ServiceTypeDescription } from '../typewriter/enums';
import { BrandContext } from '../modules/BrandContext';
import { formatPhoneNumber } from '../utils/formatting';

export function OrderConfirmation() {
    const brandInfo = React.useContext(BrandContext);
    const { model, loading } = useOrderModel();

    React.useEffect(() => {
        if (model?.brandType && brandInfo.brand != model.brandType) {
            brandInfo.setBrandType(model.brandType);
        }
    }, [brandInfo, brandInfo.brand, model?.brandType]);

    const loadingJobs = model?.quoteModel.loadingJobs;
    const unloadingJobs = model?.quoteModel.unloadingJobs;

    usePageTitle('Order Confirmation', brandInfo.companyName);

    if (!model || loading) {
        return (
            <Container className='text-center mt-5'>
                <Spinner animation='border' />
                <div>Please wait... Loading</div>
            </Container>
        );
    }

    const leadsAddress = model.quoteModel.loadingJobs?.[0]?.jobAddresses?.[0] ?? model.quoteModel.unloadingJobs?.[0]?.jobAddresses?.[0];

    window['leadsUserData'] = {
        'email': model.quoteModel.contactInfo.email,
        'phone_number': `+1${model.quoteModel.contactInfo.phone}`,
        'address': {
            'first_name': model.quoteModel.contactInfo.firstName,
            'last_name': model.quoteModel.contactInfo.lastName,
            'street': leadsAddress?.street,
            'city': leadsAddress?.city,
            'region': leadsAddress?.state,
            'country': 'US',
            'postal_code': leadsAddress?.zip,
        },
    }

    //GTAG
    if (model?.shouldSendConvTracking) {
        const itemsToAdd: {
            item_id: string;
            item_name: string;
            item_brand: string;
            item_category: string;
            price: number;
            quantity: number;
        }[] = [];

        model.quoteModel.loadingJobs.forEach((job) => {
            itemsToAdd.push({
                item_id: job.jobID!.toString(),
                item_name: 'Loading',
                item_brand: model.loadingProvider,
                item_category: ServiceTypeDescription.get(job.serviceType)!,
                price: model.loadingTotal,
                quantity: 1,
            });
        });

        model.quoteModel.unloadingJobs.forEach((job) => {
            itemsToAdd.push({
                item_id: job.jobID!.toString(),
                item_name: 'Unloading',
                item_brand: model.unloadingProvider,
                item_category: ServiceTypeDescription.get(job.serviceType)!,
                price: model.unloadingTotal,
                quantity: 1,
            });
        });

        const purchaseObj = {
            transaction_id: model.orderId,
            value: model.netIncomeSum,
            currency: 'USD',
            items: itemsToAdd,
        };

        if (window.dataLayer) {

            setTimeout(() => {
                console.debug('Sending purchase event to GTM', { purchaseObj });
                window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
                window.dataLayer.push({
                    event: 'purchase',
                    ecommerce: purchaseObj,
                });
            }, 1000); // wait 1 second to register the purchase event - so the page tracking event happens before it. see PMG-1316
        } else {
            console.debug(
                'window.dataLayer was not loaded - we are probably in non-prod environment. heres what wouldve been sent.',
                purchaseObj,
            );
        }
    } else {
        console.debug(
            'Not sending conversion tracking because shouldSendConvTracking is false',
        );
    }

    return (
        <>
            <BrandedNav
                brandInfo={brandInfo}
                affiliateInfo={model.quoteModel.affiliateInfo}
                shouldShowOffCanvas={false}
            />

            <div className='text-center mb-3'>
                <h1>
                    <span className='text-primary fst-italic'>Your Move</span>{' '}
                    Is Scheduled!
                </h1>
                <hr className='review-bar' />{' '}
                <span className='fs-3'>Pretty easy, huh?</span>{' '}
                <hr className='review-bar' />
            </div>

            <Container className='mb-3'>
                <Row className='justify-content-center'>
                    <Col
                        md={5}
                        lg={4}
                        xxl={3}
                        className='text-center order-md-1'
                    >
                        <img
                            srcSet={`/img/${brandInfo.abbrev}/scheduled.png 1x, /img/${brandInfo.abbrev}/scheduled2x.png 2x`}
                            sizes='(max-width: 300px) 150px, 300px'
                            src={`/img/${brandInfo.abbrev}/scheduled.png`}
                            alt='Scheduled'
                            className='img-fluid'
                        />
                    </Col>
                    <Col md={7} lg={6} xxl={5}>
                        <Row className='mb-3 mt-3'>
                            <Col xs={'auto'}>
                                <span className='number-circle'>1</span>
                            </Col>
                            <Col>
                                You'll receive an email confirmation and receipt
                                for your move.
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col xs={'auto'}>
                                <span className='number-circle'>2</span>
                            </Col>
                            <Col>
                                Updates will be sent to your mobile phone via
                                SMS or iMessage
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col xs={'auto'}>
                                <span className='number-circle'>3</span>
                            </Col>
                            <Col>
                                Sit back and relax as we manage your entire
                                move!
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container className='mb-3'>
                <Row className='justify-content-center'>
                    <Col md={7} lg={6} xxl={5}>
                        {loadingJobs &&
                            loadingJobs.map((job) => (
                                <MoveDate
                                    key={job.jobID}
                                    label='Loading'
                                    job={job}
                                />
                            ))}
                        {loadingJobs && loadingJobs?.length > 0 && <br />}
                        {unloadingJobs &&
                            unloadingJobs.map((job) => (
                                <MoveDate
                                    key={job.jobID}
                                    label='Unloading'
                                    job={job}
                                />
                            ))}
                    </Col>
                    <Col md={5} lg={4} xxl={3}>
                        <div className='d-flex flex-column justify-content-center pt-4 ps-4 review-box-conditional h-100 small'>
                            <Row className='mb-3'>
                                <Col xs={1}>
                                    <FontAwesomeIcon
                                        icon={iconLibrary.faCheck as IconProp}
                                        className='text-primary'
                                    />
                                </Col>
                                <Col>Damage insurance included</Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col xs={1}>
                                    <FontAwesomeIcon
                                        icon={iconLibrary.faCheck as IconProp}
                                        className='text-primary'
                                    />
                                </Col>
                                <Col>Dedicated support agents</Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col xs={1}>
                                    <FontAwesomeIcon
                                        icon={iconLibrary.faCheck as IconProp}
                                        className='text-primary'
                                    />
                                </Col>
                                <Col>Reliable and safe movers</Col>
                            </Row>
                            <div className='d-none d-md-block'>
                                <Row className='mb-3'>
                                    <Col xs={1}>
                                        <FontAwesomeIcon
                                            icon={
                                                iconLibrary.faCheck as IconProp
                                            }
                                            className='text-primary'
                                        />
                                    </Col>
                                    <Col>Experienced service providers</Col>
                                </Row>
                                <Row>
                                    <Col xs={1}>
                                        <FontAwesomeIcon
                                            icon={
                                                iconLibrary.faCheck as IconProp
                                            }
                                            className='text-primary'
                                        />
                                    </Col>
                                    <Col>Top-rated movers in your area</Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className='mb-3'>
                <Row className='justify-content-center'>
                    <Col lg={10} xxl={8}>
                        <Card>
                            <Card.Header>
                                <FontAwesomeIcon
                                    icon={iconLibrary.faPhone as IconProp}
                                    className='me-1'
                                    flip='horizontal'
                                />{' '}
                                Manage Reservation:
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    If you need to make any adjustments to your
                                    reservation or have any questions, connect
                                    with our Moving Specialists at{' '}
                                    <a
                                        className='fw-bold text-decoration-none text-body'
                                        href={`tel:${brandInfo.cleanPhone}`}
                                    >
                                        {brandInfo.brandPhone}
                                    </a>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
