import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { iconLibrary } from '../../components';
import { RequireAtLeastOne } from '../../hooks';

interface MoveReviewProps {
    editMode: boolean;
    model: models.ManagedMoveReviewModel;
    onChange: (newValue: RequireAtLeastOne<models.ManagedMoveReviewModel>) => void;
}


function getRatingLabel(rating: number) {
    switch (rating) {
        case 0:
            return 'N/A';
        case 1:
            return 'Very Poor';
        case 2:
            return 'Ok';
        case 3:
            return 'Regular';
        case 4:
            return 'Good';
        case 5:
            return 'Excellent!!';
    }
}

const textColor = classNames('section-arrow', 'starIcon', 'text-primary');

export const MoveReview = (props: MoveReviewProps) => {

    const { editMode, model, onChange } = props;
    const { rating, comments, question } = model;

    const [moveRating, setMoveRating] = React.useState<number>(rating);
    const ratingText = getRatingLabel(moveRating);

    const icons = React.useMemo(() => {

        const arr = [];
        for (let i = 0; i < 5; i++) {
            arr.push(i >= moveRating ? iconLibrary.farStar : iconLibrary.faStar);
        }
        return arr;
    }, [moveRating]);

    const handleRatingChange = React.useCallback((value: number) => {
        onChange({ rating: value })

        setMoveRating(value);
    }, [onChange]);

    const handleCommentsChange = React.useCallback((value: string) => {

        onChange({ comments: value })

    }, [onChange]);


    return <Row>
        <Col xs={12} className='mt-3'>
            <h5 className='text-secondary'>{question}</h5>
        </Col>
        <Col lg={7}>
            <Row className='row row-cols-7 row-cols-lg-7 g-1 g-lg-1 justify-content-center text-center'>
                {icons.map((v, i) => {
                    return <Col key={i}
                        onMouseLeave={() => editMode ? setMoveRating(rating) : null}
                        onMouseOver={() => editMode ? setMoveRating(i+1) : null}
                        onClick={() => editMode ? handleRatingChange(i+1) : null}>
                        <FontAwesomeIcon className={textColor} icon={v as IconProp} />
                        <p className='mt-2'>{i+1}</p>
                    </Col>;
                })}

                {model.allowNotApplicableRating
                    ? <>
                        <Col key={0}
                            onMouseLeave={() => editMode ? setMoveRating(rating) : null}
                            onMouseOver={() => editMode ? setMoveRating(0) : null}
                            onClick={() => editMode ? handleRatingChange(0) : null}>
                            <FontAwesomeIcon className={textColor} icon={iconLibrary.faBan as IconProp} />
                            <p className='mt-2'>N/A</p>
                        </Col>
                        <Col>
                            <p className={textColor}>{ratingText}</p>
                        </Col>
                    </>
                    : <><Col /><Col /></>
                }


            </Row>
        </Col>

        <Col xs={12} className='mt-4' style={{ whiteSpace:'pre-line' }} >
            <p className='fw-bold text-secondary'>Additional Comments:</p>
            {editMode
                ? <textarea title='Please enter some additional comments about your move, if desired.' className='form-control' id="reviewComments" value={comments} rows={4} maxLength={5000} onChange={v => handleCommentsChange(v.currentTarget.value)} />
                : <p className='text-muted'>{comments || '(None)'}</p>}
            {comments?.length > 100 && editMode && <p className='text-end text-danger text-muted'><small>{comments?.length}/5000</small></p>}
        </Col>
    </Row>;
};
