import * as Sentry from '@sentry/react';
import { FormikHelpers } from 'formik';
import React from 'react';
import { tokenizeFunc } from 'react-braintree-fields';
import { toast } from 'react-toastify';
import { setApiFieldErrors, UpdatePaymentInfoValidationModel } from '../modules';
import { tokenizePayment } from '../modules/tokenizePayment';
import { postUpdatePaymentInfo } from '../typewriter/controllers/OrderController';
import { withScope } from '@sentry/react';
import { OnChangePickSetState } from './usePickSetState';
import { mcProblemJsonFetchError } from '../typewriter/http-utils';

export function useUpdatePaymentInfoHandler(authToken: string, balanceDueNow: number, onShowConfirmation: () => void, brandInfo: BrandInfo, onChange: OnChangePickSetState<models.UpdatePaymentInfoModel>) {

    const [isFormSubmitting, setIsFormSubmitting] = React.useState(false);
    const tokenizeRef = React.useRef<tokenizeFunc>();

    const [testNonce, setTestNonce] = React.useState<string>();

    const handleSubmit = React.useCallback(async (values: UpdatePaymentInfoValidationModel, helpers: FormikHelpers<UpdatePaymentInfoValidationModel>) => {
        if (isFormSubmitting) {
            // we're already submitting. let's not cause double submits...
            // log sentry warning but dont say anything to user
            Sentry?.captureEvent({
                level: 'warning',
                tags: {
                    pmgAuthToken: authToken,
                },
                message: 'Form is already submitting, ignoring submit request.',
                extra: {
                    model: JSON.stringify(values)
                },
            });
            return;
        }

        setIsFormSubmitting(true);

        let nonce: string;
        if (!isProdBuild && testNonce) {
            nonce = testNonce;
        }
        else {
            Sentry?.addBreadcrumb({
                category: 'update-payment-info',
                type: 'info',
                message: 'Attempting to tokenizePayment',
            });

            const tokenResult = await tokenizePayment(tokenizeRef.current, helpers.setFieldError);

            if (!tokenResult.success) {
                setIsFormSubmitting(false);

                // actually, you know, tell the user about the concern
                toast.error(`Sorry, there was an issue with your payment method: ${tokenResult.error}`);

                Sentry?.captureEvent({
                    level: 'warning',
                    message: `Tokenizing payment returned non-success: ${tokenResult.error}`,
                    tags: {
                        pmgAuthToken: authToken,
                    },
                    extra: tokenResult
                } as Sentry.Event);

                return;
            }

            nonce = tokenResult.nonce;
        }

        const model: models.SubmitUpdatePaymentInfoModel = { authToken, paymentInfo: { address: values.paymentInfo.address, nonce }, balanceDueNow: balanceDueNow };

        try {
            Sentry?.addBreadcrumb({
                category: 'update-payment-info',
                type: 'info',
                message: 'Attempting to updatePaymentInfo',
            });

            const result = await postUpdatePaymentInfo(model);

            setIsFormSubmitting(false);

            if (result.success) {
                onChange(prev => ({ ...prev, billingAddress: model.paymentInfo.address, order: { ...prev?.order, lastFour: result.lastFour, nameOnCard: result.nameOnCard }} as models.UpdatePaymentInfoModel));
                onShowConfirmation();
            }
            else {
                Sentry?.captureException(new Error('Update Payment Info returned non-success: ' + result.message), { tags: { pmgAuthToken: authToken, }, extra: { result: JSON.stringify(result), model: JSON.stringify(model) } });

                toast.error(result.message);
            }
        } catch (error: any) {
            setIsFormSubmitting(false);

            let apiError = false;
            if (error.name == mcProblemJsonFetchError.Name) {
                const castedErr = error as mcProblemJsonFetchError;
                apiError = true;
                if (helpers) {
                    setApiFieldErrors(castedErr.webApiProblem.errors, helpers);
                }
                const toastMessage = 'There was one or more validation errors with your form - please take a look and try submitting again.';
                toast.error(toastMessage);
            }
            else
            {
                toast.error('An unexpected error has occurred. We have notified our team and do apologize for any inconvenience. Please call us at ' + brandInfo.brandPhone + ' and we would be happy to assist you.');
            }

            withScope((scope) => {
                scope.setLevel(apiError  ? 'warning' : 'error');
                Sentry?.captureException(error, { tags: { pmgAuthToken: authToken, }, extra: { model: JSON.stringify(model) } });
            });
        }
    }, [authToken, balanceDueNow, brandInfo.brandPhone, isFormSubmitting, onChange, onShowConfirmation, testNonce]);


    return { handleSubmit, tokenizeRef, isFormSubmitting, setTestNonce }
}
