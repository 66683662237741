import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';
import React from 'react';
import { Col, Collapse, Form, Row } from 'react-bootstrap';
import { CheckoutContext } from '../checkout/CheckoutContext';
import { MoveDateAndTime } from './MoveDateAndTime';
import { sqftSizeOptions } from '../modules';
import { JobDetailsTypeOfHelpEnum, JobDetailsTypeOfHelpEnumDescription } from '../typewriter/enums';
import { AddressForm } from './AddressForm';
import { AddressTypeSelection } from './AddressTypeCard';
import { typedHahFormikSelectField } from './HahFormikField';
import { iconLibrary } from './Icon';

const HahFormikSelectField = typedHahFormikSelectField<models.JobAddressModel>();
type StairOption = { label: string; value: number | undefined };

const stairTypeOptions: StairOption[] = [
    { label: 'Select flights of stairs...', value: undefined },
];

for (let i = 0; i <= 9; i++) {
    const label = i === 0 ? `${i} flights of stairs` : i === 1 ? `${i} flight of stairs` : `${i} flights of stairs`;
    stairTypeOptions.push({ label, value: i });
}

interface LocationCardProps {
    typeOfHelpProp: JobDetailsTypeOfHelpEnum;
}
export const LocationCard = ({ typeOfHelpProp }: LocationCardProps) => {
    const { brandInfo } = React.useContext(CheckoutContext);
    const [{ value }, { error, touched }, { setValue }] = useField('typeOfHelp');
    const cardTypeOfHelpDescription = JobDetailsTypeOfHelpEnumDescription.get(typeOfHelpProp)!;
    const selected = value == JobDetailsTypeOfHelpEnum.LoadingAndUnloading || value == typeOfHelpProp;
    const parentName = cardTypeOfHelpDescription!.toLowerCase() + 'Location';
    const handleSelectCard = React.useCallback(() => {
        if (value == JobDetailsTypeOfHelpEnum.LoadingAndUnloading) {
            setValue(typeOfHelpProp == JobDetailsTypeOfHelpEnum.Loading ? JobDetailsTypeOfHelpEnum.Unloading : JobDetailsTypeOfHelpEnum.Loading);
        }
        else if (value != typeOfHelpProp) {
            setValue(JobDetailsTypeOfHelpEnum.LoadingAndUnloading);
        }
    }, [setValue, value, typeOfHelpProp]);

    return <div className='mb-4'>
        <div id={cardTypeOfHelpDescription} className={`location-card bg-white border rounded p-3 ${selected ? 'selected border-primary shadow' : 'mb-2'}`} onClick={!selected ? handleSelectCard : undefined}>
            <Row className='align-items-center text-body gx-3'>
                <Col xs={'auto'}>
                    <Form.Check.Input required name='locationCheck' type='checkbox' checked={selected} className="" id={`locationCheck-${cardTypeOfHelpDescription}`} onChange={handleSelectCard}/>
                </Col>
                {
                    selected && <>
                        <Col xs>
                            <h3 className="fw-bold d-inline-block fs-5 mb-0">{cardTypeOfHelpDescription}</h3>
                        </Col>
                    </>
                }
                <Col xs={'auto'}>
                    <img className='move-details-icon' src={`/img/${brandInfo.abbrev}/move-details-icon.svg`} alt='Mover holding box' width="32" height="32" />
                </Col>
                {
                    !selected && <>
                        <Col xs='auto'>
                            <h3 className="fw-bold d-inline-block fs-5 mb-0">Add {cardTypeOfHelpDescription}</h3>
                        </Col>
                        <Col md className='text-muted pt-2 pt-md-0'>
                            <p className='small mb-0'>You chose <strong>{typeOfHelpProp == JobDetailsTypeOfHelpEnum.Loading ? 'Unloading' : 'Loading'} only</strong>. Check this box for {cardTypeOfHelpDescription} too!</p>
                        </Col>
                    </>
                }

            </Row>

            <Collapse in={selected}>
                <div className='pt-3'>
                    <div className='mb-4 pb-2'>
                        <h2 className="fw-bold h6">Enter your {cardTypeOfHelpDescription.toLowerCase()} address below</h2>
                        <AddressForm parentName={parentName} />
                    </div>
                    <div className='mb-4 pb-2'>
                        <h2 className="fw-bold h6">When are you moving?</h2>
                        <p>Select your date (it cannot be today or tomorrow)</p>
                        <MoveDateAndTime parentName={parentName} typeOfHelp={typeOfHelpProp} />
                    </div>
                    <div className='mb-4 pb-2'>
                        <h2 className="fw-bold h6">What is the size of your {cardTypeOfHelpDescription.toLowerCase()} location?</h2>
                        <p>Select one of the options</p>
                        <AddressTypeSelection parentName={parentName} />
                    </div>
                    <div className='mb-4 pb-2'>
                        <h2 className="fw-bold h6">What is the approximate square footage</h2>
                        <p>Select the approximate square footage of your {cardTypeOfHelpDescription.toLowerCase()} location</p>
                        <HahFormikSelectField className="mb-3" parentName={parentName} name="sqFootage" label="Set Square Feet" options={sqftSizeOptions} />
                    </div>
                    <div className='mb-4'>
                        <h2 className="fw-bold h6">How many flights of stairs are there?</h2>
                        <p>Four or more stairs between floors are considered a flight of stairs. A landing between stairs is still considered one flight.</p>
                        <HahFormikSelectField className="mb-3" parentName={parentName} name="stairs" options={stairTypeOptions} />
                    </div>
                </div>
            </Collapse>
        </div>
        { !selected && <>
            <p className='text-primary fw-bold d-flex align-items-center small'><FontAwesomeIcon icon={iconLibrary.faBolt as IconProp} className='pe-2' />Save an average of 6 hours when you book loading and unloading</p>
        </> }
  </div>;
}