import * as Sentry from '@sentry/react';
import {
    mcBadRequestFetchError,
    mcGoneFetchError,
    mcNotFoundFetchError,
} from '../typewriter/http-utils';

export const catchAndLogToSentry = (
    error: any,
    message: string | undefined,
    authToken: string | undefined,
    extra: any | undefined = {}
) => {
    if (message) {
        extra.message = message;
    }
    const level =
        error.name == mcNotFoundFetchError.Name ||
        error.name == mcGoneFetchError.Name ||
        error.name == mcBadRequestFetchError.Name
            ? 'warning'
            : 'error';
    Sentry?.captureException(error, {
        level,
        tags: { pmgAuthToken: authToken },
        extra,
    });
};
