import * as Sentry from '@sentry/browser';
import { Event, withScope } from '@sentry/react';
import { FormikHelpers } from 'formik';
import React from 'react';
import { tokenizeFunc } from 'react-braintree-fields';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BookOrderValidationModel, setApiFieldErrors } from '../modules';
import { tokenizePayment } from '../modules/tokenizePayment';
import { bookOrder } from '../typewriter/controllers/BookingSubdomainController';
import { BookingSubdomainQuoteErrorReason } from '../typewriter/enums';
import { TransientAuthType } from '../typewriter/enums/TransientAuthType';
import { mcProblemJsonFetchError } from '../typewriter/http-utils';
import { useReviewModel } from './useReviewModel';
import { catchAndLogToSentry } from '../modules/catchAndLogToSentry';


export function useBookOrderHandler(authToken: string, tokenType: TransientAuthType, setLoading: React.Dispatch<React.SetStateAction<boolean>>, brandInfo: BrandInfo) {
    const { reviewModel, fetchModel } = useReviewModel(authToken, tokenType, setLoading);

    const [isFormSubmitting, setIsFormSubmitting] = React.useState(false);
    const tokenizeRef = React.useRef<tokenizeFunc>();

    const { push } = useHistory();

    const [testNonce, setTestNonce] = React.useState<string>();

    const handleSubmit = React.useCallback(async (values: BookOrderValidationModel, actions: FormikHelpers<BookOrderValidationModel>) => {
        if (isFormSubmitting) {
            // we're already submitting. let's not cause double submits...
            // log sentry warning but dont say anything to user
            Sentry?.captureEvent({
                level: 'warning',
                tags: {
                    pmgAuthToken: authToken,
                },
                message: 'Form is already submitting, ignoring submit request.',
                extra: {
                    model: JSON.stringify(values)
                },
            });
            return;
        }

        setIsFormSubmitting(true);

        let nonce: string;
        if (!isProdBuild && testNonce) {
            nonce = testNonce;
        }
        else {
            Sentry?.addBreadcrumb({
                category: 'book-order',
                type: 'info',
                message: 'Attempting to tokenizePayment',
            });
            const tokenResult = await tokenizePayment(tokenizeRef.current, actions.setFieldError);

            if (!tokenResult.success) {
                setIsFormSubmitting(false);

                // actually, you know, tell the user about the concern
                toast.error(`Sorry, there was an issue with your payment method: ${tokenResult.error}`);

                Sentry?.captureEvent({
                    level: 'warning',
                    tags: {
                        pmgAuthToken: authToken,
                    },
                    message: `Tokenizing payment returned non-success: ${tokenResult.error}`,
                    extra: tokenResult
                } as Event);

                return;
            }
            else {
                nonce = tokenResult.nonce;
            }
        }

        const paymentInfo = { address: values.paymentInfo.address, nonce };
        const model: models.BookingSubdomainBookOrderModel = { authToken, tokenType, paymentInfo, orderTotal: reviewModel!.quote.grandOrderTotal!, balanceDueNow: reviewModel!.quote.balanceDueNowSum! };

        try {

            Sentry?.addBreadcrumb({
                category: 'book-order',
                type: 'info',
                message: 'Attempting to bookOrder',
            });

            const result = await bookOrder(model);

            setIsFormSubmitting(false);

            if (result.success) {
                //toast.success('Order Booked!');
                //moveNext();
                push(`/order-confirmation/?token=${result.orderToken}`)
            }
            else {
                // unsuccesful results will be logged in the api
                // captureException(new Error('Unsuccessful booking quote'), { extra: { result: JSON.stringify(result), model: JSON.stringify(model) } });

                switch (result.errorReason) {
                    case BookingSubdomainQuoteErrorReason.BalanceDueMismatch:
                    case BookingSubdomainQuoteErrorReason.BalanceDueMismatchJobs:
                    case BookingSubdomainQuoteErrorReason.OrderTotalMismatch:
                        await fetchModel();
                        toast.error('It appears this quote may have changed since it was opened. We just reloaded the latest information, so please double check all of your move details and try submitting again.');
                        break;
                    default:
                        toast.error(result.message);
                        break;

                }
            }


        } catch (error: any) {
            setIsFormSubmitting(false);

            let apiError = false;
            if (error.name == mcProblemJsonFetchError.Name) {
                const castedErr = error as mcProblemJsonFetchError;
                apiError = true;
                if (actions) {
                    setApiFieldErrors(castedErr.webApiProblem.errors, actions);
                }
                const toastMessage = 'There was one or more validation errors with your form - please take a look and try submitting again.';
                toast.error(toastMessage);
            }
            else
            {
                toast.error('An unexpected error has occurred. We have notified our team and do apologize for any inconvenience. Please call us at ' + brandInfo.brandPhone + ' and we would be happy to assist you.');
            }

            withScope((scope) => {
                scope.setLevel(apiError  ? 'warning' : 'error');
                catchAndLogToSentry(error,
                    'bookOrder returned non-success: ' + error.message,
                    authToken,
                    {
                        error,
                        model: JSON.stringify(model)
                    }
                );
            });
        }
    }, [authToken, brandInfo.brandPhone, fetchModel, isFormSubmitting, push, reviewModel, testNonce, tokenType]);

    return { reviewModel, handleSubmit, tokenizeRef, isFormSubmitting, setTestNonce }
}
