/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-const */
// KL 1/20/14
// Basic array prototype helper methods - comparable to functions available in Linq
//import 'foo';

import distinct from './distinct';
import selectMany from './selectMany';
//declare module "test" {

//}

export const prototypes = {};

declare global {
    interface ReadonlyArray<T> {

        //firstOrDefault(predicate?: (item: T, index?: number) => any, thisArg?: any): T;
        singleOrDefault(predicate?: (item: T, index?: number) => any, thisArg?: any): T | undefined;
        lastOrDefault(predicate?: (item: T, index?: number) => any, thisArg?: any): T | undefined;
        sum(predicate: (item: T, index?: number) => number, thisArg?: any): number;
        count(predicate: (item: T, index?: number) => boolean, thisArg?: any): number;
        selectMany<TCollection>(collectionSelector: (item: T, index?: number) => ReadonlyArray<TCollection> | TCollection[]): TCollection[];
        selectMany<TCollection, TResult>(collectionSelector: (item: T, index?: number) => ReadonlyArray<TCollection> | TCollection[],
            resultSelector: (parent: T, child: TCollection) => TResult): TResult[];
        bin(predicate: (item: T) => string, thisArg?: any): Array<BinResult<T>>;
        /**
* Determines whether two arrays have the same contents. Returns true if they are the same
* @param other
* @returns boolean
*/
        compare(other: T[]): boolean;
        groupBy<TKey>(predicate: (item: T, index?: number) => TKey, comparer?: (a: TKey, b: TKey) => boolean): Array<Group<T, TKey>>;
        sortBy(expression: (item: T) => any, caseInsensitive?: boolean): T[];
        sortByMultiple(...expression: Array<(item: T) => any>): T[];

        contains(item: T | undefined): boolean;

        distinct(comparer?: (item: T) => string | number): T[];
        take(maxcount: number): T[];
        max(predicate: (item: T, index?: number) => number, thisArg?: any): number | undefined;
        min(predicate: (item: T, index?: number) => number, thisArg?: any): number | undefined;

    }

    interface Array<T> {
        //firstOrDefault(predicate?: (item: T, index?: number) => any, thisArg?: any): T;
        singleOrDefault(predicate?: (item: T, index?: number) => any, thisArg?: any): T | undefined;
        lastOrDefault(predicate?: (item: T, index?: number) => any, thisArg?: any): T | undefined;
        sum(predicate: (item: T, index?: number) => number, thisArg?: any): number;
        count(predicate: (item: T, index?: number) => boolean, thisArg?: any): number;
        selectMany<TCollection>(collectionSelector: (item: T, index?: number) => ReadonlyArray<TCollection> | TCollection[]): TCollection[];
        selectMany<TCollection, TResult>(collectionSelector: (item: T, index?: number) => ReadonlyArray<TCollection> | TCollection[],
            resultSelector: (parent: T, child: TCollection) => TResult): TResult[];
        bin(predicate: (item: T) => string, thisArg?: any): Array<BinResult<T>>;
        /**
    * Determines whether two arrays have the same contents. Returns true if they are the same
    * @param other
    * @returns boolean
    */
        compare(other: T[]): boolean;
        groupBy<TKey>(predicate: (item: T, index?: number) => TKey, comparer?: (a: TKey, b: TKey) => boolean): Array<Group<T, TKey>>;
        sortBy(expression: (item: T) => any, caseInsensitive?: boolean): T[];
        sortByMultiple(...expression: Array<(item: T) => any>): T[];

        contains(item: T | undefined): boolean;

        distinct(comparer?: (item: T) => string | number): T[];
        take(maxcount: number): T[];
        max(predicate: (item: T, index?: number) => number, thisArg?: any): number | undefined;
        min(predicate: (item: T, index?: number) => number, thisArg?: any): number | undefined;

        remove(predicate: (item: T, index?: number) => boolean): T[];

    }

    interface String {
        repeat(count: number): string;
        padStart(targetLength: number, padString: string): string;
    }

}

// Array.prototype.firstOrDefault = function (this: any[], predicate: (item: any, index?: number) => any = null, thisArg?: any) {

//     for (let i = 0, len = this.length; i < len; ++i) {
//         if (i in this) {
//             const item = this[i];
//             if (predicate == null || predicate.call(thisArg, item, i)) {
//                 return item;
//             }
//         }
//     }

//     return null;
// };

Array.prototype.lastOrDefault = function (this: any[], predicate?: (item: any, index?: number) => any, thisArg?: any) {

    for (let i = this.length - 1; i >= 0; i--) {
        if (i in this) {
            const item = this[i];
            if (!predicate || predicate.call(thisArg, item, i)) {
                return item;
            }
        }
    }

    return null;
};

Array.prototype.singleOrDefault = function (this: any[], predicate?: (item: any, index?: number) => any, thisArg?: any) {

    let match: any;

    for (let i = 0, len = this.length; i < len; ++i) {
        if (i in this) {
            const item = this[i];
            if (!predicate || predicate.call(thisArg, item, i)) {
                if (match) {
                    throw new Error('singleOrDefault found more than 1 match');
                }
                match = item;
            }
        }
    }

    return match;
};

Array.prototype.selectMany = function <T, TCollection, TResult>(this: T[], collectionSelector: (col: T) => TCollection[], resultSelector?: (parent: T, child: TCollection) => TResult): TResult[] {
    // let result: TResult[] = [];
    // let parent: T;
    // let col: TResult[];
    // let i: number;

    // if (resultSelector) {
    //     for (i = 0; i < this.length; i++) {
    //         parent = this[i];

    //         col = collectionSelector(parent).map(m => resultSelector(parent, m));

    //         result = result.concat(col);
    //     }
    // } else {
    //     for (i = 0; i < this.length; i++) {
    //         parent = this[i];

    //         col = collectionSelector(parent) as any as TResult[];

    //         result = result.concat(col);
    //     }
    // }
    // return result;
    return selectMany(this, collectionSelector, resultSelector);
};

Array.prototype.count = function (this: any[], predicate: (item: any, index?: number) => boolean, thisArg?: any) {

    'use strict';

    if (typeof predicate === 'function') {
        let total = 0;
        for (let i = 0, len = this.length; i < len; ++i) {
            if (i in this) {
                const item = this[i];
                if (predicate.call(thisArg, item, i)) {
                    total++;
                }
            }
        }
        return total;

    }
    return this.length;
};

Array.prototype.sum = function (this: any[], predicate: (item: any, index?: number) => number, thisArg?: any) {

    let total = 0;
    for (let i = 0, len = this.length; i < len; ++i) {
        if (i in this) {
            const item = this[i];
            const value = predicate.call(thisArg, item, i);
            total += value;
        }
    }

    return total;
};

// TODO: these arent working/compiling
/*
Array.prototype.max = function (this: any[], predicate: (item: any, index?: number) => number, thisArg?: any) {

    if (this.length == 0) {
        return undefined;
    }
    let max = 0;
    for (let i = 0, len = this.length; i < len; ++i) {
        if (i in this) {
            const item = this[i];
            const value = predicate.call(thisArg, item, i);

            if (value > max) {
                max = value;
            }
        }
    }

    return max;
};

Array.prototype.min = function (this: any[], predicate: (item: any, index?: number) => number, thisArg?: any) {

    if (this.length == 0) {
        return undefined;
    }
    let min = 2147483647;
    for (let i = 0, len = this.length; i < len; ++i) {
        if (i in this) {
            const item = this[i];
            const value = predicate.call(thisArg, item, i);

            if (value < min) {
                min = value;
            }
        }
    }

    return min;
};
*/

/**
 * Sorts an array into groups based on keys generated by the supplied function.
 * @param keyGen
 * @returns binResult[]
 */
Array.prototype.bin = function <T>(this: any[], keyGen: (item: any) => string): Array<BinResult<T>> {
    const result: Array<BinResult<T>> = [];
    // eslint-disable-next-line:prefer-for-of
    for (let i = 0; i < this.length; i++) {
        const key = keyGen(this[i]);
        let br = result.find(a => a.key === key);
        if (br == null) {
            br = {
                key,
                items: [],
            } as BinResult<T>;
            br.key = key;
            br.items = new Array<T>();
            result.push(br);
        }
        br.items.push(this[i]);
    }

    return result;
};
interface BinResult<T> {
    key: string;
    items: T[];
}

/**
 * Sorts an array into groups based on keys generated by the supplied function.
 * @param keyGen
 * @returns Group[]
 */
Array.prototype.groupBy = function <T, TKey>(this: any[], keyGen: (item: T, index?: number) => TKey, comparer: (a: TKey, b: TKey) => boolean = (a, b) => a === b): Array<Group<T, TKey>> {
    const result: Array<Group<T, TKey>> = [];
    for (let i = 0; i < this.length; i++) {
        const key = keyGen(this[i], i);
        let g = result.find(a => comparer(a.key, key));
        if (g == null) {
            g = {
                key,
                items: [],
            };
            result.push(g);
        }
        g.items.push(this[i]);
    }

    return result;
};
interface Group<T, TKey> {
    key: TKey; items: T[];
}

if (!Array.prototype.forEach) {

    Array.prototype.forEach = function (this: any[], callbackFn: (item: any, index?: number, array?: any[]) => void, thisArg?: any) {

        for (let i = 0, len = this.length; i < len; ++i) {
            if (i in this) {
                callbackFn.call(thisArg, this[i], i, this);
            }
        }
    } as any;

}

// Production steps of ECMA-262, Edition 5, 15.4.4.19
// Reference: http://es5.github.com/#x15.4.4.19
if (!Array.prototype.map) {
    Array.prototype.map = function (this: any[], callback, thisArg?: any) {

        // eslint-disable-next-line:one-variable-per-declaration
        let T, A, k;

        if (this == null) {
            throw new TypeError(' this is null or not defined');
        }

        // 1. Let O be the result of calling ToObject passing the |this| value as the argument.
        const O = Object(this);

        // 2. Let lenValue be the result of calling the Get internal method of O with the argument "length".
        // 3. Let len be ToUint32(lenValue).
        // eslint-disable-next-line:no-bitwise
        const len = O.length >>> 0;

        // 4. If IsCallable(callback) is false, throw a TypeError exception.
        // See: http://es5.github.com/#x9.11
        if (typeof callback !== 'function') {
            throw new TypeError(callback + ' is not a function');
        }

        // 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
        if (thisArg) {
            T = thisArg;
        }

        // 6. Let A be a new array created as if by the expression new Array(len) where Array is
        // the standard built-in constructor with that name and len is the value of len.
        A = new Array(len);

        // 7. Let k be 0
        k = 0;

        // 8. Repeat, while k < len
        while (k < len) {

            // eslint-disable-next-line:one-variable-per-declaration
            let kValue, mappedValue;

            // a. Let Pk be ToString(k).
            //   This is implicit for LHS operands of the in operator
            // b. Let kPresent be the result of calling the HasProperty internal method of O with argument Pk.
            //   This step can be combined with c
            // c. If kPresent is true, then
            if (k in O) {

                // i. Let kValue be the result of calling the Get internal method of O with argument Pk.
                kValue = O[k];

                // ii. Let mappedValue be the result of calling the Call internal method of callback
                // with T as the this value and argument list containing kValue, k, and O.
                mappedValue = callback.call(T, kValue, k, O);

                // iii. Call the DefineOwnProperty internal method of A with arguments
                // Pk, Property Descriptor {Value: mappedValue, : true, Enumerable: true, Configurable: true},
                // and false.

                // In browsers that support Object.defineProperty, use the following:
                // Object.defineProperty(A, Pk, { value: mappedValue, writable: true, enumerable: true, configurable: true });

                // For best browser support, use the following:
                A[k] = mappedValue;
            }
            // d. Increase k by 1.
            k++;
        }

        // 9. return A
        return A;
    };
}

if (!Array.prototype.filter) {
    Array.prototype.filter = function (this: any[], fn: any, context?: any) {
        // eslint-disable-next-line:one-variable-per-declaration
        let i, value, length;
        const result = [];

        if (!this || typeof fn !== 'function' || (fn instanceof RegExp)) {
            throw new TypeError();
        }

        length = this.length;

        for (i = 0; i < length; i++) {
            if (this.hasOwnProperty(i)) {
                value = this[i];
                if (fn.call(context, value, i, this)) {
                    result.push(value);
                }
            }
        }
        return result;
    };
}
if (!Array.prototype.some) {
    Array.prototype.some = function (this: any[], fun) {

        if (this == null) {
            throw new TypeError();
        }

        // eslint-disable-next-line:one-variable-per-declaration
        let thisp, i;
        const t = Object(this);
        // eslint-disable-next-line:no-bitwise
        const len = t.length >>> 0;
        if (typeof fun !== 'function') {
            throw new TypeError();
        }

        thisp = arguments[1];
        for (i = 0; i < len; i++) {
            if (i in t && fun.call(thisp, t[i], i, t)) {
                return true;
            }
        }

        return false;
    };
}

if (!Array.prototype.every) {
    Array.prototype.every = function (this: any[], fun: any  /*, thisArg */) {

        if (this === void 0 || this === null) {
            throw new TypeError();
        }

        const t = Object(this);
        // eslint-disable-next-line:no-bitwise
        const len = t.length >>> 0;
        if (typeof fun !== 'function') {
            throw new TypeError();
        }

        const thisArg = arguments.length >= 2 ? arguments[1] : void 0;
        for (let i = 0; i < len; i++) {
            if (i in t && !fun.call(thisArg, t[i], i, t)) {
                return false;
            }
        }

        return true;
    };
}

/**
* Determines whether two arrays have the same contents. Returns true if they are the same
* @param other
* @returns boolean
*/
if (!Array.prototype.compare) {
    Array.prototype.compare = function (this: any[], other: any) {
        if (other === null || other === undefined) {
            throw new TypeError();
        }

        if (this.length !== other.length) { return false; }

        for (let i = 0; i < this.length; i++) {
            if (this[i].compare && this[i].prototype === other[i].prototype) {
                // for nested arrays or custom comparisons
                if (!this[i].compare(other[i])) { return false; }
            } else {
                if (this[i] !== other[i]) { return false; }
            }
        }

        return true;
    };
}

if (!String.prototype.trim) {
    String.prototype.trim = function (this: string) {
        return this.replace(/^\s+|\s+$/g, '');
    };
}

//Array.prototype.sortBy = function <T, TKey>(this: any[], expression: (item) => TKey) {

//    return this.sort((a, b) => {
//        var av = expression(a);
//        var bv = expression(b);
//        return ((av < bv) ? -1 : ((av > bv) ? 1 : 0));
//    });

//}

Array.prototype.sortBy = function <T, TKey>(this: T[], expression: (item: T) => TKey, caseInsensitive?: boolean) {

    if (caseInsensitive) {

        let sortFunc: (a: any, b: any) => number;

        return [...this].sort((a: any, b: any) => {
            const av = expression(a) as any;
            const bv = expression(b) as any;

            if (av == null) {
                if (bv == null) {
                    return 0;
                } else {
                    return -1;
                }
            } else if (bv == null) {
                return 1;
            }

            if (!sortFunc) {

                const aType = typeof av;
                const bType = typeof bv;

                if (aType === bType && aType === 'string') {
                    sortFunc = (a2, b2) => {
                        return a2.localeCompare(b2, [], { sensitivity: 'base' });

                    };

                } else {
                    sortFunc = (a2, b2) => {

                        return ((a2 < b2) ? -1 : ((a2 > b2) ? 1 : 0));
                    };
                }

            }
            return sortFunc(av, bv);

        });
    }

    const sorters = {
        string: function(a: string, b: string) {
          if (a < b) {
            return -1;
          } else if (a > b) {
            return 1;
          } else {
            return 0;
          }
        },

        number: function(a: number, b: number) {
          return a - b;
        },
      };

    return [...this].sort((a, b) => {
        const type = typeof expression(a) || 'string';

        const sorter  =sorters[type] ?? sorters.string;

        return sorter(expression(a), expression(b));
    });
};
Array.prototype.sortByMultiple = function <T, TKey>(this: T[], ...expressions: Array<(item: T) => TKey>) {

    return [...this].sort((a: any, b: any) => {

        for (const expression of expressions) {
            const av = expression(a);
            const bv = expression(b);

            if (av > bv) {
                return 1;
            }
            if (av < bv) {
                return -1;
            }
        }
        return 0;
    });

};

Array.prototype.distinct = function <T>(this: T[], comparer?: (item: T) => string | number) {
    // const u = {};
    // const a = [];
    // for (let i = 0, l = this.length; i < l; ++i) {

    //     const current = this[i];
    //     const compare = comparer ? comparer(current) : current as any;
    //     if (u.hasOwnProperty(compare)) {
    //         continue;
    //     }
    //     a.push(this[i]);
    //     u[compare] = 1;
    // }
    // return a;
    return distinct(this, comparer);
};

Array.prototype.take = function (this: any[], maxcount: number) {
    return this.slice(0, Math.min(this.length, maxcount));
};

Array.prototype.remove = function (this: any[], predicate: (v: any, i?: number) => boolean) {

    const removedValues: any[] = [];

    for (let i = 0; i < this.length; i++) {
        const value = this[i];
        if (predicate(value, i)) {
            removedValues.push(value);
            this.splice(i, 1);
            i--;
        }
    }

    return removedValues;

};

if (!String.prototype.repeat) {
    String.prototype.repeat = function (this: string, count: any) {

        if (this == null) {
            throw new TypeError('can\'t convert ' + this + ' to object');
        }
        let str = '' + this;
        count = +count;
        if (count != count) {
            count = 0;
        }
        if (count < 0) {
            throw new RangeError('repeat count must be non-negative');
        }
        if (count == Infinity) {
            throw new RangeError('repeat count must be less than infinity');
        }
        count = Math.floor(count);
        if (str.length == 0 || count == 0) {
            return '';
        }
        // Ensuring count is a 31-bit integer allows us to heavily optimize the
        // main part. But anyway, most current (August 2014) browsers can't handle
        // strings 1 << 28 chars or longer, so:
        // eslint-disable-next-line:no-bitwise
        if (str.length * count >= 1 << 28) {
            throw new RangeError('repeat count must not overflow maximum string size');
        }
        let rpt = '';
        for (; ;) {
            // eslint-disable-next-line:no-bitwise
            if ((count & 1) == 1) {
                rpt += str;
            }
            // eslint-disable-next-line:no-bitwise
            count >>>= 1;
            if (count == 0) {
                break;
            }
            str += str;
        }
        // Could we try:
        // return Array(count + 1).join(this);
        return rpt;
    };
}

// https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
if (!String.prototype.padStart) {
    String.prototype.padStart = function padStart(targetLength: number, padString: string) {
        // eslint-disable-next-line:no-bitwise
        targetLength = targetLength >> 0; //truncate if number or convert non-number to 0;
        padString = String((typeof padString !== 'undefined' ? padString : ' '));
        if (this.length > targetLength) {
            return String(this);
        }
        else {
            targetLength = targetLength - this.length;
            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
            }
            return padString.slice(0, targetLength) + String(this);
        }
    };
}

Array.prototype.contains = function (this: any[], item: any) {

    return this.indexOf(item) > -1;
};
