import { FormikHelpers } from 'formik';

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegex = /^(1\s*[-/.]?)?(\(([2-9]\d{2})\)|([2-9]\d{2}))\s*[-/.]?\s*(\d{3})\s*[-/.]?\s*(\d{4})\s*(([xX]|[eE][xX][tT])\.?\s*(\d+))*$/;

export function setApiFieldErrors(errors: any, formikHelpers: FormikHelpers<any>) {
    Object.entries(errors).forEach(([key, value]: [string, any]) => {
            // we use PastelCase in .net and camelCase in JS... so...
            const properKeys = key.split('.');
            const camelKeys = properKeys.map((k) => {
            return k.charAt(0).toLowerCase() + k.slice(1)
        })
        let camel = camelKeys.join('.')
        if (camel.startsWith('loadingLocation') || camel.startsWith('unloadingLocation')) {
            camel = camel.replace(/\.jobAddresses\[0\]/g, '');
        }
        formikHelpers.setFieldTouched(camel, true, false);
        formikHelpers.setFieldError(camel, value);
    });
}