




        /**
        * A TypeScript version of a server-side enum. This is the "basic" version of type of help that the job details page can represent, like load, unload, or both.
        */
        export enum JobDetailsTypeOfHelpEnum {
            
                /**
        * [0] 
        */
                Unknown=0,
                /**
        * [1] 
        */
                Loading=1,
                /**
        * [2] 
        */
                Unloading=2,
                /**
        * [3] Loading + Unloading
        */
                LoadingAndUnloading=3,
                /**
        * [4] Rearrange/Move On-Site Only
        */
                MoveOnSiteOnly=4,

        }

        const jobDetailsTypeOfHelpEnumDescription = {
            
                Unknown: 'Unknown',
                Loading: 'Loading',
                Unloading: 'Unloading',
                LoadingAndUnloading: 'Loading + Unloading',
                MoveOnSiteOnly: 'Rearrange/Move On-Site Only',
        };

        export const JobDetailsTypeOfHelpEnumDescription = {

            ...jobDetailsTypeOfHelpEnumDescription,

            get: (value: JobDetailsTypeOfHelpEnum | undefined | null): string | undefined => {
                if (typeof (value) === "string") {
                    return jobDetailsTypeOfHelpEnumDescription[value];
                }
                if (value===null || value===undefined) {
                    return undefined;
                }
                return jobDetailsTypeOfHelpEnumDescription[JobDetailsTypeOfHelpEnum[value]  as keyof typeof jobDetailsTypeOfHelpEnumDescription];
            },
            all: [
                    
                    { value: 0, label: jobDetailsTypeOfHelpEnumDescription.Unknown },
                    { value: 1, label: jobDetailsTypeOfHelpEnumDescription.Loading },
                    { value: 2, label: jobDetailsTypeOfHelpEnumDescription.Unloading },
                    { value: 3, label: jobDetailsTypeOfHelpEnumDescription.LoadingAndUnloading },
                    { value: 4, label: jobDetailsTypeOfHelpEnumDescription.MoveOnSiteOnly },
            ] as ReadonlyArray<{label: string, value: number}>

        };
    