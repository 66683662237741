import React, { useCallback, useRef, useState } from 'react';


export type OnChangePickSetState<T extends {}, TFull extends T = T> = (change: PickSetState<T, TFull>) => void;


export type OnlyUndefinableKeys<Type> = {
    [Key in keyof Type]: undefined extends Type[Key] ? Key : never
};

export type OnlyUndefinable<Type> = OnlyUndefinableKeys<Type>[keyof Type];

export type CombineNullable<T, Keys extends keyof T | OnlyUndefinable<T> = keyof T | OnlyUndefinable<T>> = {
    [Key in Keys]-?: T[Key]
}


export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = {
    [Key in Keys]-?: Required<Pick<CombineNullable<T>, Key>> & Partial<Pick<T, Exclude<keyof T, Key>>>;
}[Keys];



export type PickSetState<T, TFull extends T = T> = ((prev: TFull) => RequireAtLeastOne<T> | void | undefined) | RequireAtLeastOne<T>;


export function usePickSetState<T extends {}>(initialState?: T | (() => T)): [T, OnChangePickSetState<T>, React.MutableRefObject<T>] {

    const [state, setState] = useState<T>(initialState!);

    const ref = useRef(state);

    const handleChange = useCallback<OnChangePickSetState<T>>((change) => {

        setState(prev => {

            if (typeof change === 'function') {
                const newValue = change(prev);
                if (newValue !== undefined) {

                    return ref.current = { ...prev, ...newValue };
                }
                else {
                    return prev;
                }
            }
            else {
                return ref.current = { ...prev, ...change };
            }
        });

    }, []);

    return [state, handleChange, ref];
}
