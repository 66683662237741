import * as Sentry from '@sentry/browser';
import { Formik } from 'formik';
import React from 'react';
import { Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { ActionButton, BillingAddress, BrandedNav, ErrorFocus, MoveSummary, PaymentDateInfo, PaymentInfo, UpdatePaymentFooterActionBar, iconLibrary } from '../components';
import { PaymentInfoDev } from '../components/payment/PaymentInfoDev';
import { OnChangePickSetState, usePageTitle } from '../hooks';
import { useUpdatePaymentInfoHandler } from '../hooks/useUpdatePaymentInfoHandler';
import { useUpdatePaymentInfoModel } from '../hooks/useUpdatePaymentInfoModel';
import { UpdatePaymentInfoValidationModel, updatePaymentInfoValidationSchema } from '../modules';
import { formatCurrency, formatPhoneNumber } from '../utils/formatting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AddressOneLine } from '../components/shared/AddressOneLine';
import { PaymentMethodDescription, TypeOfHelpEnum } from '../typewriter/enums';
import { BrandContext } from '../modules/BrandContext';


const PaymentInfoActual = isProdBuild ? PaymentInfo : PaymentInfoDev;

//const initialValues = { cardholderName: '', cardNumber: '', expirationDate: '', cvv: '' };

export const UpdatePaymentInfo = () => {
    const brandInfo = React.useContext(BrandContext);

    const { authToken, model, setLoading, loading, setModel } = useUpdatePaymentInfoModel();

    React.useEffect(() => {
        if (model?.order?.brandType && brandInfo.brand != model.order.brandType) {
            brandInfo.setBrandType(model.order.brandType);
        }
    }, [brandInfo, brandInfo.brand, model?.order?.brandType]);

    const [showConfirmation, setShowConfirmation] = React.useState(false);

    const onShowConfirmation = React.useCallback(() => setShowConfirmation(true), []);

    usePageTitle('Update Payment Information', brandInfo.companyName);

    if (authToken && !model && loading) {
        return <Container className="text-center mt-5">
            <Spinner animation='border' />
            <div>Please wait... Loading</div>
        </Container>;
    }

    if (!authToken || !model) {

        return <Container className="pb-3 body-container">
            <h2 className={'text-center fw-bold text-secondary mb-4 mb-lg-5'}>Sorry, there seems to be a problem with the link you are attempting to access.</h2>
        </Container>;
    }


    if (showConfirmation) {
        return <>
            <BrandedNav brandInfo={brandInfo} affiliateInfo={model.order.quoteModel.affiliateInfo} shouldShowOffCanvas={false} />
            <PaymentUpdateConfirmationPage model={model} brandInfo={brandInfo} />
        </>;
    }

    return <>
        <BrandedNav brandInfo={brandInfo} affiliateInfo={model.order.quoteModel.affiliateInfo} shouldShowOffCanvas={false} />
        <UpdatePaymentInfoForm {...{ authToken, model, setLoading, loading, onShowConfirmation, brandInfo, setModel }} />
    </>;
}


interface Props {
    authToken: string;
    model: models.UpdatePaymentInfoModel;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    onShowConfirmation: () => void;
    brandInfo: BrandInfo;
    setModel: OnChangePickSetState<models.UpdatePaymentInfoModel>;
}

const UpdatePaymentInfoForm = ({ model, authToken, onShowConfirmation, brandInfo, setModel }: Props) => {

    const { handleSubmit, tokenizeRef, isFormSubmitting, setTestNonce } = useUpdatePaymentInfoHandler(authToken!, model.order.balanceDueNowSum!, onShowConfirmation, brandInfo, setModel);

    //const [error, setError] = React.useState<Error>();

    const handleError = React.useCallback((error: Error) => {
        Sentry.captureException(error, { tags: { pmgAuthToken: authToken } });
    }, [authToken]);

    const buttonText = model.order.balanceDueNowSum! > 0 ? {
        label: 'Process Now',
        busyLabel: 'Processing'
    }
        : {
            label: 'Confirm Update',
            busyLabel: 'Updating'
        };

    return (<Formik
        initialValues={{ paymentInfo: { address: model.billingAddress, cardholderName: '', cardNumber: '', expirationDate: '', cvv: '' } } as UpdatePaymentInfoValidationModel}
        validationSchema={updatePaymentInfoValidationSchema}
        onSubmit={handleSubmit}>
        {({ handleSubmit, setValues }) => <Form noValidate>
            <ErrorFocus />
            <Container className="pb-3 body-container">
                <h1 className={'text-center fw-bold text-secondary mb-4 mb-lg-5'}>Please update your card and billing information below:</h1>
                <Row className="justify-content-center">
                    <Col className="order-lg-1" lg={7} xl={6}>
                        <MoveSummary model={model.order} />
                    </Col>
                    <Col lg={5}>

                        <h6 className="fw-bold mb-3">Payment information:</h6>

                        <PaymentInfoActual braintreeClientToken={model.braintreeClientToken} tokenizeRef={tokenizeRef} onError={handleError} setTestNonce={setTestNonce} />

                        <UpdatePaymentFooterActionBar continueButton={<UpdateButton isFormSubmitting={isFormSubmitting} onClick={handleSubmit} {...buttonText} />} />

                        <PaymentDateInfo balanceDueNow={model.order.balanceDueNowSum!} />
                    </Col>
                </Row>
            </Container>
        </Form>}

    </Formik>);

}
const UpdateButton = ({ isFormSubmitting, onClick, label, busyLabel }: { isFormSubmitting: boolean, onClick: () => void, label: string, busyLabel: string }) => {


    const inner = isFormSubmitting ? <><Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
    />
        <span className="d-inline-block ms-1">{busyLabel}...</span>
    </>
        : label

    return <ActionButton variant="btn btn-primary text-white w-100" onClickAction={onClick} disabled={isFormSubmitting} style={{ minWidth: 160 }}>{inner}</ActionButton>
}

const PaymentUpdateConfirmationPage = ({ model, brandInfo }: { model: models.UpdatePaymentInfoModel, brandInfo: BrandInfo}) => {
    return <>


        <Container className="mb-3">
            <Row>
                <Col>
                    <div className="text-center mb-3">
                        <h1>Payment Information <span className="text-primary fst-italic">Updated!</span></h1>
                        <div>Your payment information has been updated and your move is now confirmed.</div>
                        {model.order.balanceDueNowSum! > 0 && <div>And we successfully processed your payment of {formatCurrency(model.order.balanceDueNowSum)}.</div>}
                        <div>Please review the details below for confirmation.</div>
                    </div>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md={5} lg={4} xxl={3} className="text-center order-md-1">
                    <img
                        srcSet={`/img/${brandInfo.abbrev}/scheduled.png 1x, /img/${brandInfo.abbrev}/scheduled2x.png 2x`}
                        sizes="(max-width: 300px) 150px, 300px"
                        src={`/img/${brandInfo.abbrev}/scheduled.png`}
                        alt="Scheduled"
                        className="img-fluid"/>
                </Col>
                <Col md={7} lg={6} xxl={5}>
                    <div className="review-box mt-4 mt-md-0">
                        <Row className="justify-content-center">
                            <Col className="small">
                                <h5 className="text-uppercase">Details:</h5>
                                <ul className="mb-0 ps-4">
                                    <li className="mb-1"><strong>Card Owner:</strong> {model.order.nameOnCard}</li>
                                    <li className="mb-1"><strong>Email:</strong> {model.order.quoteModel.contactInfo.email}</li>
                                    <li className="mb-1"><strong>Phone:</strong> {formatPhoneNumber(model.order.quoteModel.contactInfo.phone)}</li>
                                    <li className="mb-1"><strong>Billing Address:</strong> <AddressOneLine typeOfHelp={TypeOfHelpEnum.Unknown} {...model.billingAddress} /></li>
                                    <li className="mb-1"><strong>Payment Method:</strong> {PaymentMethodDescription.get(model.order.paymentMethod)}</li>
                                    <li className="mb-1"><strong>Card Number:</strong> ****{model.order.lastFour}</li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>

        <Container className="mb-3 d-md-none">
            <Row className="justify-content-center">
                <Col>
                    <div className="d-flex flex-column justify-content-center pt-4 ps-4 review-box-conditional h-100 small">
                        <Row className="mb-3">
                            <Col xs={1}><FontAwesomeIcon icon={iconLibrary.faCheck as IconProp} className="text-primary" /></Col>
                            <Col>Damage insurance included</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={1}><FontAwesomeIcon icon={iconLibrary.faCheck as IconProp} className="text-primary" /></Col>
                            <Col>Dedicated support agents</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={1}><FontAwesomeIcon icon={iconLibrary.faCheck as IconProp} className="text-primary" /></Col>
                            <Col>Reliable and safe movers</Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>

        <Container className="mb-3">
            <Row className="justify-content-center">
                <Col lg={10} xxl={8}>
                    <Card>
                        <Card.Header>
                            <FontAwesomeIcon icon={iconLibrary.faPhone as IconProp} className="me-1" flip="horizontal"/> Manage Reservation:
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                            You can easily update your information by contacting our customer support team: <a className="fw-bold text-decoration-none text-body" href={`tel:${brandInfo.cleanPhone}`}>{brandInfo.brandPhone}</a>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>;
}