// @ts-ignore
import * as enums from '~/typewriter/enums';


import { executeGet, executePost } from '../http-utils';




    export const endpointUrl = '/api/managedReview/';


    export function get(authToken: string) { return executeGet<models.CustomerManagedReviewModel>(endpointUrl + 'get/?authToken=' + (authToken ? encodeURIComponent(authToken) : '')); }
    

    
    export function review(model: models.CustomerManagedReviewModel) { return executePost<models.DefaultCommandResult>(endpointUrl + 'review/', model); }
    


