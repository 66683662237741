import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { CheckoutContext } from '../checkout/CheckoutContext';
import { OnChangePickSetState } from '../hooks';
import { TransportOptionType } from '../typewriter/enums';
import { iconLibrary } from './Icon';

interface Props {

  value: models.TransportOptionRef;
  onChange: OnChangePickSetState<models.TransportOptionRef>;
  transportationType: TransportOptionType;
  selected: boolean,
  onSelect: (type: TransportOptionType) => void;
  options: models.TransportOptionBasic[];


}

const defaultQuantityOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
]

const reloCubeQuantityOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
]

export const TransportationCard = ({ transportationType, selected, value, onChange, onSelect, options }: Props) => {
const { model: { affiliateInfo } } = useContext(CheckoutContext);
  const { iconToUse, title, subTitle, quantityOptions } = getTransportTypeProps(transportationType)

  const handleSelectCard = React.useCallback(() => {

    if (!selected) {
      onSelect(transportationType);

      if (options.length == 1) {
        onChange(prev => ({ transportOptionID: options[0].transportOptionId, quantity: prev.quantity || 1 }));
      }
    }
  }, [onChange, onSelect, options, selected, transportationType]);

  if (!title) {
    return null;
  }

  return <Col xs={12}>
    <div className={`transportation-card bg-white m-2 border rounded px-3 py-4 ${selected ? 'selected border-primary shadow' : ''}`} onClick={handleSelectCard}>

    <Row className='align-items-center text-body gx-3'>
        <Col xs={'auto'} className={`${selected ? 'mb-3 mb-lg-0' : ''}`}>
            <Form.Check.Input required name='transportationType' value={transportationType} type='radio' checked={selected} className="" id={`transportationType-${transportationType}`} onChange={handleSelectCard}/>
        </Col>
        <Col xs={'auto'} className={`${selected ? 'mb-3 mb-lg-0' : ''}`}>
            <span className="fa-layers" style={{width: '48px'}}>
                <FontAwesomeIcon icon={iconLibrary.faCircle as IconProp} className={`${selected ? 'text-primary' : 'text-white'}`} size="3x"></FontAwesomeIcon>
                <FontAwesomeIcon icon={iconToUse as IconProp} color='#9F9E9A' size='lg' className={`${selected ? 'text-white' : ''}`}></FontAwesomeIcon>
            </span>
        </Col>
        <Col xs xl={6} className={`${selected ? 'mb-3 mb-lg-0' : ''}`}>
            <Row className='align-items-center text-body gx-3'>
                <Col xl={5}>
                    <h3 className="fw-bold d-inline-block fs-5 mb-0">{title}</h3>
                </Col>
                <Col xl>
                    <p className='mb-0'>{subTitle}</p>
                </Col>
            </Row>
        </Col>
        {affiliateInfo && affiliateInfo.logoUrl && transportationType == TransportOptionType.Container && <Col xs='auto'>
            <img alt={affiliateInfo.publicName} src={affiliateInfo.logoUrl} className='d-lg-none mb-3' style={{ maxHeight: '40px', maxWidth: '85px' }} />
        </Col>}
        {
            selected && transportationType !== TransportOptionType.None && <>
                <Col xs={10} lg className={`${selected ? 'mb-3 mb-lg-0 mx-auto' : ''}`}>
                    {options.length === 1 ? <div>{options[0].displayName}</div>
                        :
                        <Select id={`transportation-${transportationType}-size-select`} placeholder="Transportation Size" options={options} getOptionLabel={v => v.displayName}
                        getOptionValue={v => v.transportOptionId.toString()}
                        value={options.find(m => m.transportOptionId === value?.transportOptionID)}
                        onChange={(v) => onChange(prev => ({ transportOptionID: v?.transportOptionId as number, quantity: prev?.quantity ?? 1 }))}
                        aria-label="Transportation Size"
                        />
                    }
                </Col>
                { quantityOptions &&
                    <Col xs={10}  lg={'auto'} className='mx-auto'>
                        <Select placeholder="Amount" options={quantityOptions} value={quantityOptions.find(m => m.value === value?.quantity)} onChange={(v) => onChange({ quantity: v?.value || 1 })} aria-label="Transportation Quantity" />
                    </Col>
                }
            </>
        }
    </Row>

    </div>
  </Col>;
}

type QuantityOptions = {
  value: number;
  label: string;
}
type TransportTypeProps = {
  subTitle: string;
  title: string;
  iconToUse?: IconDefinition;
  quantityOptions?: QuantityOptions[];
}

function getTransportTypeProps(transportationType: TransportOptionType): TransportTypeProps {
  switch (transportationType) {
    case TransportOptionType.ReloCube:
      return {
        subTitle: 'One Size',
        title: 'ReloCube',
        iconToUse: iconLibrary.faCube,
        quantityOptions: reloCubeQuantityOptions,
      }
    case TransportOptionType.FreightTrailer:
      return {
        subTitle: '5 Linear Feet - 53 Linear feet',
        title: 'Freight Trailer',
        iconToUse: iconLibrary.faTrailer,
      }
    case TransportOptionType.Container:
      return {
        subTitle: '8FT / 12FT / 16FT',
        title: 'Container',
        iconToUse: iconLibrary.faContainerStorage,
        quantityOptions: defaultQuantityOptions,
      }
    case TransportOptionType.TruckSizeRange:
      return {
        subTitle: '10\' - 13\', 14\' -19\', 20\' - 26\'',
        title: 'Rental Truck',
        iconToUse: iconLibrary.faTruckMoving,
        quantityOptions: defaultQuantityOptions,
      }
    case TransportOptionType.None:
      return {
        subTitle: 'Rearranging on site / not sure yet',
        title: 'No Transport',
        iconToUse: iconLibrary.faQuestion,
      }

    default: {
      return {
        title: '',
        subTitle: '',
      }
    }
  }
}