import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { CheckoutLayout } from './checkout/CheckoutLayout';
import { BrandedNav } from './components';
import { ManagedReview } from './review/ManagedReview';
import { CompleteBooking } from './pages/CompleteBooking';
import { OrderConfirmation } from './pages/OrderConfirmation';
import { UpdatePaymentInfo } from './pages/UpdatePaymentInfo';
import { BrandType } from './typewriter/enums';
import { BrandContext } from './modules/BrandContext';
import { TermsOfServicePage } from './pages/TermsOfServicePage';

export const Layout = () => {
    const brandInfo = React.useContext(BrandContext);
    return <div className={brandInfo.abbrev} role="main">
        <ToastContainer position="bottom-center" autoClose={5000} hideProgressBar newestOnTop closeOnClick pauseOnFocusLoss draggable pauseOnHover className={'mb-5'} />

        <Switch>
            <Redirect path="/" to="/checkout/" exact />
            <Route path="/checkout/" render={() => <CheckoutLayout  />} />
            <Route path="/order-confirmation/" render={() => <OrderConfirmation  />} />
            <Route path="/complete-booking/" render={() => <CompleteBooking  />} />
            <Route path="/update-payment-info/" render={() => <UpdatePaymentInfo />} />
            <Route path="/review/" render={() => <ManagedReview />} />
            <Route path="/tos/" render={() => <TermsOfServicePage />} />
        </Switch>
    </div>;
}