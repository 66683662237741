import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as React from 'react';
import { iconLibrary } from '../../components';
import { formatDate } from '../../components/DateTimeDisplays';
import { RequireAtLeastOne } from '../../hooks';
import { TypeOfHelpEnumDescription } from '../../typewriter/enums';

interface JobReviewProps {
    editMode: boolean;
    model: models.ManagedReviewJobModel;
    onChange: (newValue: RequireAtLeastOne<models.ManagedReviewJobModel>) => void;
}


function getRatingLabel(jobRating: number) {
    switch (jobRating) {
        case 1:
            return 'Very Poor';
        case 2:
            return 'Ok';
        case 3:
            return 'Regular';
        case 4:
            return 'Good';
        case 5:
            return 'Excellent!!';
    }
}

const textColor = classNames('section-arrow', 'starIcon', 'text-primary');

export const JobReview = (props: JobReviewProps) => {

    const { editMode, model, onChange } = props;
    const { customerRating, customerReview, jobDate, startTime, typeOfHelp, city, state } = model;

    const [jobRating, setJobRating] = React.useState<number>(customerRating);
    const ratingText = getRatingLabel(jobRating);

    const icons = React.useMemo(() => {

        const arr = [];

        for (let i = 0; i < 5; i++) {
            arr.push(i >= jobRating ? iconLibrary.farStar : iconLibrary.faStar);
        }
        return arr;
    }, [jobRating]);

    const handleRatingChange = React.useCallback((value: number) => {
        onChange({ customerRating: value })

        setJobRating(value);
    }, [onChange]);

    const handleReviewChange = React.useCallback((value: string) => {

        onChange({ customerReview: value })

    }, [onChange]);

    return <div className='row'>
        <div className='col-12 mt-3'>
            <h5 className='text-secondary'>How would you rate your <span className='text-primary'>{TypeOfHelpEnumDescription.get(typeOfHelp)}</span> experience?</h5>
            <p>Your Job on {formatDate(jobDate)} at {city}, {state}</p>
        </div>
        <div className='col-lg-6'>
            <div className='row row-cols-6 row-cols-lg-6 g-1 g-lg-1 justify-content-center text-center'>
                {icons.map((v, i) => {
                    return <div key={i} className='col'
                        onMouseLeave={() => editMode ? setJobRating(customerRating) : null}
                        onMouseOver={() => editMode ? setJobRating(i + 1) : null}
                        onClick={() => editMode ? handleRatingChange(i + 1) : null}>
                        <FontAwesomeIcon className={textColor} icon={v as IconProp} />
                        <p className='mt-2'>{i + 1}</p>
                    </div>;
                })}
                <div className='col'>
                    <p className={textColor}>{ratingText}</p>
                </div>
            </div>
        </div>

        <div className='col-12 mt-4' style={{ whiteSpace:'pre-line' }} >
            <p className='fw-bold text-secondary'>Additional Comments:</p>
            {editMode
                ? <textarea title='Please enter some additional comments about your move, if desired.' className='form-control' id="reviewComments" value={customerReview} rows={4} maxLength={5000} onChange={v => handleReviewChange(v.currentTarget.value)}></textarea>
                : <p className='text-muted'>{customerReview || '(None)'}</p>}
            {customerReview.length > 100 && editMode && <p className='text-end text-danger text-muted'><small>{customerReview.length}/5000</small></p>}
        </div>
    </div>;
};
