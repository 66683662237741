import React from 'react';
import { useHistory } from 'react-router-dom';
import { get } from '../typewriter/controllers/OrderController';
import { useQueryStringParam } from './useQueryStringParam';
import { handleTokenErrorResponse } from './handleTokenErrorResponse';

export function useOrderModel() {
    const [model, setModel] =
        React.useState<models.BookingSubdomainOrderCollectionModel>();

    const [loading, setLoading] = React.useState(true);

    const { replace } = useHistory();

    const token = useQueryStringParam('token');

    React.useEffect(() => {
        if (token) {
            setLoading(true);

            console.debug('PaymentPage React.useEffect');

            (async () => {
                try {
                    const result = await get(token);
                    setModel(result);
                    setLoading(false);
                } catch (error: any) {
                    setLoading(false);
                    replace(handleTokenErrorResponse(error, token, 'order get'));
                }
            })();
        }
    }, [replace, setLoading, token]);

    return { authToken: token, model, loading, setLoading };
}
