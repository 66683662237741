import 'flatpickr/dist/themes/light.css';
import { useField, useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import { arrivalWindows, MoveDetailsModel } from '../modules';
import { JobDetailsTypeOfHelpEnum, JobDetailsTypeOfHelpEnumDescription } from '../typewriter/enums';
import { HahFormikSelectField } from './HahFormikField';

interface MoveDateAndTimeProps {
    typeOfHelp: JobDetailsTypeOfHelpEnum;
    parentName: string;
}
export const MoveDateAndTime = ({ typeOfHelp, parentName }: MoveDateAndTimeProps) => {

    const [{ value }, { error, touched }, { setValue }] = useField(parentName + '.jobDate');

    const minDatePicker = DateTime.now().plus({ days: 2 }).toISODate();
    {
        // future: add serverside validation..?
    }
    return (
        <div>
            <FloatingLabel label={JobDetailsTypeOfHelpEnumDescription.get(typeOfHelp) + ' Date'} className="mb-3">
                <Flatpickr
                className='form-control'
                data-enable-time={false}
                value={value}
                options={{
                    inline: false,
                    minDate: minDatePicker as string, //to prevent same or next day orders.
                    enableTime: false,
                    allowInvalidPreload: true // Because IOS doesn't allow minDate... this allows iOS users to select a previous date and not have the field look empty https://github.com/flatpickr/flatpickr/issues/1224
                }}
                onChange={(_, currentDate) => {
                    setValue(currentDate);
                }} />
                <Form.Control type="hidden" required name={parentName + '.jobDate'} isInvalid={touched && !!error} />
                <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            </FloatingLabel>
            <HahFormikSelectField className="mb-3" parentName={parentName} name="desiredArrivalWindow" label="Arrival Window" options={arrivalWindows} />
        </div>
    );
}
