import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { getTrackBackground, Range } from 'react-range';
import { CheckoutContext } from '../checkout/CheckoutContext';
import { BrandType } from '../typewriter/enums';
import { Icon, iconLibrary } from './Icon';
import { HahFormikSelectField } from './HahFormikField';

export const HeavyItems = () => {

    const { brandInfo } = React.useContext(CheckoutContext);

    const [anyHeavyItemField, anyHeavyItemMeta, anyHeavyItemHelpers] = useField('heavyItems.anyHeavyItems');

    // Note: Can we clean this up somehow..
    const handleChangeHeavyItems = React.useCallback((v: boolean) => {
        anyHeavyItemHelpers.setValue(v, false);
        anyHeavyItemHelpers.setTouched(true, false);
        anyHeavyItemHelpers.setError(undefined);
    }, [anyHeavyItemHelpers]);

    return <>
        <div className='mb-4' id='heavy-items'>
            <Row className='align-items-center'>
                <Col xs={9} md={8}>
                    <div className='mb-4'>
                        <h3 className="fw-bold d-inline-block fs-5 mb-3">Need help with any items over 300 lbs?</h3>
                        <p>This helps your provider to come prepared to handle all items carefully, and safely.</p>
                    </div>
                    <div className="row align-items-baseline" id="heavyItemsRow">
                        <div className="col">
                            <Form.Check.Label className={`border heavy-items-card w-100 mb-3 bg-white py-3 text-start btn btn-link ${anyHeavyItemField.value === true ? 'active' : ''} ${anyHeavyItemMeta.touched && !!anyHeavyItemMeta.error ? 'border-danger' : ''}`} id="heavyItemsCardYes">
                                <div className="align-items-center text-body gx-3 row">
                                    <div className="col-auto">
                                        <Form.Check.Input type='radio' required name="heavyItems.anyHeavyItems" checked={anyHeavyItemField.value === true} defaultValue={anyHeavyItemField.value} isInvalid={anyHeavyItemMeta.touched && !!anyHeavyItemMeta.error} onChange={() => handleChangeHeavyItems(true)}  />
                                    </div>
                                    <div className="col-xxl-3 col-xl-4 col">
                                        <h3 className="fw-semibold d-inline-block fs-6 mb-0 align-middle">
                                            <FontAwesomeIcon icon={iconLibrary.faCheckCircle as IconProp} className='me-2'></FontAwesomeIcon>Yes
                                        </h3>
                                    </div>
                                </div>
                            </Form.Check.Label>
                            <Form.Check.Label className={`border heavy-items-card w-100 mb-3 bg-white py-3 text-start btn btn-link ${anyHeavyItemField.value === false ? 'active' : ''} ${anyHeavyItemMeta.touched && !!anyHeavyItemMeta.error ? 'border-danger' : ''}`} id="heavyItemsCardNo">
                                <div className="align-items-center text-body gx-3 row">
                                    <div className="col-auto">
                                        <Form.Check.Input type='radio' required name="heavyItems.anyHeavyItems" checked={anyHeavyItemField.value === false} defaultValue={anyHeavyItemField.value} isInvalid={anyHeavyItemMeta.touched && !!anyHeavyItemMeta.error} onChange={() => handleChangeHeavyItems(false)}  />
                                    </div>
                                    <div className="col-xxl-3 col-xl-4 col">
                                        <h3 className="fw-semibold d-inline-block fs-6 mb-0 align-middle">
                                            <FontAwesomeIcon icon={iconLibrary.faBan as IconProp} className='me-2'></FontAwesomeIcon>No
                                        </h3>
                                    </div>
                                </div>
                            </Form.Check.Label>
                        </div>
                    </div>
                    <Form.Control.Feedback type="invalid" className={`mb-2 ${anyHeavyItemMeta.touched && !!anyHeavyItemMeta.error ? 'd-block' : ''}`}>{anyHeavyItemMeta.error}</Form.Control.Feedback>
                </Col>
                <Col className='text-end text-md-center'>
                {(anyHeavyItemField.value) && <>
                        <FontAwesomeIcon icon={iconLibrary.faCheckCircle as IconProp} className='text-success heavy-item-check me-md-4'></FontAwesomeIcon>
                    </>}
                    <img srcSet={`/img/${brandInfo.abbrev}/heavy-items.png 1x, /img/${brandInfo.abbrev}/heavy-items2x.png 2x`} alt='Box' width="69" height="64" className='d-none d-md-inline-block'/>
                </Col>
            </Row>
            {anyHeavyItemField.value && <div className=''>
                <HeavyItemsSelects />
            </div>}
        </div>
    </>;
};

const HeavyItemSelectField = ({ label, name }: { label: string, name: string }) => (
    <div className="col-auto col-lg-2 mt-3 my-lg-0">
        <label className="fw-semibold mb-1">{label}</label>
        <div>
            <HahFormikSelectField parentName='heavyItems' className="mb-3" name={name} options={[
                { label: '0', value: 0 },
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 },
                { label: '5', value: 5 },
            ]} />
        </div>
    </div>
);

const HeavyItemsSelects = () => (
    <>
        <div className="row align-items-center small d-flex" id="heavyItemsInput">
            <div className="col-12">
                <div className="text-muted mb-2">Please specify the type of heavy items you have:</div>
            </div>
            <HeavyItemSelectField label="300-450 lbs?" name="heavyItemQuantities.heavyItemsBetweenThreeAndFourFiftyQty" />
            <HeavyItemSelectField label="450-600 lbs?" name="heavyItemQuantities.heavyItemsBetweenFourFiftyAndSixQty" />
            <HeavyItemSelectField label="Over 600 lbs?" name="heavyItemQuantities.heavyItemsSixPlusQty" />
            <HeavyItemSelectField label="Baby/Grand Pianos?" name="heavyItemQuantities.heavyItemsBabyGrandPianosQty" />
            <HeavyItemSelectField label="Upright Pianos?" name="heavyItemQuantities.heavyItemsUprightPianosQty" />
        </div>
    </>
);