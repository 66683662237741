import { DateTime } from 'luxon';
import { BraintreeFieldValueValid } from 'react-braintree-fields';
import * as Yup from 'yup';
import { AddressType, ClaimType, JobDetailsTypeOfHelpEnum, TypeOfHelpEnum } from '../typewriter/enums';
import { phoneRegex } from './validation';
import isEmailValidator from 'validator/lib/isEmail';

export interface AddressBasic {
    street: string;
    city: string;
    zip: string;
    streetLineTwo?: string | undefined;
    state: string;
}

export interface LocationValidationModel extends AddressBasic {
    jobDate?: string | undefined;
    desiredArrivalWindow?: string | undefined;
    sqFootage?: number | undefined;
    stairs?: number | undefined;
    type?: AddressType | undefined;
    typeOfHelp: TypeOfHelpEnum;
}
export interface MoveDetailsModel extends Pick<models.PublicBookingSubdomainQuoteModel, 'moveDescription' | 'heavyItems' | 'typeOfHelp'> {
    loadingLocation?: LocationValidationModel;
    unloadingLocation?: LocationValidationModel;
}

export interface PaymentInfoModel {
    //nonce: string;

    address: AddressBasic;

    cardholderName?: string;
    cardNumber?: string;
    expirationDate?: string;
    cvv?: string;

}
export interface BookOrderValidationModel extends Omit<models.BookingSubdomainBookOrderModel, 'authToken' | 'tokenType' | 'paymentInfo' | 'terms' | 'orderTotal' | 'balanceDueNow'> {
    paymentInfo: PaymentInfoModel;
}
export interface CompleteOrderValidationModel extends Omit<models.BookingSubdomainBookOrderModel, 'authToken' | 'tokenType' | 'paymentInfo' | 'terms' | 'orderTotal' | 'balanceDueNow'> {

    paymentInfo: PaymentInfoModel;
    terms?: Omit<models.ManagedSignTermsModel, 'termsVersion'>;

}

export interface UpdatePaymentInfoValidationModel extends Omit<models.SubmitUpdatePaymentInfoModel, 'authToken' | 'paymentInfo' | 'balanceDueNow'> {
    paymentInfo: PaymentInfoModel;
}

const paymentInfoValidation: Yup.ObjectSchema<PaymentInfoModel> = Yup.object({
    address: Yup.object({
        street: Yup.string().notRequired(),
        city: Yup.string().notRequired(),
        zip: Yup.string()
            .matches(/^[0-9]+$/, 'Zip Code must be only digits')
            .min(5, 'Zip Code must be exactly 5 digits')
            .max(5, 'Zip Code must be exactly 5 digits')
            .required('Zip Code required'),
        streetLineTwo: Yup.string().notRequired(),
        state: Yup.string().notRequired(),
    }),
    cardholderName: Yup.string()
        .test('Cardholder Name', 'Cardholder Name Required', o => o != BraintreeFieldValueValid),
    cardNumber: Yup.string()
        .test('Card Number', 'Card Number Required', o => o != BraintreeFieldValueValid),
    expirationDate: Yup.string()
        .test('Exp Date', 'Exp Date Invalid', o => o != BraintreeFieldValueValid),
    cvv: Yup.string()
        .test('CVV', 'CVV Invalid', o => o != BraintreeFieldValueValid)

}) as any;

export const bookOrderValidationSchema: Yup.ObjectSchema<BookOrderValidationModel> = Yup.object({
    paymentInfo: paymentInfoValidation,
});

export const completeOrderValidationSchema: Yup.ObjectSchema<CompleteOrderValidationModel> = Yup.object({
    paymentInfo: paymentInfoValidation,
    terms: Yup.object({
        signatureName: Yup.string()
            .required('Please enter your signature'),
        agreedToTerms: Yup.bool().required().isTrue('Accept Terms is required'),
    }),
});

export const updatePaymentInfoValidationSchema: Yup.ObjectSchema<UpdatePaymentInfoValidationModel> = Yup.object({
    paymentInfo: paymentInfoValidation
});

const optionalLocationSchema: Yup.ObjectSchema<LocationValidationModel> = Yup.object({
    type: Yup.number().notRequired(),
    sqFootage: Yup.number().notRequired(),
    stairs: Yup.number().notRequired(),
    street: Yup.string().notRequired(),
    city: Yup.string().notRequired(),
    zip: Yup.string().notRequired(),
    streetLineTwo: Yup.string().notRequired(),
    state: Yup.string().notRequired(),
    jobDate: Yup.string().notRequired(),
    desiredArrivalWindow: Yup.string().notRequired(),
}) as any; // Lots of as any because strongly typed Yup object models vs possibly undefined values

const locationSchema: Yup.ObjectSchema<LocationValidationModel> = Yup.object({
    type: Yup.number()
    .required('Please select a type of residence')
    .test('test_type', '', function (value) {
        // your condition
        if (!value) {
            // setting the error message using the value's length
            return this.createError({ message: 'Please select a location type' })
        }
        return true;
    }),
    sqFootage: Yup.number()
        .required('Please let us know the approximate square footage')
        .test('test_sqFtvalue', '', function (value) {
            // your condition
            if (!value) {
                // setting the error message using the value's length
                return this.createError({ message: 'Please select an approximate square footage' })
            }
            return true;
        }),
    stairs: Yup.number()
        .typeError('Please select if you have stairs or not')
        .required('Please let us know if you have stairs or not')
        .test('test_stairs', '', function (value) {
            // your condition
            if (value === undefined) {
                // setting the error message using the value's length
                return this.createError({ message: 'Please select if you have stairs or not' })
            }
            return true;
        }),
    street: Yup.string()
        .min(2, 'Street Address must be at least 2 characters')
        .max(35, 'Street Address has a maximum of 35 characters')
        .required('Street Address Required'),
    city: Yup.string()
        .max(20, 'City has a maximum of 20 characters')
        .required('City Required'),
    zip: Yup.string()
        .matches(/^[0-9]+$/, 'Zip Code must be only digits')
        .min(5, 'Zip Code must be exactly 5 digits')
        .max(5, 'Zip Code must be exactly 5 digits')
        .required('Zip Code required'),
    streetLineTwo: Yup.string()
        .max(20, 'Suite/Apt # must be 20 characters or less')
        .notRequired(),
    state: Yup.string()
        .max(2, 'Please select a State')
        .required('Please select a State'),
    jobDate: Yup.string()
        .max(20)
        .required('Please select a Job Date')
        .test('jobDate', '', function (value: string) {
            // your condition
            if (!value) {
                // setting the error message using the value's length
                return this.createError({ message: 'Please select a job date.' })
            }
            else {
                const minDate = DateTime.now().plus({ days: 2 }).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                const selectedDate = DateTime.fromISO(value);
                if (selectedDate < minDate) {
                    return this.createError({ message: 'Please select a date at least 48 hours in the future' })
                }
                return true;
            }
        }),
    desiredArrivalWindow: Yup.string()
        .max(50, 'Please select a desired Arrival Window')
        .required('Please select a desired Arrival Window'),
}) as any;
export const moveDetailsValidationSchema: Yup.ObjectSchema<MoveDetailsModel> = Yup.object().shape({
    typeOfHelp: Yup.number()
        .required('Type of Help Required')
        .test('test_type', '', function (value) {
            // your condition
            if (!value) {
                // setting the error message using the value's length
                return this.createError({ message: 'Please select loading and/or unloading' })
            }
            return true;
        }),
    loadingLocation: Yup.object().when('typeOfHelp', ([typeOfHelp], schema) => {
        return typeOfHelp == JobDetailsTypeOfHelpEnum.Loading || typeOfHelp == JobDetailsTypeOfHelpEnum.LoadingAndUnloading ? locationSchema : optionalLocationSchema
    }),
    unloadingLocation: Yup.object().when('typeOfHelp', ([typeOfHelp], schema) => {
        return typeOfHelp == JobDetailsTypeOfHelpEnum.Unloading || typeOfHelp == JobDetailsTypeOfHelpEnum.LoadingAndUnloading ? locationSchema : optionalLocationSchema
    }),
    heavyItems: Yup.object({
        anyHeavyItems: Yup.bool()
            .required('Please select whether or not you have heavy items'),
    }),
    moveDescription: Yup.string()
    .max(500, 'Move Details must be 500 characters or less')
    .notRequired(),
}) as any;

export const contactValidationSchema: Yup.ObjectSchema<models.ContactInfo> = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'First Name is too short')
        .max(50, 'First Name is too long')
        .required('First Name is required'),
    lastName: Yup.string()
        .min(2, 'Last Name is too short')
        .max(50, 'Last Name is too long')
        .required('Last Name is required'),
    phone: Yup.string()
        .matches(phoneRegex, 'Phone Number appears invalid')
        .required('Phone Number is required'),
    email: Yup
        .string()
        .email('Email Address appears invalid')
        .required('Email Address is required')
        .test('is-valid', (message) => 'Email Address appears invalid', (value) => value ? isEmailValidator(value) : new Yup.ValidationError('Email Address appears invalid')),
});

export const typeOfHelpAndContactValidationSchema: Yup.ObjectSchema<Pick<models.BookingSubdomainQuoteModelBase, 'contactInfo' | 'typeOfHelp'>> = Yup.object().shape({
    contactInfo: contactValidationSchema,
    typeOfHelp: Yup.number()
        .required('Type of Help Required')
        .test('test_type', '', function (value) {
            // your condition
            if (!value) {
                // setting the error message using the value's length
                return this.createError({ message: 'Please select loading and/or unloading' })
            }
            return true;
        }),
});
