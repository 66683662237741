import React from 'react';
import { Container } from 'react-bootstrap';
import { BrandContext } from '../modules/BrandContext';
import { useTos } from '../hooks/useTos';
import { BrandedNav } from '../components';
import { TransientAuthType } from '../typewriter/enums';
import { useCheckoutContextProvider } from '../hooks/useCheckoutContextProvider';

export const TermsOfServicePage = () => {
    const brandInfo = React.useContext(BrandContext);
    const { model } = useCheckoutContextProvider({ brand: brandInfo.brand, tokenType: TransientAuthType.CcAuth });

    React.useEffect(() => {
        if (model?.brandType && brandInfo.brand != model.brandType) {
            brandInfo.setBrandType(model.brandType);
        }
    }, [brandInfo, brandInfo.brand, model?.brandType]);
    
    document.title = 'Terms of Service';
    const tos = useTos();

    return (
        <div>
            <BrandedNav brandInfo={brandInfo} shouldShowOffCanvas={false} />
            <Container className="pb-3 body-container">
                {tos && <div dangerouslySetInnerHTML={{ __html: tos.htmlString }}></div>}
            </Container>
        </div>
    );
}