import { BrandType } from '../typewriter/enums';

// FUTURE: this really should come from our server. inside the initial HTML request if possible, otherwise, in a "settings" type API call at start of the SPA. But we have this centralized for a reason/benefit. Get this data into the product/brand info, and then serialize that and call it a day.

const brands = [
    {
        brand: BrandType.SmlMarketplace,
        companyName: 'Simple Moving Labor',
        abbrev: 'sml',
        icon: 'sml-favicon.ico',
        logoUrl: '/img/logos/sml-marketplace-stacked-color-logo.png',
        brandPhone: '1 (866) 767-5348',
        cleanPhone: '1-866-767-5348',
        gtmId: 'GTM-TP26GJX',
        addressLine1: '3700 Rufe Snow Dr.',
        addressLine2: 'Ft. Worth, TX 76180',
        phoneNumbers: ['(866) 767-5348', '(866) 767-5348']
    },
    {
        brand: BrandType.MsMarketplace,
        companyName: 'Moving Staffers',
        abbrev: 'ms',
        icon: 'ms-favicon.ico',
        logoUrl: '/img/logos/movingstaffers-marketplace-stacked-color-logo.png',
        brandPhone: '1 (800) 658-7292',
        cleanPhone: '1-800-658-7292',
        gtmId: 'GTM-KMZSFDP',
        addressLine1: 'P.O. Box 8367',
        addressLine2: 'Wichita Falls, TX 76307-8367',
        phoneNumbers: ['(800) 570-2052', '(800) 658-7292']
    },
    {
        brand: BrandType.SimpleMovingLabor,
        companyName: 'Simple Moving Labor',
        abbrev: 'sml',
        icon: 'sml-favicon.ico',
        logoUrl: '/img/sml-logo.png',
        brandPhone: '1 (866) 767-5348',
        cleanPhone: '1-866-767-5348',
        gtmId: 'GTM-TP26GJX',
        addressLine1: '3700 Rufe Snow Dr.',
        addressLine2: 'Ft. Worth, TX 76180',
        phoneNumbers: ['(866) 767-5348', '(866) 767-5348']
    },
    {
        brand: BrandType.MovingStaffers,
        companyName: 'Moving Staffers',
        abbrev: 'ms',
        icon: 'ms-favicon.ico',
        logoUrl: '/img/ms-logo.png',
        brandPhone: '1 (800) 658-7292',
        cleanPhone: '1-800-658-7292',
        gtmId: 'GTM-KMZSFDP',
        addressLine1: 'P.O. Box 8367',
        addressLine2: 'Wichita Falls, TX 76307-8367',
        phoneNumbers: ['(800) 570-2052', '(800) 658-7292']
    }
];


export function getBrandFromHost(host: string, brandType?: BrandType): BrandInfo {
    if (brandType) {
        const result = brands.find(b => b.brand === brandType);
        if (result) {
            return result;
        }
    }

    if (host.match(/simplemovinglabor\.com/i)) {
        return brands.find(b => b.brand === BrandType.SmlMarketplace)!;
    }
    if (host.match(/movingstaffers\.com/i)) {
        return brands.find(b => b.brand === BrandType.MsMarketplace)!;
    }
    return {
        brand: BrandType.Unknown,
        companyName: '',
        abbrev: 'unknown',
        icon: '',
        logoUrl: '',
        brandPhone: '',
        cleanPhone: '',
        gtmId: '',
        addressLine1: '',
        addressLine2: '',
        phoneNumbers: []
    };
}
