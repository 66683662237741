import { AddressType } from '../typewriter/enums/AddressType';

export const sqftSizeOptions = [
    { label: 'Select...', value: 0, load: 0, unload: 0 },
    { label: '500 sqft', value: 500, load: 4, unload: 4 },
    { label: '750 sqft', value: 750, load: 4, unload: 4 },
    { label: '1000 sqft', value: 1000, load: 6, unload: 4 },
    { label: '1250 sqft', value: 1250, load: 12, unload: 9 },
    { label: '1500 sqft', value: 1500, load: 12, unload: 9 },
    { label: '1750 sqft', value: 1750, load: 12, unload: 12 },
    { label: '2000 sqft', value: 2000, load: 16, unload: 12 },
    { label: '2500 sqft', value: 2500, load: 20, unload: 16 },
    { label: '3000 sqft', value: 3000, load: 20, unload: 16 },
    { label: '3500 sqft', value: 3500, load: 24, unload: 16 },
    { label: '4000 sqft', value: 4000, load: 28, unload: 20 },
];

export const homeSizeOptions = [
    { type: AddressType.CondoApt, bedrooms: 2, load: 6, unload: 4 },
    { type: AddressType.CondoApt, bedrooms: 3, load: 6, unload: 6 },
    { type: AddressType.CondoApt, bedrooms: 6, load: 8, unload: 6 },
    { type: AddressType.CondoApt, bedrooms: 8, load: 12, unload: 8 },
    { type: AddressType.CondoApt, bedrooms: 10, load: 16, unload: 12 },
    { type: AddressType.House, bedrooms: 1, load: 4, unload: 4 },
    { type: AddressType.House, bedrooms: 2, load: 6, unload: 4 },
    { type: AddressType.House, bedrooms: 3, load: 8, unload: 6 },
    { type: AddressType.House, bedrooms: 4, load: 12, unload: 8 },
    { type: AddressType.House, bedrooms: 5, load: 16, unload: 12 },
    { type: AddressType.House, bedrooms: 6, load: 24, unload: 16 },
    { type: AddressType.House, bedrooms: 10, load: 32, unload: 24 },
];
