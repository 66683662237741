import * as React from 'react';
import { formatCurrency } from '../utils/formatting';



export const PaymentDateInfo = ({ balanceDueNow }: { balanceDueNow: number }) => {


    if (balanceDueNow > 0) {

        return <div className={'mb-3 p-2 payment-callout rounded text-center'}>
            <p className="mb-0 small">Your card <strong>WILL</strong> be charged {formatCurrency(balanceDueNow)} at this time.</p>
        </div>;

    }

    return <div className={'mb-3 p-2 payment-callout rounded text-center'}>
        <p className="mb-0 small">Your card <strong>WILL NOT</strong> be charged at this time. We will charge your card three (3) days prior to your booked move date.</p>
    </div>;
};
