import * as React from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { BrandedNav } from '../components';
import { usePageTitle } from '../hooks';
import { useManagedReviewModel } from '../hooks/useManagedReviewModel';
import { AppContainer } from './components/AppContainer';
import { AppHeader } from './components/AppHeader';
import { TokenExpired } from './components/TokenExpired';
import './styles/app.scss';
import { BrandContext } from '../modules/BrandContext';


export const ManagedReview = () => {
    const brandInfo = React.useContext(BrandContext);
    const { authToken, model, loading, editMode, tokenExpired, setEditMode, onUpdate } = useManagedReviewModel();

    React.useEffect(() => {
        if (model?.brandType && brandInfo.brand != model.brandType) {
            brandInfo.setBrandType(model.brandType);
        }
    }, [brandInfo, brandInfo.brand, model?.brandType]);

    usePageTitle(`Review for Order #${model?.orderId}`, brandInfo.companyName);

    if (authToken && !model && loading) {
        return <Container className='text-center mt-5'>
            <Spinner animation='border' />
            <div>Please wait... Loading</div>
        </Container>
    }

    if (!authToken || !model) {
        return <Container className="pb-3 body-container">
            <h2 className={'text-center fw-bold text-secondary mb-4 mb-lg-5'}>Sorry, there seems to be a problem with the link you are attempting to access.</h2>
        </Container>;
    }

    if (tokenExpired) {
        return <TokenExpired orderId={model.orderId} customerName={model.customerName} jobs={model.jobs} />
    }

    return <>
        <BrandedNav brandInfo={brandInfo} shouldShowOffCanvas={false} />
        <AppHeader editMode={editMode} orderId={model.orderId} setEditMode={setEditMode} />

        <div className='container-fluid app-container mb-5'>
            <div className='row'>
                <div className='col-12'>
                    <AppContainer model={model} editMode={editMode} onUpdate={onUpdate} loading={loading} />
                </div>
            </div>
        </div>
    </>;
};
