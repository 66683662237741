import classNames from 'classnames';
import * as React from 'react';

interface AppHeaderProps {
    editMode: boolean;
    orderId: number;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}
export const AppHeader = (props: AppHeaderProps) => {

    const { editMode, orderId, setEditMode } = props;
    const appHeaderClassNames = classNames(editMode ? 'bg-primary header-image' : null);

    let headerContent;

    if (editMode) {
        headerContent = <div className='row align-items-center'>
            <div className='col-12 g-0 d-block d-lg-none mb-3'>
                <img src='img/review/header-image.png' className='img-fluid w-100' alt='A blurry background showing a couple with moving boxes.' />
            </div>

            <div className='col-12 col-lg-6 text-white align-middle'>
                <h2 className='text-white'>Review for order #{orderId}:</h2>
                <p>Verified reviews from people who've had your exact experience are what we crave.</p>
                <p>Please rate the following statements from 1 - 5 stars, with 1 being "<strong>Very Poor</strong>" and 5 being "<strong>Excellent!</strong>".</p>
            </div>

            <div className='col-6 g-0 d-none d-lg-block'>
                <img src='img/review/header-image-bordered.png' className='img-fluid w-100' alt='A blurry background showing a couple with moving boxes, cropped.' />
            </div>
        </div>;
    } else {
        headerContent = <div className='row align-items-center mt-3 mb-3'>
            <div className='text-center'>
                <div className='mb-4'>
                    <img className='img-fluid' src='img/review/message-icon.png' alt='Iconography of a conversation "bubble" with three dots.' />
                </div>
                <div className='mb-4'>
                    <h4 className='text-muted'>Thanks for your feedback!</h4>
                </div>
                <div className='mt-3'>
                    <button onClick={() => setEditMode(true)} className='btn btn-secondary'>Edit my review</button>
                </div>
            </div>
        </div>;
    }

    return <div className={appHeaderClassNames}>
        <div className='container'>
            { headerContent }
        </div>
    </div>;
};
