




        /**
        * A TypeScript version of a server-side enum. 
        */
        export enum StairTypeEnum {
            
                /**
        * [-1] 
        */
                NotApplicable=-1,
                /**
        * [0] 
        */
                Unknown=0,
                /**
        * [1] 
        */
                NoStairs=1,
                /**
        * [2] 
        */
                Stairs=2,

        }

        const stairTypeEnumDescription = {
            
                NotApplicable: 'Not Applicable',
                Unknown: 'Unknown',
                NoStairs: 'No Stairs',
                Stairs: 'Stairs',
        };

        export const StairTypeEnumDescription = {

            ...stairTypeEnumDescription,

            get: (value: StairTypeEnum | undefined | null): string | undefined => {
                if (typeof (value) === "string") {
                    return stairTypeEnumDescription[value];
                }
                if (value===null || value===undefined) {
                    return undefined;
                }
                return stairTypeEnumDescription[StairTypeEnum[value]  as keyof typeof stairTypeEnumDescription];
            },
            all: [
                    
                    { value: -1, label: stairTypeEnumDescription.NotApplicable },
                    { value: 0, label: stairTypeEnumDescription.Unknown },
                    { value: 1, label: stairTypeEnumDescription.NoStairs },
                    { value: 2, label: stairTypeEnumDescription.Stairs },
            ] as ReadonlyArray<{label: string, value: number}>

        };
    