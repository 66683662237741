import faker from '@faker-js/faker';
import { DateTime } from 'luxon';
import { AddressType, TypeOfHelpEnum } from '../typewriter/enums';
import { LocationValidationModel } from './validationSchema';

export const emptyAddress: models.AddressModel = { street: '', streetLineTwo: '', city: '', state: '', zip: '', type: AddressType.Unknown };
export const emptyJobAddress: models.JobAddressModel = { ...emptyAddress, typeOfHelp: TypeOfHelpEnum.Unknown };
export const emptyLocation: LocationValidationModel = {
    ...emptyJobAddress,
    jobDate: undefined,
    desiredArrivalWindow: undefined,
    sqFootage: undefined,
    stairs: undefined
};
export const testLocation = (typeOfHelp: TypeOfHelpEnum): models.BookingSubdomainJobInfo => {
    return {
        jobDate: DateTime.local().plus({ month: 1, days: 5 }).toISODate() as string,
        desiredArrivalWindow: 'Arrival Between 8 AM - 9 AM',
        jobAddresses: [{
            typeOfHelp: typeOfHelp,
            sqFootage: 1000,
            stairs: 1,
            street: faker.address.streetAddress(),
            city: 'San Diego',
            state: 'CA',
            zip: '92054',
            type: AddressType.House
        }],
    }
};