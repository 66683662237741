import * as Sentry from '@sentry/react';
import React from 'react';
import { toast } from 'react-toastify';
import { getTransportOptions } from '../typewriter/controllers/BookingSubdomainController';

export const useTransportOptions = () => {


    const [transportOptions, setTransportOptions] = React.useState<readonly models.TransportOptionBasic[]>();

    React.useEffect(() => {


        if (!transportOptions) {
            (async () => {
                try {
                    const result = await getTransportOptions()
                    setTransportOptions(result);
                } catch (error) {
                    Sentry?.captureException(error);

                    toast.error('Sorry, an unexpected error occurred. Please try refreshing your browser. If the problem continues, please call us for assistance');

                }

            })();


        }
    }, [transportOptions]);


    return transportOptions;

}